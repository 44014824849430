<script>
    import FilterOptions from './FilterOptions.svelte';
    import {RADIO_ALL} from "../utils/constants";
    import SVGButton from "./SVGButton.svelte";
    import PlusIcon from '../icons/plus-sign.svg';
    import ClearIcon from '../icons/clear-icon.svg';
    import randomString from "../utils/randomString";
    import queryItem from "../utils/queryItem";
    import {add} from "../store/currentAlertQuery";
    import {getFacets} from "../network/analytics/facets";
    import {getAlterationSuggestion} from "../network/alterations";
    import {getGeneSuggestion} from "../network/genes";
    import {pushFail} from "../utils/toast";
    import MultiSelect from "./MultiSelect.svelte";
    import RadioButtonFilter from "./FilterComponent/RadioButtonFilter.svelte";
    import NumericalRangeFilter from "./FilterComponent/NumericalRangeFilter.svelte";

    // initial list of facets
    export let filters = [];

    // key to append selection to currentQuery
    export let key = '';

    // random id recommended because a facet of the same name may occur elsewhere
    const id = randomString();

    // list of display strings which determine if the value can be a term or a range, all else is term.
    // ideally there should be a way so this is not a hardcoded array in this component
    const termOrRangeKeys = [
        // patient
        'First Report Date',
        'Last Report Date',
        'Order Placed Date',
        // specimen chars.
        'Neoplastic Nuclie',
        'Necrosis',
        'Cellularity',
        'Sample Collection Date',
        'Sample Received Date',
        'Sample Procurement Date',
        //order chars
        'Report Date',
        // genomic chars.
        'Alt Depth',
        'Clinvar Count',
        'Codon',
        'Copy Number',
        'Copy Ratio',
        'Cosmic Frequency',
        'Cosmic Gene Mut Count',
        'Cosmic Mut Count',
        'Exon',
        'Position',
        'Raw Copy Number',
        'Ref Depth',
        'Total Depth',
        'Vaf',
        // immunity chars.
        'Expression Score',
        'Tmb Result'
    ];

    let queryValue = ''; // value to filter possibleValues by
    let startValue = ''; // first value if keyType is range
    let endValue = ''; // second value if keyType is range
    let debounce = null;

    let filterType = RADIO_ALL;
    let keyType = 'term';

    $: keyTypeWatcher = function () {
        return keyType;
    }();

    // for checking if the value input should be a date picker or not.
    const dateKeyRegex = /[_ ]date/i;

    const onAdd = (value) => {
        if (key.length === 0) {
            console.warn('no key provided to FacetQueryInput, not adding facets');
            return;
        }

        // add selection
        const queryItem = buildQueryItem(facetKey, value, selectedFilter["core"]);
        if (!queryItem) {
            return;
        }
        add(key, queryItem)();
        // clear selection indices
        facetString = '';
        queryValue = '';
        keyType = 'term';
    };

    function buildQueryItem(key, value, core) {
        if (!key) {
            return null;
        }

        if (keyType === 'term') {
            if (value.length === 0) {
                pushFail("Query value cannot be empty");
                return null;
            }
            if (Array.isArray(value)) {
                return queryItem(key, value, filterType, core, `${facetString}: ${value.join(', ')}`);
            }
            return queryItem(key, value, filterType, core, `${facetString}: ${value}`);
        }

        // else queryItem is range
        if (startValue.length === 0 || endValue.length === 0) {
            pushFail(`Both values must be provided for range query.`);
            return null;
        }
        return queryItem(key, [startValue, endValue], filterType, core, `${facetString}: ${startValue} - ${endValue}`, keyType);
    }

    let facetString = '';
    let possibleValues = [];

    $: facetKey = selectedFilter && selectedFilter["solrField"] ? selectedFilter["solrField"] : "";
    $: selectedCore = selectedFilter && selectedFilter["core"] ? selectedFilter["core"] : "";

    // important caveats to know with reactive fetch like this
    // https://github.com/sveltejs/svelte/issues/2118#issuecomment-492537687
    $: fetchPossibleValues(facetKey, selectedCore);

    function facetHasCustomBehavior(fString) {
        // some facets require static options or use special typeahead API's
        return [
            'canonical_alterations',
            'biomarkers',
        ].includes(fString);
    }

    function clearFacetString() {
        facetString = '';
        possibleValues = [];
    }

    /**
     * attempt to fetch possible values for provided facet and query, valid even for fetching dates which can be sent in
     * a payload as a term. Ignore for canonical alterations and genes.
     * @param fString: facet string
     * @returns {Promise<void>}
     */
    async function fetchPossibleValues(fString) {
        if (fString.length && !facetHasCustomBehavior(fString) && selectedFilter["filterInput"] !== "numerical range") {
            // const response = await getFacets(fString, coreForField(fString) ?? 'cases');
            const response = await getFacets(fString, selectedFilter["core"]);

            if (response.ok) {
                const body = await response.json();
                possibleValues = Object.keys(body.types[facetKey]);
                // console.log("possible values for facetKey", facetKey, "are", possibleValues);
            } else {
                console.error("Something went wrong");
                console.error(response);
            }
        } else {
            possibleValues = [];
        }

        // Initialize any numerical range components.
        if (selectedFilter && selectedFilter["filterInput"] === "numerical range") {
            startValue = 0;
            endValue = 100;
        }
    }

    // if enter is hit early, pick the first item which matches the datalist
    async function onKeyDown() {
        if (facetHasCustomBehavior(facetKey)) {
            // clear previous debounce
            if (debounce !== null) {
                clearTimeout(debounce);
                debounce = null;
            }
            if (queryValue.trim() === '') {
                return;
            }
            // set debounce, this prevents N calls from being triggered from querying a word N in length
            debounce = setTimeout(async () => {
                // provide special suggestion methods for alterations, genes, and diseases
                if (facetKey === 'canonical_alterations') {
                    const response = await getAlterationSuggestion(queryValue);
                    console.log(response);
                    if (response.ok) {
                        let suggestValues = await response.json();
                        suggestValues.results.forEach((doc) => {
                            if (!possibleValues.includes(doc.text)) {
                                possibleValues = [...possibleValues, doc.text];
                            }
                        });
                    }
                } else if (facetKey === 'biomarkers') {
                    const response = await getGeneSuggestion(queryValue);
                    if (response.ok) {
                        possibleValues = await response.json();
                    }
                }
                possibleValues.sort((a,b) => a.length - b.length);
                debounce = null;
            }, 200);
        }
    }

    function lookupFilter(filterName) {
        for (let f of filters) {
            if (f["displayName"] === filterName) {
                return f;
            }
        }
    }

    $: selectedFilter = lookupFilter(facetString);

</script>
<FilterOptions bind:value={filterType} />
<div class="search-row">
    <input type="text" class="facet-input" list="facet-list-{id}" placeholder="Search"
           bind:value={facetString}>
    {#if facetString.length > 0}
        <img class="clear-icon" src={ClearIcon} title="Clear Query" on:click={clearFacetString} alt="clear">
    {/if}
    <datalist id="facet-list-{id}">
        {#each filters as f, index}
            <option>{ f["displayName"] }</option>
        {/each}
    </datalist>
    <div class="spacer"></div>
</div>

{#if facetKey}
    {#if termOrRangeKeys.includes(facetString) || selectedFilter["filterInput"] === "Term or Range"}
        <div class="key-type">
            <label for={`key-type-range-${id}`}>
                <input id={`key-type-term-${id}`} type="radio" value="term" bind:group={keyType}>
                Term
            </label>
            <label for={`key-type-range-${id}`}>
                <input id={`key-type-range-${id}`} type="radio" value="range" bind:group={keyType}>
                Range
            </label>
        </div>
    {/if}

    <div class="search-row">
        {#if keyType === 'range' && dateKeyRegex.test(facetKey)}
            <input type="date" class="value-input" bind:value={startValue}>
            <input type="date" class="value-input" bind:value={endValue}>
            <SVGButton onClick={onAdd} svg={PlusIcon} />
        {:else if keyType === 'range' && (termOrRangeKeys.includes(facetString) || selectedFilter["filterInput"] === "Term or Range")}
            <div class="range-row">
                <span>&leq;</span>
                <input class="age-input" type="number" bind:value={startValue}>
                <span>to</span>
                <input class="age-input" type="number" bind:value={endValue}>
                <span>&geq;</span>
            </div>
            <SVGButton onClick={onAdd} svg={PlusIcon} />

        {:else if selectedFilter["filterInput"] === "radio button"}

            <RadioButtonFilter
                    solrField={selectedFilter["solrField"]}
                    displayName={selectedFilter["displayName"]}
                    filterMode={RADIO_ALL}
                    allValues={() => {}}
                    queryLabel={selectedFilter["filterCategory"]}
            />
        {:else if selectedFilter["filterInput"] === "numerical range"}

            <NumericalRangeFilter
                    solrField={selectedFilter["solrField"]}
                    displayName={selectedFilter["displayName"]}
                    filterMode={RADIO_ALL}
                    allValues={possibleValues}
                    queryLabel={selectedFilter["filterCategory"]}
                    startValue={startValue}
                    endValue={endValue}
                    is_age_field={selectedFilter["solrField"] === "age_in_months"}
            />

        {:else }
            <!--MultiSelect is the default option, but if we want to add Autocomplete and Dropdown here, that can be
            done as well later on in the template-->
            <MultiSelect onKeyUp={onKeyDown}
                         options={possibleValues.map(v => ({ value: v, name: v}))}
                         bind:inputValue={queryValue} onAdd={onAdd}
            />
        {/if}
    </div>
{/if}

<style>
    .search-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 1em;
    }

    .search-row input {
        width: 80%;
        padding: 8px 12px;
    }

    .search-row .spacer {
        width: 80px;
    }

    .range-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        font-size: 1.5em;
    }

    .key-type {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }

    .key-type > label {
        margin: 0 10px;
    }

    /* nearly the same as .remove-all in MultiSelect.svelte*/
    .clear-icon {
        align-items: center;
        background-color: hsl(214, 15%, 55%);
        border-radius: 50%;
        color: hsl(214, 17%, 92%);
        cursor: pointer;
        position: relative;
        left: -30px;
        top: -4px;
    }
</style>