import {writable} from 'svelte/store';
import {getBaseNavigationUrl} from "../utils/urls";

export const selectedOption = writable('');

export const alertPage = writable('management');

export const alertID = writable('');

export const expandMainHeader = writable(false);

function selectOption(option) {
    if (option !== "analytics") {
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }
    else{
        window.open(`${getBaseNavigationUrl()}Analytics`, '_blank');
    }
}

// Initialize the history with an empty array and current index as -1
const {subscribe, set, update} = writable({history: [], currentIndex: -1});

// Function to navigate forward in the history
const goForward = () => {
    update(history => {
        const nextIndex = history.currentIndex + 1;
        if (nextIndex < history.history.length) {
            // Increment the currentIndex and return the updated history
            selectOption(history.history[nextIndex]);
            return {...history, currentIndex: nextIndex};
        } else {
            return history; // Cannot go forward, return unchanged history
        }
    });
};

// Function to navigate backward in the history
const goBackward = () => {
    update(history => {
        const prevIndex = history.currentIndex - 1;
        if (prevIndex >= 0) {
            // Decrement the currentIndex and return the updated history
            selectOption(history.history[prevIndex]);
            return {...history, currentIndex: prevIndex};
        } else {
            return history; // Cannot go backward, return unchanged history
        }
    });
};

// Function to navigate a specific number of steps forward in the history
const goForwardSteps = (steps) => {
    update(history => {
        const nextIndex = history.currentIndex + steps;
        if (nextIndex < history.history.length) {
            // Increment the currentIndex by the specified number of steps and return the updated history
            selectOption(history.history[nextIndex]);
            return {...history, currentIndex: nextIndex};
        } else {
            // If attempting to navigate beyond the history, return unchanged history
            return history;
        }
    });
};

// Function to navigate a specific number of steps backward in the history
const goBackwardSteps = (steps) => {
    update(history => {
        const prevIndex = history.currentIndex - steps;
        if (prevIndex >= 0) {
            // Decrement the currentIndex by the specified number of steps and return the updated history
            selectOption(history.history[prevIndex]);
            return {...history, currentIndex: prevIndex};
        } else {
            // If attempting to navigate beyond the history, return unchanged history
            return history;
        }
    });
};


// Function to add a new page to the history
const addToHistory = (page) => {
    expandMainHeader.set(false); // on loading any page reset main header height
    update(history => {
        const {currentIndex, history: currentHistory} = history;
        const updatedHistory = currentHistory.slice(0, currentIndex + 1).concat(page, currentHistory.slice(currentIndex + 1));
        return {history: updatedHistory, currentIndex: currentIndex + 1};
    });
};


// Export the functions and store for external use
export {subscribe, goForward, goBackward, goForwardSteps, goBackwardSteps, addToHistory};
