import {callApi} from "./rest";

const ROOT_URL_API = '/api';
const ROOT_URL_CONSULT = '/consult';

export const suggestDiagnosis = async (query) => {
    const url = `${ROOT_URL_API}/diseases/suggest?q=${query}&page_size=15`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get diagnoses");
    }
};

export const suggestBiomarkers = async (query, marker_significance) => {
    const url = `${ROOT_URL_API}/alterations/case_alterations_suggest?q=${query}&facet_type=${marker_significance}&page_size=15`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get biomarkers");
    }
};

export const suggestGenes = async (query) => {
    const url = `${ROOT_URL_API}/genes/suggest?q=${query}&page_size=15`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get genes");
    }
};

export const suggestDrugs = async (query) => {
    const url = `${ROOT_URL_API}/drugs/suggest?q=${query}&page_size=15`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get users");
    }
};

export const gatherAssays = async () => {
    const url = `${ROOT_URL_CONSULT}/assays`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get users");
    }
};

export const gatherFacetInfo = async () => {
    const url = `${ROOT_URL_CONSULT}/patients/facet-info`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient facet info");
    }
};

export const callMatch = async (username, password) => {
    const url = `${ROOT_URL_API}/login`;
    const body  = {username, password};
    return await callApi(url, "POST", body);
};

export const getPatientSummary = async (currentPage, selectedPageSize, searchQueries, ordering) => {
    let url = `${ROOT_URL_CONSULT}/patients/summary?page=${currentPage}&page_size=${selectedPageSize}`;

    const searchParams = Object.entries(searchQueries)
        .map(([field, value]) => value && `${field}=${encodeURIComponent(value)}`)
        .filter(Boolean)
        .join('&');

    if (searchParams) {
        url = `${url}&${searchParams}`;
    }

    if (ordering) {
        url = `${url}&sort_by=${ordering}`;
    }

    const response = await callApi(url, "GET");

    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient summary");
    }
};


export const getPatientSummaryCSVDownload = async (searchQueries, ordering) => {
    let url = `${ROOT_URL_CONSULT}/patients/summary/csv-download`;

    const searchParams = Object.entries(searchQueries)
        .map(([field, value]) => value && `${field}=${encodeURIComponent(value)}`)
        .filter(Boolean)
        .join('&');

    if (searchParams) {
        url = `${url}?${searchParams}`;
    }

    if (ordering) {
        url = `${url}&sort_by=${ordering}`;
    }

    const response = await callApi(url, "GET");

    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient summary");
    }
};

export const createPatientTrackingStatus = async (body) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses`;
    return await callApi(url, "POST", body);
};

export const createPatientTrackingStatusComment = async (body) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-status-comments`;
    return await callApi(url, "POST", body);
};

export const getPatientTrackingStatusComments = async (alert_config_name) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-status-comments?alert_config_name=${alert_config_name}&page_size=1000`;
    let response =  await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient tracking status comments.");
    }
};

export const getAllPatientTrackingStatusComments = async () => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-status-comments?page_size=10000`;
    let response =  await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient tracking status comments.");
    }
};

export const getPatientTrackingStatuses = async (alert_config_name, for_notifications) => {
    let url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses?alert_config_name=${alert_config_name}`;

    if (for_notifications) {
        url = `${url}&for_notifications=${for_notifications}`;
    }
    let response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient tracking info.");
    }
};

export const getAllPatientTrackingStatuses = async () => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses`;
    let response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get patient tracking info.");
    }
};

export const deletePatientTrackingStatus = async (id) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses/${id}`;
    return await callApi(url, "DELETE");
};

export const updatePatientTrackingStatus = async (id, body) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses/${id}`;
    return await callApi(url, "PATCH", body);
};

export const bulkUpdatePatientTrackingStatus = async (body) => {
    const url = `${ROOT_URL_CONSULT}/patients/patient-tracking-statuses/bulk-update`;
    return await callApi(url, "PATCH", body);
};