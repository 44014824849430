<script>
    import PatientSearchSidebar from "./PatientSearchSidebar.svelte";
    import {onMount} from 'svelte';
    import {getPatientSummary, getPatientSummaryCSVDownload} from "../../network/patient_search";
    import Loader from "../shared/Loader.svelte";
    import {HSplitPane} from "svelte-split-pane";
    import {generateTrialsMatches, pane, selectedCaseUUID} from "../../store/patientMatch";
    import {addToHistory, selectedOption} from "../../store/navigation";
    import {getBaseNavigationUrl} from '../../utils/urls';
    import {
        faCircleLeft,
        faCircleXmark,
        faEye,
        faFileMedicalAlt,
        faFilter,
        faPrescriptionBottleAlt
    } from "@fortawesome/free-solid-svg-icons";
    import {Icon} from "svelte-awesome";
    import {matchedPatients, searchQueries, totalPatients} from '../../store/patientSearchQueries.js';


    let patient_summary = {};
    let patients = [];
    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = '';
    let isLoading = false;
    let ordering = '';
    let isSlimView = false;
    let hideLeftNav = false;
    let leftPaneWidth = "21%";
    let rightPaneWidth = "79%";
    let leftPaneInfoWidth = "100%";
    let rightPaneInfoWidth = "0%";
    let populateRightPane = false;
    let currentCaseGlance = {};

    function clearSearch() {
        searchQueries.update(storeValue => {
            storeValue.full_name = '';
            storeValue.patient_mrn = '';
            storeValue.assay_names = '';
            storeValue.pos_markers = '';
            storeValue.vus_markers = '';
            storeValue.neg_markers = '';
            storeValue.date_of_birth = '';
            storeValue.reported_start_date = '';
            storeValue.reported_end_date = '';
            storeValue.diagnosis = '';
            storeValue.gender = '';
            storeValue.report_id = '';
            storeValue.ordering_physician = '';
            storeValue.genes = '';
            storeValue.report_status = '';
            storeValue.data_sets = '';
            return storeValue;
        });
        allCount = '';
        matchedPatients.update(storeValue => {
            storeValue = allCount;
            return storeValue;
        });
        resetPageSize();
    }

    function ResetSearch() {
        searchQueries.update(storeValue => {
            storeValue.full_name = '';
            storeValue.patient_mrn = '';
            storeValue.assay_names = '';
            storeValue.pos_markers = '';
            storeValue.vus_markers = '';
            storeValue.neg_markers = '';
            storeValue.date_of_birth = '';
            storeValue.reported_start_date = '';
            storeValue.reported_end_date = '';
            storeValue.diagnosis = '';
            storeValue.gender = '';
            storeValue.report_id = '';
            storeValue.ordering_physician = '';
            storeValue.genes = '';
            storeValue.report_status = '';
            storeValue.data_sets = '';
            return storeValue;
        });
        resetPageSize();
    }

    function resetPageSize() {
        selectedPageSize = 10;
        const pageSizeDropdown = document.getElementById("page-size");
        if (pageSizeDropdown) {
            pageSizeDropdown.selectedIndex = 0;
        }
    }

    async function fetchResetPatients() {
        clearSearch();
        currentPage = 1;
        await fetchPatients();
    }

    async function fetchClearFilters() {
        ResetSearch();
        currentPage = 1;
    }

    async function fetchSearchPatients() {
        allCount = '';
        currentPage = 1;
        await fetchPatients();
    }

    async function fetchPatients() {
        isLoading = true;
        patient_summary = await getPatientSummary(currentPage, selectedPageSize, $searchQueries, ordering);
        allCount = patient_summary.count;
        matchedPatients.update(storeValue => {
            storeValue = allCount;
            return storeValue;
        });
        patients = patient_summary.results;
        isLoading = false;
    }

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await fetchPatients();
    }

    function sortPatients(property) {
        console.log(property);
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchPatients();
    }

    function generateCSVContent(data) {
        // Define the headers for the CSV
        const headers = [
            "Full Name",
            "Patient MRN",
            "Case UUID",
            "Assay Name",
            "Marker",
            "Reported Date"
        ];

        // Convert each patient's lab results into CSV rows
        const rows = [];
        data.forEach((patientCSVData) => {
            rows.push([
                patientCSVData.full_name || "",
                patientCSVData.mrn || "",
                patientCSVData.case_uuid || "",
                patientCSVData.assay_name || "",
                patientCSVData.markers.join(", ") || "",
                patientCSVData.reported || ""
            ]);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    async function fetchExportPatients() {
        isLoading = true;
        let casePromise = await getPatientSummaryCSVDownload($searchQueries, ordering);
        isLoading = false;
        return casePromise;
    }

    async function exportToCSV() {
        fetchExportPatients().then(csvData => {
            let data = csvData.results || [];
            const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent(data))}`;
            const link = document.createElement("a");
            link.href = csvContent;
            link.download = "patients.csv";
            link.click();
        })
    }

    async function changePage(page) {
        currentPage = page;
        await fetchPatients();
    }


    function selectOption(option, case_uuid) {
        addToHistory(option);
        selectedCaseUUID.set(case_uuid);
        selectedOption.set(option);
        generateTrialsMatches.set(true);
        pane.set('backToSummary');
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }

    onMount(() => {
        function handleKeyDown(event) {
            if (event.keyCode === 13) {
                // Return key was pressed
                fetchSearchPatients();
            }
        }

        const inputElements = document.querySelectorAll('.search-box');
        inputElements.forEach((input) => {
            input.addEventListener('keydown', handleKeyDown);
        });

        return () => {
            // Cleanup event listeners
            inputElements.forEach((input) => {
                input.removeEventListener('keydown', handleKeyDown);
            });
        };
    });

    function handleLeftNav() {
        hideLeftNav = !hideLeftNav;
        leftPaneWidth = hideLeftNav ? "0%" : "21%";
        rightPaneWidth = hideLeftNav ? "100%" : "79%";
    }

    function openRightNav(case_glance) {
        currentCaseGlance = case_glance;
        leftPaneInfoWidth = "79%";
        rightPaneInfoWidth = "21%";
        populateRightPane = true;
    }

    function closeRightNav() {
        leftPaneInfoWidth = "100%";
        rightPaneInfoWidth = "0%";
        populateRightPane = false;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString('en-US', options);
    }

    function formatDiagnoses(diagnoses) {
        let diseases = [];
        diagnoses.forEach((diagnosis) => {
            if (!diseases.includes(diagnosis.name)) {
                diseases.push(diagnosis.name);
            }
        });
        return diseases.join(", ");
    }

    onMount(() => {
        clearSearch();
        fetchPatients();
    });
    $: totalPages = Math.ceil(allCount / selectedPageSize);
</script>

<style>
    .container {
        display: flex;
        height: calc(80vh - 51px);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    ul {
        list-style-type: none;
        color: grey;
    }

    li {
        margin-left: 20px;
    }

    .summary {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 1em;
        margin-right: 1em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        height: calc(70vh - 51px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .export-button {
        background-color: #3491f5;
        color: #fff;
    }

    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
    }

    .pagination {
        display: flex;
        align-items: center;
    }

    .pagination button {
        margin-left: 0.5em;
    }

    /* Add the following styles for the "Markers" column */
    td:nth-child(4) {
        word-break: break-word;
        overflow-wrap: break-word;
        max-width: 800px;
    }

    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }

    .side-bar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .select-button {
        margin-top: 10px;
        background-color: #9FCE67;
        color: #fff;
    }

    .icon-button {
        margin-right: auto;
    }

    .table-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }

    .icon-png {
        width: 25px;
    }
</style>

<div class="container">
    <HSplitPane bind:leftPaneSize={leftPaneInfoWidth} bind:rightPaneSize={rightPaneInfoWidth}>
        <left slot="left">
            {#if (hideLeftNav)}
                <div class="summary">
                    <div class="table-top">
                        <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                            {#if hideLeftNav}
                                <p style="margin-bottom: 0">Apply Filters</p>
                                <Icon data="{faFilter}" scale="1.5" style="color: #304e82; margin-top: 0"/>
                            {:else}
                                <p style="margin-bottom: 0">Close Filter Pane</p>
                                <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
                            {/if}
                        </div>
                        <div class="pagination">
                            <label>
                                <input type="checkbox" bind:checked={isSlimView}/>
                                Slim View {$totalPatients}
                            </label>
                            <label style="margin-left:15px;" for="page-size">Page Size:</label>
                            <select id="page-size" on:change={changePageSize}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="{allCount}">All</option>
                            </select>
                            {#if !isLoading && patients.length > 0}
                                <div class="pagination">
                                    {#if patient_summary.previous}
                                        <button class="next-button" on:click={() => changePage(currentPage - 1)}>
                                            Previous
                                        </button>
                                    {/if}
                                    <span class="pages-span">
                                Page {currentPage} of {totalPages}
                            </span>
                                    {#if patient_summary.next}
                                        <button class="next-button" on:click={() => changePage(currentPage + 1)}>
                                            Next
                                        </button>
                                    {/if}
                                </div>
                                <button class="export-button" on:click={() => exportToCSV(patients)}>Export</button>
                            {/if}
                        </div>
                    </div>
                    <div class="scrollable-table">
                        {#if isLoading}
                            <Loader/>
                        {:else}
                            <table>
                                <thead>
                                <tr>
                                    <th style="width: 25%;" on:click={() => sortPatients('full_name')}>
                                        Patient Information
                                        {#if ordering === 'full_name'}
                                            &#9650; <!-- Upward arrow -->
                                        {:else if ordering === '-full_name'}
                                            &#9660; <!-- Downward arrow -->
                                        {/if}
                                    </th>
                                    <th style="width: 10%;">Diagnosis</th>
                                    <th style="width: 35%;">Test Details</th>
                                    <th style="width: 10%;">Last Update Date</th>
                                    <th style="width: 10%;">Quick View</th>
                                    <th style="width: 10%;"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each patients as patient, i}
                                    <tr class:even={i % 2 === 1}>
                                        <td>{patient.full_name || ''}
                                            <br/>
                                            {#if patient.date_of_birth}
                                                DOB: {formatDate(patient.date_of_birth)}
                                            {/if}
                                            <br/>
                                            MRN: {patient.patient_mrn || ''}
                                            <br/>
                                            Ordering Physician: {patient.ordering_physician || ''}
                                        </td>
                                        <td>{formatDiagnoses(patient.diagnoses)}</td>
                                        <td>
                                            {#each patient.lab_results as result}
                                                <b>{result.name} ({result.reported_date}
                                                    ) {result.report_id || ''}</b>
                                                {#if !isSlimView}
                                                    {#if result.report_status}
                                                        <ul>
                                                            <b> Report Status:</b> {result.report_status}
                                                        </ul>
                                                    {/if}
                                                    {#if result.significant_alterations.length > 0}
                                                        <ul>
                                                            <b> Significant Alterations</b>
                                                            <ul>
                                                                <li>{result.significant_alterations.join(', ')}</li>
                                                            </ul>
                                                        </ul>
                                                    {/if}
                                                    {#if result.vus_alterations.length > 0}
                                                        <ul>
                                                            <b> Variants of Unknown Significance </b>
                                                            <ul>
                                                                <li>{result.vus_alterations.join(', ')}</li>
                                                            </ul>
                                                        </ul>
                                                    {/if}
                                                    {#if result.negative_alterations.length > 0}
                                                        <ul>
                                                            <b> Negative Alterations</b>
                                                            <ul>
                                                                <li>{result.negative_alterations.join(', ')}</li>
                                                            </ul>
                                                        </ul>
                                                    {/if}
                                                    {#if result.significant_alterations.length === 0 && result.negative_alterations.length === 0 && result.vus_alterations.length === 0}
                                                        {#if result.is_somatic === false}
                                                            <ul>
                                                                <b> Detail:</b>
                                                                <li>Germline Sample. No associated Alterations.</li>
                                                            </ul>
                                                        {:else if result.comments.length > 0}
                                                            <ul>
                                                                <b> Detail:</b>
                                                                {#each result.comments as comment}
                                                                    <li>{comment}</li>
                                                                {/each}
                                                            </ul>
                                                        {:else}
                                                            <ul>
                                                                <b> Detail:</b>
                                                                <li>No associated Alterations.</li>
                                                            </ul>
                                                        {/if}
                                                    {/if}
                                                {/if}
                                                <br>
                                            {/each}
                                        </td>
                                        <td>
                                            <div>
                                                Genomic: {patient.genomic_date || ''}
                                            </div>
                                            <div style="margin-top: 20px;">
                                                Clinical: {patient.cdw_associated_date || ''}
                                            </div>
                                        </td>
                                        <td>
                                            <div style="display: flex;">
                                                {#if patient.case_glance.therapies.total !== 0}
                                                    <div style="margin-right: 14px; color: #98B249"
                                                         aria-hidden="true">
                                                            <span title="The case has matched FDA/NCCN approved therapies">
                                                                <Icon data={faPrescriptionBottleAlt} scale="1.25"/>
                                                            </span>
                                                    </div>
                                                {:else}
                                                    <div style="margin-right: 14px; color: #d98b95"
                                                         aria-hidden="true">
                                                            <span title="The case has no matched FDA/NCCN approved therapies">
                                                                <Icon data={faPrescriptionBottleAlt} scale="1.25"/>
                                                            </span>
                                                    </div>
                                                {/if}
                                                {#if patient.case_glance.trials.all !== 0 &&
                                                (patient.case_glance.trials.institutional_biomarker_match !== 0 ||
                                                    patient.case_glance.trials.institutional_non_biomarker_match !== 0)}
                                                    <div style="margin-right: 14px; color: #98B249"
                                                         aria-hidden="true">
                                                            <span title="The case has matched clinical trials including institutional matches">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                    </div>
                                                {:else if patient.case_glance.trials.all !== 0}
                                                    <div style="margin-right: 14px; color: #98B249"
                                                         aria-hidden="true">
                                                            <span title="The case has matched clinical trials not including any institutional matches">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                    </div>
                                                {:else}
                                                    <div style="margin-right: 14px; color: #d98b95"
                                                         aria-hidden="true">
                                                            <span title="The case has no matched clinical trials">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                    </div>
                                                {/if}
                                                {#if patient.case_glance.markers.discordant_markers_count > 0}
                                                    <div style="margin-right: 14px; color: #d98b95"
                                                         aria-hidden="true">
                                                            <span title="This case has alterations where the GO interpretation does not match the vendor intepretation">
                                                                <img src="static/images/discordance_icon.png"
                                                                     class="icon-png" alt="">
                                                            </span>
                                                    </div>
                                                {/if}
                                                <div on:click={() => openRightNav(patient.case_glance)}
                                                     aria-hidden="true">
                                                        <span title="View">
                                                            <Icon data={faEye} scale="1.25"/>
                                                        </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="select-button"
                                                    on:click={() => selectOption("patient-match", patient.case_uuids[0])}>
                                                Select Case
                                            </button>
                                        </td>
                                    </tr>
                                {/each}
                                </tbody>
                            </table>
                        {/if}
                    </div>
                </div>
            {:else}
                <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth} minLeftPaneSize= "20%">
                    <left slot="left">
                        {#if !hideLeftNav}
                            <div class="side-bar">
                                <PatientSearchSidebar {fetchSearchPatients} {fetchResetPatients} {fetchClearFilters}/>
                            </div>
                        {/if}
                    </left>
                    <right slot="right">

                        <div class="summary">
                            <div class="table-top">
                                <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                                    {#if hideLeftNav}
                                        <p style="margin-bottom: 0">Apply Filters</p>
                                        <Icon data="{faFilter}" scale="1.5" style="color: #304e82; margin-top: 0"/>
                                    {:else}
                                        <p style="margin-bottom: 0">Close Filter Pane</p>
                                        <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
                                    {/if}
                                </div>
                                <div class="pagination">
                                    <label>
                                        <input type="checkbox" bind:checked={isSlimView}/>
                                        Slim View {$totalPatients}
                                    </label>
                                    <label style="margin-left:15px;" for="page-size">Page Size:</label>
                                    <select id="page-size" on:change={changePageSize}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="{allCount}">All</option>
                                    </select>
                                    {#if !isLoading && patients.length > 0}
                                        <div class="pagination">
                                            {#if patient_summary.previous}
                                                <button class="next-button" on:click={() => changePage(currentPage - 1)}>
                                                    Previous
                                                </button>
                                            {/if}
                                            <span class="pages-span">
                                Page {currentPage} of {totalPages}
                            </span>
                                            {#if patient_summary.next}
                                                <button class="next-button" on:click={() => changePage(currentPage + 1)}>
                                                    Next
                                                </button>
                                            {/if}
                                        </div>
                                        <button class="export-button" on:click={() => exportToCSV(patients)}>Export</button>
                                    {/if}
                                </div>
                            </div>
                            <div class="scrollable-table">
                                {#if isLoading}
                                    <Loader/>
                                {:else}
                                    <table>
                                        <thead>
                                        <tr>
                                            <th style="width: 25%;" on:click={() => sortPatients('full_name')}>
                                                Patient Information
                                                {#if ordering === 'full_name'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if ordering === '-full_name'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th style="width: 10%;">Diagnosis</th>
                                            <th style="width: 35%;">Test Details</th>
                                            <th style="width: 10%;">Last Update Date</th>
                                            <th style="width: 10%;">Quick View</th>
                                            <th style="width: 10%;"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {#each patients as patient, i}
                                            <tr class:even={i % 2 === 1}>
                                                <td>{patient.full_name || ''}
                                                    <br/>
                                                    {#if patient.date_of_birth}
                                                        DOB: {formatDate(patient.date_of_birth)}
                                                    {/if}
                                                    <br/>
                                                    MRN: {patient.patient_mrn || ''}
                                                    <br/>
                                                    Ordering Physician: {patient.ordering_physician || ''}
                                                </td>
                                                <td>{formatDiagnoses(patient.diagnoses)}</td>
                                                <td>
                                                    {#each patient.lab_results as result}
                                                        <b>{result.name} ({result.reported_date}
                                                            ) {result.report_id || ''}</b>
                                                        {#if !isSlimView}
                                                            {#if result.report_status}
                                                                <ul>
                                                                    <b> Report Status:</b> {result.report_status}
                                                                </ul>
                                                            {/if}
                                                            {#if result.significant_alterations.length > 0}
                                                                <ul>
                                                                    <b> Significant Alterations</b>
                                                                    <ul>
                                                                        <li>{result.significant_alterations.join(', ')}</li>
                                                                    </ul>
                                                                </ul>
                                                            {/if}
                                                            {#if result.vus_alterations.length > 0}
                                                                <ul>
                                                                    <b> Variants of Unknown Significance </b>
                                                                    <ul>
                                                                        <li>{result.vus_alterations.join(', ')}</li>
                                                                    </ul>
                                                                </ul>
                                                            {/if}
                                                            {#if result.negative_alterations.length > 0}
                                                                <ul>
                                                                    <b> Negative Alterations</b>
                                                                    <ul>
                                                                        <li>{result.negative_alterations.join(', ')}</li>
                                                                    </ul>
                                                                </ul>
                                                            {/if}
                                                            {#if result.significant_alterations.length === 0 && result.negative_alterations.length === 0 && result.vus_alterations.length === 0}
                                                                {#if result.is_somatic === false}
                                                                    <ul>
                                                                        <b> Detail:</b>
                                                                        <li>Germline Sample. No associated Alterations.</li>
                                                                    </ul>
                                                                {:else if result.comments.length > 0}
                                                                    <ul>
                                                                        <b> Detail:</b>
                                                                        {#each result.comments as comment}
                                                                            <li>{comment}</li>
                                                                        {/each}
                                                                    </ul>
                                                                {:else}
                                                                    <ul>
                                                                        <b> Detail:</b>
                                                                        <li>No associated Alterations.</li>
                                                                    </ul>
                                                                {/if}
                                                            {/if}
                                                        {/if}
                                                        <br>
                                                    {/each}
                                                </td>
                                                <td>
                                                    <div>
                                                        Genomic: {patient.genomic_date || ''}
                                                    </div>
                                                    <div style="margin-top: 20px;">
                                                        Clinical: {patient.cdw_associated_date || ''}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="display: flex;">
                                                        {#if patient.case_glance.therapies.total !== 0}
                                                            <div style="margin-right: 14px; color: #98B249"
                                                                 aria-hidden="true">
                                                            <span title="The case has matched FDA/NCCN approved therapies">
                                                                <Icon data={faPrescriptionBottleAlt} scale="1.25"/>
                                                            </span>
                                                            </div>
                                                        {:else}
                                                            <div style="margin-right: 14px; color: #d98b95"
                                                                 aria-hidden="true">
                                                            <span title="The case has no matched FDA/NCCN approved therapies">
                                                                <Icon data={faPrescriptionBottleAlt} scale="1.25"/>
                                                            </span>
                                                            </div>
                                                        {/if}
                                                        {#if patient.case_glance.trials.all !== 0 &&
                                                        (patient.case_glance.trials.institutional_biomarker_match !== 0 ||
                                                            patient.case_glance.trials.institutional_non_biomarker_match !== 0)}
                                                            <div style="margin-right: 14px; color: #98B249"
                                                                 aria-hidden="true">
                                                            <span title="The case has matched clinical trials including institutional matches">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                            </div>
                                                        {:else if patient.case_glance.trials.all !== 0}
                                                            <div style="margin-right: 14px; color: #98B249"
                                                                 aria-hidden="true">
                                                            <span title="The case has matched clinical trials not including any institutional matches">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                            </div>
                                                        {:else}
                                                            <div style="margin-right: 14px; color: #d98b95"
                                                                 aria-hidden="true">
                                                            <span title="The case has no matched clinical trials">
                                                                <Icon data={faFileMedicalAlt} scale="1.25"/>
                                                            </span>
                                                            </div>
                                                        {/if}
                                                        {#if patient.case_glance.markers.discordant_markers_count > 0}
                                                            <div style="margin-right: 14px; color: #d98b95"
                                                                 aria-hidden="true">
                                                            <span title="This case has alterations where the GO interpretation does not match the vendor intepretation">
                                                                <img src="static/images/discordance_icon.png"
                                                                     class="icon-png" alt="">
                                                            </span>
                                                            </div>
                                                        {/if}
                                                        <div on:click={() => openRightNav(patient.case_glance)}
                                                             aria-hidden="true">
                                                        <span title="View">
                                                            <Icon data={faEye} scale="1.25"/>
                                                        </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button class="select-button"
                                                            on:click={() => selectOption("patient-match", patient.case_uuids[0])}>
                                                        Select Case
                                                    </button>
                                                </td>
                                            </tr>
                                        {/each}
                                        </tbody>
                                    </table>
                                {/if}
                            </div>
                        </div>
                    </right>
                </HSplitPane>
            {/if}
        </left>
        <right slot="right">
            {#if (populateRightPane)}
                <div style="display:flex; justify-content:center; align-items: center;" on:click={() => closeRightNav()}
                     aria-hidden="true">
                    <h3 style="margin-right: 10px;font-weight: bold; font-size: 20px; text-decoration: underline;">Case
                        at a Glance</h3>
                    <div>
                        <Icon data="{faCircleXmark}"/>
                    </div>
                </div>
                <div style="text-align: left; margin-left: 5px;">
                    <div style="margin-bottom: 50px">
                        <div style="margin-bottom: 15px">
                            <h3> Clinical Trials </h3>
                        </div>
                        <div style="margin-bottom: 15px">
                            <h3> Total Matches: {currentCaseGlance.trials.all} </h3>
                        </div>
                        <div style="margin-bottom: 5px">
                            <div> In-House Biomarker
                                Matched: {currentCaseGlance.trials.institutional_biomarker_match} </div>
                        </div>
                        <div style="margin-bottom: 5px">
                            <div> In-House Non-Biomarker
                                Matched: {currentCaseGlance.trials.institutional_non_biomarker_match} </div>
                        </div>
                        <div>
                            <div> Outside: {currentCaseGlance.trials.outside_trials} </div>
                        </div>
                    </div>
                    <div>
                        <div style="margin-bottom: 15px">
                            <h3> Therapies </h3>
                        </div>
                        <div style="margin-bottom: 15px">
                            <h3> Total Matches: {currentCaseGlance.therapies.total} </h3>
                        </div>
                        <div style="margin-bottom: 5px">
                            <div> FDA Approved For Tumor Type: {currentCaseGlance.therapies.approved_for_tumor} </div>
                        </div>
                        <div style="margin-bottom: 5px">
                            <div> FDA Approved For Other Tumor
                                Type: {currentCaseGlance.therapies.approved_other_tumor} </div>
                        </div>
                        <div style="margin-bottom: 5px">
                            <div> Resistant: {currentCaseGlance.therapies.resistant} </div>
                        </div>
                        <div>
                            <div> NCCN Recommended: {currentCaseGlance.therapies.fda_approved} </div>
                        </div>
                    </div>
                    {#if (currentCaseGlance.markers.discordant_markers_count > 0)}
                        <div>
                            <div style="margin-bottom: 15px">
                                <h3> Interpretations </h3>
                            </div>
                            <div style="margin-bottom: 5px">
                                <p> Total Concordant: {currentCaseGlance.markers.concordant_markers_count} </p>
                                <p> Total Discordant: {currentCaseGlance.markers.discordant_markers_count} </p>
                            </div>
                        </div>
                    {/if}
                </div>
            {/if}
        </right>
    </HSplitPane>
</div>