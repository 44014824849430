<style>
    table {
        padding: 25px;
    }

    h1 {
        text-decoration: underline;
    }

    .content-row {
        color: #233C6B;
        display: flex;
        justify-content: space-around; /* This evenly spaces the children with space around them */
        align-items: center; /* This centers the children vertically */
        margin-left: 10%;
        width: 80%; /* Take the full width of the parent container */
    }

    .summary-content {
        background-color: #f0faf0;
        color: #233C6B;
        margin-top: 2em;
        display: flex;
        flex-direction: column; /* Stack the icon and the title vertically */
        align-items: flex-start; /* Center the icon and text horizontally in the container */
        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        justify-content: space-around;
        height: 275px;
        width: 40%;
        padding-left: 2em;
    }

    .icon-content {
        background-color: #f0faf0;
        cursor: pointer;
        display: flex;
        flex-direction: column; /* Stack the icon and the title vertically */
        align-items: center; /* Center the icon and text horizontally in the container */
        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        justify-content: space-around;
        height: 200px;
        width: 200px;
    }

    .icon-button {
        color: #233C6B;
    }

    .icon-title {
        font-weight: bold;
        font-size: 16px;
        color: #233C6B;
    }
    .content-header{
        font-weight: bold;
        font-size: 22px;
    }
    .sub-summary-content{
        padding-left: 50px;
        font-size: 18px;
    }
    .main-summary-content{
        font-size: 20px;
    }
    .content-header-container{
        display: flex;
        justify-content: center;
    }
    div.wrapper {
        height: calc(80vh - 50px);
        margin: 10px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 2px;
    }
    div.header {
        margin: 0 10px 10px;
        height: 75px;
        background-color: #BBD5DD;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .header-icon {
        display: flex;
        align-items: center;
    }

    .header-title {
        color: #233C6B;
        width: 100%;
        text-align: center;
    }
</style>

<script>
    import {getBaseNavigationUrl} from '../../utils/urls';
    import {
        addToHistory,
        goBackward,
        goBackwardSteps,
        goForward, goForwardSteps,
        selectedOption,
        subscribe
    } from "../../store/navigation";
    import {Icon} from "svelte-awesome";
    import DashboardHeader from "./DashboardHeader.svelte";
    import {HSplitPane} from 'svelte-split-pane';
    import {getPdModOverrideVar, getUser} from "../../store/session";
    import {
        faUsers,
        faCircleInfo,
        faHospitalUser,
        faChartSimple,
        faTriangleExclamation,
        faBell,
        faBuildingColumns,
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleRight, faCircleLeft, faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {getDashboardInfo} from "../../network/dashboard";
    import {onMount} from "svelte";
    import MainFooter from "../shared/MainFooter.svelte";
    import MainHeader from "../shared/MainHeader.svelte";
    import Modal from "../Modal.svelte";

    let iconTooltip = "";
    let iconScale = "3.75";
    let newNotificationsCount = 0;
    let newCaseCount = 0;
    let newFMICount = 0;
    let newCarisCount = 0;
    let newGuardantCount = 0;
    let newTempusCount = 0;
    let newOtherCount = 0;
    let newTherapyCount = 0;
    let newFDACount = 0;
    let newNCCNCount = 0;
    let totalCaseCount = 0;
    let defaultInstName = "";
    let totalInstTrialCount = 0;
    let totalTherapyCount = 0;
    let oneWeekAgo = "";
    let totalFDACount = 0;
    let totalNCCNCount = 0;
    let totalFMICount = 0;
    let totalCarisCount = 0;
    let totalGuardantCount = 0;
    let totalTempusCount = 0;
    let totalOtherCount = 0;
    let needsReviewModal;

    let leftPaneInfoWidth = "100%";
    let rightPaneInfoWidth = "0%";
    let rightPaneDescription = '';

    onMount(async () => {
        await retrieveDashboardInfo();
        if (getUser().num_patients_needing_review > 0) {
            needsReviewModal.open();
        }
    });

    function selectOption(option) {
        addToHistory(option);
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }

    let startTime;
    let timer;
    let openBackMenu = false;
    let openForwardMenu = false;
    let backMenuOptions = [];
    let forwardMenuOptions = [];
    let currentMenuIndex = -1;


    function handleMouseDown(clickDirection) {
        // close both menus
        openBackMenu = false;
        openForwardMenu = false;
        // Record the start time when mouse is pressed down
        startTime = Date.now();
        // Start a timer when mouse is pressed down
        timer = setTimeout(() => {
            // If the timer expires, it indicates a click-and-hold action
            // Clear the timer to prevent the regular click action from executing
            clearTimeout(timer);
            timer = null;
            // Trigger the click-and-hold action
            if (clickDirection === 'back') {
                console.log('open go back div');
                // close both menus
                openBackMenu = true;
                openForwardMenu = false;
            } else if (clickDirection === 'forward') {
                console.log('open go forward div');
                // close both menus
                openBackMenu = false;
                openForwardMenu = true;
            }
        }, 500);
    }

    function handleMouseUp(clickDirection) {
        // Clear the timer when mouse is released
        clearTimeout(timer);
        timer = null;
        // Get the duration of the click
        const holdTime = Date.now() - startTime;
        if (holdTime < 500) {
            // If the duration is less than 500 milliseconds, it's a regular click
            // Trigger the regular click action
            if (clickDirection === 'back') {
                goBackward();
            } else if (clickDirection === 'forward') {
                goForward();
            }
        }
    }

    const unsubscribe = subscribe(value => {
        backMenuOptions = value.history.slice(0, value.currentIndex);
        forwardMenuOptions = value.history.slice(value.currentIndex + 1);
        currentMenuIndex = value.currentIndex;
    });

    function openRightNav() {
        leftPaneInfoWidth = "80%";
        rightPaneInfoWidth = "20%";
        rightPaneDescription='explanation'
    }

    function closeRightNav() {
        leftPaneInfoWidth = "100%";
        rightPaneInfoWidth = "0%";
        rightPaneDescription='';
    }

    async function retrieveDashboardInfo() {
        const response = await getDashboardInfo();
        newNotificationsCount = response.total_alerts_past_week;
        //new cases
        newCaseCount = response.total_cases_past_week.total_count;
        newFMICount = response.total_cases_past_week.fmi_count;
        newCarisCount = response.total_cases_past_week.caris_count;
        newGuardantCount = response.total_cases_past_week.guardant_count;
        newTempusCount = response.total_cases_past_week.tempus_count;
        newOtherCount = response.total_cases_past_week.other_count;
        newFDACount = response.therapies_past_week.FDA;
        newNCCNCount = response.therapies_past_week.NCCN;
        newTherapyCount = newFDACount + newNCCNCount;
        //total cases
        totalCaseCount = response.total_cases.total_count;
        totalFMICount = response.total_cases.fmi_count;
        totalCarisCount = response.total_cases.caris_count;
        totalGuardantCount = response.total_cases.guardant_count;
        totalTempusCount = response.total_cases.tempus_count;
        totalOtherCount = response.total_cases.other_count;
        defaultInstName = response.inst_name;
        totalInstTrialCount = response.institution_trials_map[response.inst_name];
        //total therapies
        totalFDACount = response.therapies_total.FDA;
        totalNCCNCount = response.therapies_total.NCCN;
        totalTherapyCount = totalNCCNCount + totalFDACount;
        oneWeekAgo = response.one_week_ago;
    }
</script>


<MainHeader/>
<div class="header">
    <div class="header-icon">
        <div style="display: flex;">
            <div>
                <div on:mousedown={()=>{handleMouseDown('back')}} on:mouseup={()=>{handleMouseUp('back')}} aria-hidden="true">
                    <Icon data="{faCircleLeft}" scale="1.75" style="margin-left: 10px; color: {backMenuOptions.length>0 ? '#233C6B' : '#9d9d9f'}; cursor:pointer;"/>
                </div>
                <div style="margin-left: 10px; position: absolute; z-index: 9999; visibility: {openBackMenu ? 'visible' : 'hidden'}; border: 1px solid #ccc; background-color: white; padding: 10px; margin-right: 10px;">
                    {#each backMenuOptions.slice().reverse() as option, index}
                        <div class="hover-list-item" on:click={()=>{console.log(currentMenuIndex); console.log(option); console.log(index); console.log('back'); goBackwardSteps(index+1);}} aria-hidden="true">{option}</div>
                    {/each}
                </div>
            </div>
            <div>
                <div on:mousedown={()=>{handleMouseDown('forward')}} on:mouseup={()=>{handleMouseUp('forward')}} aria-hidden="true">
                    <Icon data="{faCircleRight}" scale="1.75" style="margin-left: 10px; color:{forwardMenuOptions.length>0 ? '#233C6B' : '#9d9d9f'}; cursor:pointer;"/>
                </div>
                <div style="margin-left: 10px; position: absolute; z-index: 9999; visibility: {openForwardMenu ? 'visible' : 'hidden'}; border: 1px solid #ccc; background-color: white; padding: 10px; margin-right: 10px;">
                    {#each forwardMenuOptions as option, index}
                        <div class="hover-list-item" on:click={()=>{console.log(currentMenuIndex); console.log(option); console.log(index); console.log('forward'); goForwardSteps(index+1);}} aria-hidden="true">{option}</div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
    <div class="header-title">
        <DashboardHeader/>
    </div>
</div>
<div class="wrapper">
    <HSplitPane leftPaneSize="{leftPaneInfoWidth}" rightPaneSize="{rightPaneInfoWidth}" minLeftPaneSize="65%" minRightPaneSize="{rightPaneInfoWidth}">
        <left slot="left">
<div>
    <div class="content-row">
        <div class="summary-content">
            <div style="width: 100%;">
                <div class="content-header-container">
                    <span class="content-header">New Content Since {oneWeekAgo}</span>
                </div>
                <span class="main-summary-content"><b>{newNotificationsCount}</b> Notifications</span>
                <br/>
                <span class="main-summary-content"><b>{getUser().num_patients_needing_review}</b> Patients Needing Review</span>
                <br/>
                <span class="main-summary-content"><b>{newCaseCount}</b> New Cases</span>
                <br/>
                <span class="sub-summary-content"><b>{newOtherCount}</b> {defaultInstName}</span>
                <br/>
                <span class="sub-summary-content"><b>{newTempusCount}</b> Tempus</span>
                <br/>
                <span class="sub-summary-content"><b>{newFMICount}</b> Foundation</span>
                <br/>
                <span class="sub-summary-content"><b>{newGuardantCount}</b> Guardant</span>
                <br/>
                <span class="sub-summary-content"><b>{newCarisCount}</b> Caris</span>
                <br/>
                <span class="main-summary-content"><b>{newTherapyCount}</b> New Level 1 Therapies</span>
                <br/>
                <span class="sub-summary-content"><b>{newFDACount}</b> FDA Approved</span>
                <br/>
                <span class="sub-summary-content"><b>{newNCCNCount}</b> NCCN Guidelines</span>
            </div>
        </div>
        <div class="summary-content">
            <div style="width: 100%;">
                <div class="content-header-container">
                    <span class="content-header">Summary in Database</span>
                </div>
                <span class="main-summary-content"><b>{totalCaseCount}</b> Total Cases</span>
                <br/>
                <span class="sub-summary-content"><b>{totalOtherCount}</b> {defaultInstName}</span>
                <br/>
                <span class="sub-summary-content"><b>{totalTempusCount}</b> Tempus</span>
                <br/>
                <span class="sub-summary-content"><b>{totalFMICount}</b> Foundation</span>
                <br/>
                <span class="sub-summary-content"><b>{totalGuardantCount}</b> Guardant</span>
                <br/>
                <span class="sub-summary-content"><b>{totalCarisCount}</b> Caris</span>
                <br/>
                <span class="main-summary-content"><b>{totalInstTrialCount}</b> Total {defaultInstName} Trials</span>
                <br/>
                <span class="main-summary-content"><b>{totalTherapyCount}</b> Level 1 Therapies</span>
                <br/>
                <span class="sub-summary-content"><b>{totalFDACount}</b> FDA Approved</span>
                <br/>
                <span class="sub-summary-content"><b>{totalNCCNCount}</b> NCCN Guidelines</span>
            </div>
        </div>
    </div>

    <div class="content-row">
        <div style="margin: 4em;">
            <span style="font-size: 32px; font-weight: bold;">LaunchPad</span>
            <span on:click={() => openRightNav()} aria-hidden="true">
                <Icon style="margin-bottom: 10px; color: #9FCE67;" data={faCircleInfo} scale="1.75"/>
            </span>
        </div>
    </div>
    <div class="content-row">
        {#if (getPdModOverrideVar().includes('patient_match'))}
            <div class="icon-content" on:click={() => selectOption('patient-search')} aria-hidden="true">
                <div>
                    <div class="icon-button">
                        <Icon data="{faHospitalUser}" scale="{iconScale}"/>
                    </div>
                    <span class="icon-title">Patient<br/>Search</span>
                </div>
            </div>
        {/if}
        {#if (getPdModOverrideVar().includes('analytics'))}
            <a href="/Analytics" target="_blank">
                <div class="icon-content">
                    <div>
                        <div class="icon-button">
                            <Icon data="{faChartSimple}" style="margin-left: 10px" scale="{iconScale}"/>
                        </div>
                        <span class="icon-title">Analytics</span>
                    </div>
                </div>
            </a>
        {/if}
        {#if (getPdModOverrideVar().includes('alerts'))}
            <div class="icon-content" on:click={() => selectOption('alert-dashboard')} aria-hidden="true">
                <div>
                    <div class="icon-button">
                        <Icon data="{faTriangleExclamation}" scale="{iconScale}"/>
                    </div>
                    <span class="icon-title" style="margin-left: 6px" >Alerts</span>
                </div>
            </div>
            <div class="icon-content" on:click={() => selectOption('notifications')} aria-hidden="true">
                <div>
                    <div class="icon-button">
                        <Icon data="{faBell}" style="margin-left: 21px" scale="{iconScale}"/>
                    </div>
                    <span class="icon-title">Notifications</span>
                </div>
            </div>
        {/if}
        {#if (getPdModOverrideVar().includes('institutional_insights'))}
            <div class="icon-content" on:click={() => selectOption('institutional-insights')} aria-hidden="true">
                <div>
                    <div class="icon-button">
                        <Icon data="{faBuildingColumns}" style="margin-left: 12px" scale="{iconScale}"/>
                    </div>
                    <span class="icon-title">Institutional<br/>Insights</span>
                </div>
            </div>
        {/if}
        {#if (getPdModOverrideVar().includes('patient_tracking'))}
            <div class="icon-content" on:click={() => selectOption('patient-management')} aria-hidden="true">
                <div>
                    <div class="icon-button">
                        <Icon data="{faUsers}" style="margin-left: 12px" scale="{iconScale}"/>
                    </div>
                    <span class="icon-title">Patient<br/>Management</span>
                </div>
            </div>
        {/if}
    </div>
</div>
        </left>
        <right slot="right">
            {#if (rightPaneDescription === 'explanation')}
                <div style="display:flex; justify-content:center; align-items: center; color: #233C6B;">
                    <h3 style="margin-right: 10px;font-weight: bold; font-size: 20px; text-decoration: underline;">Modal Explanation</h3>
                    <div on:click={() => closeRightNav()} aria-hidden="true">
                        <Icon data="{faCircleXmark}"/>
                    </div>
                </div>
                <div style="margin:2em; font-size: 18px; color: #233C6B;">
                    <p><b>Patient Search:</b> Find patient cases using a variety of searchable parameters.</p>
                    <p><b>Analytics:</b> Analyze entire database by performing clinicogenomic queries.</p>
                    <p><b>Alerts:</b> Create and manage precision medicine alerts to automate process of patient identification.</p>
                    <p><b>Notifications:</b> View patients identified via alerts.</p>
                    <p><b>Patient Management:</b> Manage and track patients.</p>
                    <p><b>Institutional Insights:</b> View all institutional trials in depth including curation, matched patients and ability to directly set alerts.</p>
                </div>
            {/if}
        </right>
    </HSplitPane>
</div>
<MainFooter/>


<!--Patients Need Reviewing  Modal-->
<Modal bind:modalFunc={needsReviewModal}>
    <div slot="header" style="background-color: lightgray; padding-top: 2px; box-shadow: 0px 0px 0px 20px lightgray; margin-bottom: 35px">
        <div>
            <h1 style="color: red; text-decoration: none;">Patient Needs Review Notice</h1>
        </div>
    </div>
    <div slot="content" style="display: flex; justify-content: center; align-items: center">
        <div style="max-width: 300px; font-size: 20px">
            There
            {#if getUser().num_patients_needing_review === 1}
                is {getUser().num_patients_needing_review} patient that needs
            {:else}
                are {getUser().num_patients_needing_review} patients that need
            {/if}
                review - please proceed to the patient management page.
        </div>
    </div>
    <div slot="footer">
        <button class="modal-button" style=" float: right; background-color: lightcoral; padding: 0.5rem 1rem; margin-top: 10px" on:click={() => needsReviewModal.close()}>Close</button>
    </div>
</Modal>