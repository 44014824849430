import {callApi} from "./rest";

const ROOT_URL_CONSULT_CASES = '/consult/cases';

export const getCasesWithUuids = async (fields, uuids, currentPage, selectedPageSize) => {
    let url = `${ROOT_URL_CONSULT_CASES}?fields=${fields}&page=${currentPage}&page_size=${selectedPageSize}`;
    uuids.split(",").forEach((uuid) => {
        url = url + `&uuid=${uuid}`;
    });
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get cases");
    }
};