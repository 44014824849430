<script>
    import {Icon} from "svelte-awesome";
    import {faPenToSquare, faXmark, faSave} from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark, faCircleRight, faCircleLeft,} from "@fortawesome/free-regular-svg-icons";
    import {pane, selectedCase} from "../../store/patientMatch";
    import {additionalPatientDetails} from "../../store/patientMatch";
    import {HSplitPane} from "svelte-split-pane";
    import PatientInfoLeftNav from "./PatientInfoLeftNav.svelte";
    import {afterUpdate, createEventDispatcher, onMount, tick} from 'svelte';
    import {suggestDiagnosis} from "../../network/patient_search";

    const dispatch = createEventDispatcher();

    let vitalStatus = 'N/A';
    let race = 'N/A';
    let diagnosisDate = 'N/A';
    let gender = 'N/A';
    let diagnosisSuggestions = [];
    let selectedDiagnoses = $selectedCase.specific_diseases || [];
    let diagnosis = '';
    let diagnosisDisplay = 'none';
    let diagInput;

    let selectedCancerICD10Codes = [];
    let cancerICD10Code = '';

    let selectedCancerTypes = [];
    let cancerType = '';

    let selectedECOGValues = [];
    let ECOGValue = '';

    let selectedICD10Codes = [];
    let ICD10Code = '';

    let editPage = false;
    let formattedDate = '';
    let viewAddlFieldsPane = false;
    let stage = 'N/A';

    let activeDiv;
    let isResizing = false;

    let resizableDivs = [];
    let mainDiv;

    $: maxICD10Height = selectedCancerICD10Codes && selectedCancerICD10Codes.length > 0
        ? `calc(${selectedCancerICD10Codes.length} * 24px)`
        : '24px';

    $: maxICDAdditionalHeight = selectedICD10Codes && selectedICD10Codes.length > 0
        ? `calc(${selectedICD10Codes.length} * 24px)`
        : '24px';

    function addResizableDiv(div) {
        if (div && !resizableDivs.includes(div)) {
            resizableDivs.push(div);
            div.addEventListener('mousedown', (e) => onMouseDown(e, div));
        }
    }

    function onMouseMove(event) {
        if (isResizing && activeDiv) {
            const newHeight = event.clientY - activeDiv.getBoundingClientRect().top;
            activeDiv.style.height = `${newHeight}px`;

            const combinedHeight = resizableDivs.reduce((maxHeight, div) => {
                return Math.max(maxHeight, div.scrollHeight);
            }, 0);

            const mainDivMaxHeight = parseInt(getComputedStyle(mainDiv).maxHeight);

            if (combinedHeight > mainDivMaxHeight) {
                mainDiv.style.height = `${combinedHeight}px`;
                mainDiv.scrollTop = mainDiv.scrollHeight;
            } else {
                mainDiv.style.height = `${Math.max(combinedHeight, mainDivMaxHeight)}px`;
            }
        }
    }

    function onMouseUp() {
        isResizing = false;
        activeDiv = null;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    }

    function onMouseDown(event, div) {
        isResizing = true;
        activeDiv = div;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }

    function handleClickOutside(event){
        if (diagInput && !diagInput.contains(event.target)) {
            diagnosisDisplay = 'none';
        }
    }
    afterUpdate(async () => {
        await tick(); // Wait for the DOM to update
        resizableDivs.forEach(div => {
            div.addEventListener('mousedown', (e) => onMouseDown(e, div));
        });
    });


    onMount(() => {
        document.addEventListener('click', handleClickOutside);

        if ($selectedCase && $selectedCase.performance_statuses) {
            for (let perfStatus of $selectedCase.performance_statuses) {
                if (perfStatus.type === 'ECOG') {
                    selectedECOGValues = [...new Set([...selectedECOGValues, perfStatus.value])];
                }
            }
        }

        if ($selectedCase && $selectedCase.meta) {
            selectedCancerTypes = [...new Set($selectedCase.meta.Cancer_Type || [])];
            selectedCancerICD10Codes = [...new Set($selectedCase.meta.ICD10__Cancer__code || [])];
            selectedICD10Codes = [...new Set($selectedCase.meta.ICD10__Non__Cancer__code || [])];
        }

        if ($selectedCase && $selectedCase.patient_information && $selectedCase.patient_information[0] && $selectedCase.patient_information[0].race) {
            race = $selectedCase.patient_information[0].race[0] || 'N/A';
            gender = $selectedCase.patient_information[0].gender || 'N/A';
            vitalStatus = $selectedCase.patient_information[0].vital_status || 'N/A';
        }

        if ($selectedCase && $selectedCase.diagnoses && $selectedCase.diagnoses[0]) {
            diagnosisDate = $selectedCase.diagnoses[0].date_of_diagnosis !== null ? $selectedCase.diagnoses[0].date_of_diagnosis : "N/A";
            stage = $selectedCase.diagnoses[0].stage !== null ? $selectedCase.diagnoses[0].stage : "N/A";
        }
    });


    if ($selectedCase && $selectedCase.patient_information && $selectedCase.patient_information[0]) {
        formattedDate = formatDate($selectedCase.patient_information[0].date_of_birth);
    } else {
        console.warn("No patient information available or empty selected case.");
    }

    function toggleEditMode() {
        editPage = !editPage;
    }


    function handleECOGValueKeyUp() {
        if (event.key === 'Enter') {
            handleECOGValueSelect(ECOGValue);
        }
    }

    const removeECOGValue = (index) => {
        selectedECOGValues = selectedECOGValues.filter((_, i) => i !== index);
    };
    const handleECOGValueSelect = (input) => {
        selectedECOGValues = [input, ...selectedECOGValues];
        ECOGValue = "";
    };

    function handleICD10CodeKeyUp() {
        if (event.key === 'Enter') {
            handleICD10CodeSelect(ICD10Code);
        }
    }

    const removeICD10Code = (index) => {
        selectedICD10Codes = selectedICD10Codes.filter((_, i) => i !== index);
    };
    const handleICD10CodeSelect = (input) => {
        selectedICD10Codes = [input, ...selectedICD10Codes];
        ICD10Code = "";
    };

    function handleCancerICD10KeyUp() {
        if (event.key === 'Enter') {
            handleCancerICD10CodeSelect(cancerICD10Code);
        }
    }

    const removeCancerICD10Code = (index) => {
        selectedCancerICD10Codes = selectedCancerICD10Codes.filter((_, i) => i !== index);
    };
    const handleCancerICD10CodeSelect = (input) => {
        selectedCancerICD10Codes = [input, ...selectedCancerICD10Codes];
        cancerICD10Code = "";
    };

    function handleCancerTypeKeyUp() {
        if (event.key === 'Enter') {
            handleCancerTypeSelect(cancerType);
        }
    }

    const removeCancerType = (index) => {
        selectedCancerTypes = selectedCancerTypes.filter((_, i) => i !== index);
    };
    const handleCancerTypeSelect = (input) => {
        selectedCancerTypes = [input, ...selectedCancerTypes];
        cancerType = "";
    };


    const removeDiagnosis = (index) => {
        selectedDiagnoses = selectedDiagnoses.filter((_, i) => i !== index);
    };
    const fetchDiagnosisSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDiagnosis(diagnosis);

            if (Array.isArray(suggestion_resp['results'])) {
                diagnosisSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid diagnosis suggestions response:", suggestion_resp);
                diagnosisSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching diagnosis suggestions:", error);
        }
    };
    const handleDiagnosisInput = async () => {
        diagnosisDisplay='block';
        await fetchDiagnosisSuggestions();
    };
    const handleDiagnosisSelect = (suggestion) => {
        selectedDiagnoses = [suggestion, ...selectedDiagnoses];
        diagnosis = "";
        diagnosisSuggestions = [];
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
</script>
{#if (viewAddlFieldsPane)}
    <HSplitPane rightPaneSize={viewAddlFieldsPane ? "80%" : "100%"} leftPaneSize={viewAddlFieldsPane ? "20%" : "0%"}
                minRightPaneSize=80% minLeftPaneSize={viewAddlFieldsPane ? "20%" : "0%"}>
        <left slot="left">
            {#if (viewAddlFieldsPane)}
                <svelte:component this={PatientInfoLeftNav}/>
            {/if}
        </left>
        <right slot="right">
            <div style="width: 95%; display: flex; flex-direction: column; justify-content: space-between; position: relative;">
                <table>
                    <thead>
                    <th colSpan="2">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <div style="cursor: pointer; display:flex; justify-content:start;"
                                 on:click={() => {viewAddlFieldsPane = !viewAddlFieldsPane}}>
                                {#if !viewAddlFieldsPane}
                                    <Icon data="{faCircleRight}" scale="1.5" style="color: #233C6B;"/>
                                    <p style="font-weight: normal; position: absolute; top: -2.5px; left: 30px; z-index: 999;">
                                        Open Additional Fields Pane
                                    </p>
                                {:else}
                                    <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7;"/>
                                    <p style="font-weight: normal; position: absolute; top: -2.5px; left: 30px; z-index: 999;">
                                        Close Additional Fields Pane
                                    </p>
                                {/if}
                            </div>
                            <div style="display: flex; align-items: center; justify-content: flex-start">
                                <h1 style="margin: 0;">Patient Information</h1>
                                <div style="cursor: pointer;" on:click={toggleEditMode}>
                                    {#if editPage}
                                        <Icon data="{faCircleXmark}" scale="1.75"
                                              style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                                        <Icon data="{faSave}" scale="1.75"
                                              style="margin-left: 15px; color:#9FCE67; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faPenToSquare}" scale="1.75"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </div>
                            </div>
                            <div on:click={() => pane.set("backToSummary")}>
                                <Icon data="{faXmark}" scale="1.75"
                                      style="margin-right:-100px; color:black; cursor:pointer;"/>
                            </div>
                        </div>
                    </th>
                    </thead>
                </table>
            </div>
            <div style="max-height: 61vh; overflow-y: auto; width: 100%;" bind:this={mainDiv}>
                <table>
                    <tr>
                        <td style="vertical-align: top; width: 50%">
                            <div class="patient-details-row">
                                {#if editPage}
                                    <!-- Edit mode -->
                                    <div class="input-container">
                                        <h3>Patient Name:</h3>
                                        <input type="text" class="input-field" bind:value={$selectedCase.patient_name}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Patient MRN:</h3>
                                        <input type="text" class="input-field" bind:value={$selectedCase.patient_mrn}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Birth:</h3>
                                        <input type="date" class="input-field" bind:value={formattedDate}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Gender:</h3>
                                        <select class="select-field"
                                                bind:value={gender}>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="unknown">Unknown</option>
                                        </select>
                                    </div>
                                    <div class="input-container">
                                        <h3>Stage:</h3>
                                        <input type="text" class="input-field">
                                    </div>
                                {:else}
                                    <!-- View mode -->
                                    <div class="input-container">
                                        <h3>Patient Name:</h3>
                                        <p>{$selectedCase.patient_name || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Patient MRN:</h3>
                                        <p>{$selectedCase.patient_mrn || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Birth:</h3>
                                        <p>{formattedDate || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Gender:</h3>
                                        <p>{gender || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Stage: </h3>
                                        <p>{stage}</p>
                                    </div>
                                {/if}
                            </div>
                        </td>
                        <td style="vertical-align: top; width: 50%;">
                            <div class="patient-details-row">
                                {#if editPage}
                                    <!-- Edit mode -->
                                    <div class="selected-values-container">
                                        {#each selectedDiagnoses as value, index (value)}
                                                <span class="selected-value">
                                                    {value}
                                                    <span class="remove-selection" on:click={() => removeDiagnosis(index)}>x</span>
                                                </span>
                                        {/each}
                                    </div>
                                    <div class="input-container" bind:this={diagInput}>
                                        <h3>Diagnosis: </h3>
                                        <div class="type-ahead" on:click={() => diagnosisDisplay = diagnosisDisplay === 'none' ? 'block' : 'none'}>
                                            <input
                                                    type="text"
                                                    class="input-field"
                                                    bind:value={diagnosis}
                                                    on:input={handleDiagnosisInput}
                                                    on:click={handleDiagnosisInput}
                                            />
                                            <div class="suggestions" style="display:{diagnosisDisplay}">
                                                {#each diagnosisSuggestions as diag}
                                                    <div class="suggestion-item"
                                                         on:click={() => handleDiagnosisSelect(diag)}>{diag}</div>
                                                {/each}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Diagnosis:</h3>
                                        <input type="date" class="input-field"
                                               bind:value={diagnosisDate}>
                                    </div>
                                    <div style="margin-top: 5px;">
                                        {#each selectedCancerICD10Codes as value, index (value)}
                                            <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerICD10Code(index)}>x</span>
                                                        </span>
                                            </div>
                                        {/each}
                                    </div>
                                    <div class="input-container">
                                        <h3>Cancer ICD10 Codes:</h3>
                                        <input
                                                type="text"
                                                class="input-field"
                                                bind:value={cancerICD10Code}
                                                on:keyup={handleCancerICD10KeyUp}
                                        />
                                        <br/>
                                    </div>
                                {:else}
                                    <!-- View mode -->
                                    <div class="input-container">
                                        <h3>Diagnosis: </h3>
                                        <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                            {#each $selectedCase.specific_diseases as disease, index}
                                                <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{disease}</p>
                                            {/each}
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Diagnosis:</h3>
                                        <p>{diagnosisDate}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Cancer ICD10 Codes: </h3>
                                        <div style="display:flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            margin-top: 15px;
                                            margin-left: 5px;
                                            overflow:scroll;
                                            resize: vertical;
                                            transition: height 0.1s ease-in;
                                            border: 1px solid #ccc;
                                            min-height: 50px;
                                            max-height: {maxICD10Height}"
                                             use:addResizableDiv>

                                            {#if selectedCancerICD10Codes.length > 0}
                                                {#each selectedCancerICD10Codes as code, index}
                                                    <p style="margin:0">{code}</p>
                                                {/each}
                                            {:else}
                                                <p class="table-body">N/A</p>
                                            {/if}
                                        </div>
                                    </div>
                                {/if}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <h2 style="visibility: {$additionalPatientDetails.length > 0 ? 'visible' : 'hidden'}">
                                Additional Fields:
                            </h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="vertical-align: top; width: 50%;">
                            <div class="patient-details-row">
                                {#each $additionalPatientDetails.slice(0, 2) as _, index}
                                    {#if $additionalPatientDetails[index]}
                                        {#if (editPage)}
                                            {#if $additionalPatientDetails[index].value === "vital_status"}
                                                <div class="input-container">
                                                    <h3>Vital Status:</h3>
                                                    <input type="text" class="input-field" bind:value={vitalStatus}>
                                                </div>
                                            {:else if $additionalPatientDetails[index].value === "race"}
                                                <div class="input-container">
                                                    <h3>Race:</h3>
                                                    <input type="text" class="input-field" bind:value={race}>
                                                </div>
                                            {:else if $additionalPatientDetails[index].value === "cancer_type"}
                                                <div style="margin-top: 5px;">
                                                    {#each selectedCancerTypes as value, index (value)}
                                                        <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerType(index)}>x</span>
                                                        </span>
                                                        </div>
                                                    {/each}
                                                </div>
                                                <div class="input-container">
                                                    <h3>Cancer Type:</h3>
                                                    <input
                                                            type="text"
                                                            class="input-field"
                                                            bind:value={cancerType}
                                                            on:keyup={handleCancerTypeKeyUp}
                                                    />
                                                    <br/>
                                                </div>
                                            {:else if $additionalPatientDetails[index].value === "ecog_value"}
                                                <div class="input-container">
                                                    <h3>ECOG Value:</h3>
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedECOGValues as ecog, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                        {/each}
                                                    </div>
                                                </div>
                                            {:else if $additionalPatientDetails[index].value === "icd10_codes"}
                                                <div style="margin-top: 5px;">
                                                    {#each selectedICD10Codes as value, index (value)}
                                                        <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeICD10Code(index)}>x</span>
                                                        </span>
                                                        </div>
                                                    {/each}
                                                </div>
                                                <div class="input-container">
                                                    <h3>ICD10 Code:</h3>
                                                    <input
                                                            type="text"
                                                            class="input-field"
                                                            bind:value={ICD10Code}
                                                            on:keyup={handleICD10CodeKeyUp}
                                                    />
                                                    <br/>
                                                </div>
                                            {/if}
                                        {:else}
                                            <div class="input-container">
                                                <h3>{$additionalPatientDetails[index].label}:</h3>
                                                {#if $additionalPatientDetails[index].value === "vital_status"}
                                                    <p class="table-body">{vitalStatus}</p>
                                                {:else if $additionalPatientDetails[index].value === "race"}
                                                    <p class="table-body">{race}</p>
                                                {:else if $additionalPatientDetails[index].value === "cancer_type"}
                                                    {#if selectedCancerTypes.length > 0}
                                                        <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                            {#each selectedCancerTypes as cType, index}
                                                                <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{cType}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {:else if $additionalPatientDetails[index].value === "ecog_value"}
                                                    {#if selectedECOGValues.length > 0}
                                                        <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                            {#each selectedECOGValues as ecog, index}
                                                                <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {:else if $additionalPatientDetails[index].value === "icd10_codes"}
                                                    {#if selectedICD10Codes.length > 0}
                                                        <div style="display:flex;
                                                        flex-direction: column;
                                                        align-items: flex-start;
                                                        margin-top: 15px;
                                                        margin-left: 5px;
                                                        overflow: scroll;
                                                        resize: vertical;
                                                        transition: height 0.1s ease-in;
                                                        border: 1px solid #ccc;
                                                        height: 50px;
                                                        max-height: {maxICDAdditionalHeight}"
                                                             use:addResizableDiv>
                                                            {#each selectedICD10Codes as icd10, index}
                                                                <p style="margin-bottom: 0; margin-top:0;">{icd10}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {/if}
                                            </div>
                                        {/if}
                                    {:else}
                                        <div style="padding-top: 55px; padding-right: 5px;">
                                        </div>
                                    {/if}
                                {/each}
                            </div>
                        </td>
                        <td style="vertical-align: top; width: 50%">
                            <div class="patient-details-row">
                                {#each $additionalPatientDetails.slice(2) as _, index}
                                    {#if $additionalPatientDetails[index + 2]}
                                        {#if (editPage)}
                                            {#if $additionalPatientDetails[index + 2].value === "vital_status"}
                                                <div class="input-container">
                                                    <h3>Vital Status:</h3>
                                                    <input type="text" class="input-field" bind:value={vitalStatus}>
                                                </div>
                                            {:else if $additionalPatientDetails[index + 2].value === "race"}
                                                <div class="input-container">
                                                    <h3>Race:</h3>
                                                    <input type="text" class="input-field" bind:value={race}>
                                                </div>
                                            {:else if $additionalPatientDetails[index + 2].value === "cancer_type"}
                                                <div style="margin-top: 5px;">
                                                    {#each selectedCancerTypes as value, index (value)}
                                                        <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerType(index)}>x</span>
                                                        </span>
                                                        </div>
                                                    {/each}
                                                </div>
                                                <div class="input-container">
                                                    <h3>Cancer Type:</h3>
                                                    <input
                                                            type="text"
                                                            class="input-field"
                                                            bind:value={cancerType}
                                                            on:keyup={handleCancerTypeKeyUp}
                                                    />
                                                    <br/>
                                                </div>
                                            {:else if $additionalPatientDetails[index + 2].value === "ecog_value"}
                                                <div class="input-container">
                                                    <h3>ECOG Value:</h3>
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedECOGValues as ecog, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                        {/each}
                                                    </div>
                                                </div>
                                            {:else if $additionalPatientDetails[index + 2].value === "icd10_codes"}
                                                <div style="margin-top: 5px;">
                                                    {#each selectedICD10Codes as value, index (value)}
                                                        <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeICD10Code(index)}>x</span>
                                                        </span>
                                                        </div>
                                                    {/each}
                                                </div>
                                                <div class="input-container">
                                                    <h3>ICD10 Code:</h3>
                                                    <input
                                                            type="text"
                                                            class="input-field"
                                                            bind:value={ICD10Code}
                                                            on:keyup={handleICD10CodeKeyUp}
                                                    />
                                                    <br/>
                                                </div>
                                            {/if}
                                        {:else}
                                            <div class="input-container">
                                                <h3>{$additionalPatientDetails[index + 2].label}:</h3>
                                                {#if $additionalPatientDetails[index + 2].value === "vital_status"}
                                                    <p class="table-body">{vitalStatus}</p>
                                                {:else if $additionalPatientDetails[index + 2].value === "race"}
                                                    <p class="table-body">{race}</p>
                                                {:else if $additionalPatientDetails[index + 2].value === "cancer_type"}
                                                    {#if selectedCancerTypes.length > 0}
                                                        <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                            {#each selectedCancerTypes as cType, index}
                                                                <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{cType}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {:else if $additionalPatientDetails[index + 2].value === "ecog_value"}
                                                    {#if selectedECOGValues.length > 0}
                                                        <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                            {#each selectedECOGValues as ecog, index}
                                                                <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {:else if $additionalPatientDetails[index + 2].value === "icd10_codes"}
                                                    {#if selectedICD10Codes.length > 0}
                                                        <div style="display:flex;
                                                        flex-direction: column;
                                                        align-items: flex-start;
                                                        margin-top: 15px;
                                                        margin-left: 5px;
                                                        overflow: scroll;
                                                        resize: vertical;
                                                        transition: height 0.1s ease-in;
                                                        border: 1px solid #ccc;
                                                        height: 50px;
                                                        max-height: {maxICDAdditionalHeight};"
                                                             use:addResizableDiv>

                                                            {#each selectedICD10Codes as icd10, index}
                                                                <p style="margin-bottom: 0; margin-top:0;">{icd10}</p>
                                                            {/each}
                                                        </div>
                                                    {:else}
                                                        <p class="table-body"> N/A</p>
                                                    {/if}
                                                {/if}
                                            </div>
                                        {/if}
                                    {:else}
                                        <div style="padding-top: 55px; padding-right: 5px;">
                                        </div>
                                    {/if}
                                {/each}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div style="display: flex; justify-content: flex-end;">
                                <button class='save-button'
                                        style="visibility: {editPage ? 'visible' : 'hidden'}">
                                    Save
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </right>
    </HSplitPane>
{:else}
    <div style="display: flex; flex-direction: column; width:100vw;">
        <div style="width: 95%; display: flex; flex-direction: column; justify-content: space-between; position: relative;">
            <table>
                <thead>
                <th colSpan="2">
                    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                        <div style="cursor: pointer; display:flex; justify-content:start;"
                             on:click={() => {viewAddlFieldsPane = !viewAddlFieldsPane}}>
                            {#if !viewAddlFieldsPane}
                                <Icon data="{faCircleRight}" scale="1.5" style="color: #233C6B;"/>
                                <p style="font-weight: normal; position: absolute; top: -2.5px; left: 30px; z-index: 999;">
                                    Open Additional Fields Pane
                                </p>
                            {:else}
                                <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7;"/>
                                <p style="font-weight: normal; position: absolute; top: -2.5px; left: 30px; z-index: 999;">
                                    Close Additional Fields Pane
                                </p>
                            {/if}
                        </div>
                        <div style="display: flex; align-items: center; justify-content: flex-start">
                            <h1 style="margin: 0;">Patient Information</h1>
                            <div style="cursor: pointer;" on:click={toggleEditMode}>
                                {#if editPage}
                                    <Icon data="{faCircleXmark}" scale="1.75"
                                          style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                                    <Icon data="{faSave}" scale="1.75"
                                          style="margin-left: 15px; color:#9FCE67; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faPenToSquare}" scale="1.75"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </div>
                        </div>
                        <div on:click={() => pane.set("backToSummary")}>
                            <Icon data="{faXmark}" scale="1.75"
                                  style="margin-right:-100px; color:black; cursor:pointer;"/>
                        </div>
                    </div>
                </th>
                </thead>
            </table>
        </div>
        <div style="max-height: 61vh; overflow-y: auto; width: 100%;" bind:this={mainDiv}>
            <table>
                <tr>
                    <td style="vertical-align: top; width: 50%">
                        <div class="patient-details-row">
                            {#if editPage}
                                <!-- Edit mode -->
                                <div class="input-container">
                                    <h3>Patient Name:</h3>
                                    <input type="text" class="input-field" bind:value={$selectedCase.patient_name}>
                                </div>
                                <div class="input-container">
                                    <h3>Patient MRN:</h3>
                                    <input type="text" class="input-field" bind:value={$selectedCase.patient_mrn}>
                                </div>
                                <div class="input-container">
                                    <h3>Date of Birth:</h3>
                                    <input type="date" class="input-field" bind:value={formattedDate}>
                                </div>
                                <div class="input-container">
                                    <h3>Gender:</h3>
                                    <select class="select-field"
                                            bind:value={gender}>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="unknown">Unknown</option>
                                    </select>
                                </div>
                                <div class="input-container">
                                    <h3>Stage:</h3>
                                    <input type="text" class="input-field">
                                </div>
                            {:else}
                                <!-- View mode -->
                                <div class="input-container">
                                    <h3>Patient Name:</h3>
                                    <p>{$selectedCase.patient_name || 'N/A'}</p>
                                </div>
                                <div class="input-container">
                                    <h3>Patient MRN:</h3>
                                    <p>{$selectedCase.patient_mrn || 'N/A'}</p>
                                </div>
                                <div class="input-container">
                                    <h3>Date of Birth:</h3>
                                    <p>{formattedDate || 'N/A'}</p>
                                </div>
                                <div class="input-container">
                                    <h3>Gender:</h3>
                                    <p>{gender || 'N/A'}</p>
                                </div>
                                <div class="input-container">
                                    <h3>Stage: </h3>
                                    <p>{stage}</p>
                                </div>
                            {/if}
                        </div>
                    </td>
                    <td style="vertical-align: top; width: 50%;">
                        <div class="patient-details-row">
                            {#if editPage}
                                <!-- Edit mode -->
                                <div class="selected-values-container">
                                    {#each selectedDiagnoses as value, index (value)}
                                                <span class="selected-value">
                                                    {value}
                                                    <span class="remove-selection" on:click={() => removeDiagnosis(index)}>x</span>
                                                </span>
                                    {/each}
                                </div>
                                <div class="input-container" bind:this={diagInput}>
                                    <h3>Diagnosis: </h3>
                                    <div class="type-ahead" on:click={() => diagnosisDisplay = diagnosisDisplay === 'none' ? 'block' : 'none'}>
                                        <input
                                                type="text"
                                                class="input-field"
                                                bind:value={diagnosis}
                                                on:input={handleDiagnosisInput}
                                                on:click={handleDiagnosisInput}
                                        />
                                        <div class="suggestions" style="display:{diagnosisDisplay}">
                                            {#each diagnosisSuggestions as diag}
                                                <div class="suggestion-item"
                                                     on:click={() => handleDiagnosisSelect(diag)}>{diag}</div>
                                            {/each}
                                        </div>
                                    </div>
                                </div>
                                <div class="input-container">
                                    <h3>Date of Diagnosis:</h3>
                                    <input type="date" class="input-field"
                                           bind:value={diagnosisDate}>
                                </div>
                                <div style="margin-top: 5px;">
                                    {#each selectedCancerICD10Codes as value, index (value)}
                                        <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerICD10Code(index)}>x</span>
                                                        </span>
                                        </div>
                                    {/each}
                                </div>
                                <div class="input-container">
                                    <h3>Cancer ICD10 Codes:</h3>
                                    <input
                                            type="text"
                                            class="input-field"
                                            bind:value={cancerICD10Code}
                                            on:keyup={handleCancerICD10KeyUp}
                                    />
                                    <br/>
                                </div>
                            {:else}
                                <!-- View mode -->
                                <div class="input-container">
                                    <h3>Diagnosis: </h3>
                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                        {#each $selectedCase.specific_diseases as disease, index}
                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{disease}</p>
                                        {/each}
                                    </div>
                                </div>
                                <div class="input-container">
                                    <h3>Date of Diagnosis:</h3>
                                    <p>{diagnosisDate}</p>
                                </div>
                                <div class="input-container">
                                    <h3>Cancer ICD10 Codes: </h3>
                                    <div style="display:flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            margin-top: 15px;
                                            margin-left: 5px;
                                            overflow:scroll;
                                            resize: vertical;
                                            transition: height 0.1s ease-in;
                                            border: 1px solid #ccc;
                                            min-height: 50px;
                                            max-height: {maxICD10Height}"
                                         use:addResizableDiv>

                                        {#if selectedCancerICD10Codes.length > 0}
                                            {#each selectedCancerICD10Codes as code, index}
                                                <p style="margin:0">{code}</p>
                                            {/each}
                                        {:else}
                                            <p class="table-body">N/A</p>
                                        {/if}
                                    </div>
                                </div>
                            {/if}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <h2 style="visibility: {$additionalPatientDetails.length > 0 ? 'visible' : 'hidden'}">
                            Additional Fields:
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td style="vertical-align: top; width: 50%;">
                        <div class="patient-details-row">
                            {#each $additionalPatientDetails.slice(0, 2) as _, index}
                                {#if $additionalPatientDetails[index]}
                                    {#if (editPage)}
                                        {#if $additionalPatientDetails[index].value === "vital_status"}
                                            <div class="input-container">
                                                <h3>Vital Status:</h3>
                                                <input type="text" class="input-field" bind:value={vitalStatus}>
                                            </div>
                                        {:else if $additionalPatientDetails[index].value === "race"}
                                            <div class="input-container">
                                                <h3>Race:</h3>
                                                <input type="text" class="input-field" bind:value={race}>
                                            </div>
                                        {:else if $additionalPatientDetails[index].value === "cancer_type"}
                                            <div style="margin-top: 5px;">
                                                {#each selectedCancerTypes as value, index (value)}
                                                    <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerType(index)}>x</span>
                                                        </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>Cancer Type:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        bind:value={cancerType}
                                                        on:keyup={handleCancerTypeKeyUp}
                                                />
                                                <br/>
                                            </div>
                                        {:else if $additionalPatientDetails[index].value === "ecog_value"}
                                            <div class="input-container">
                                                <h3>ECOG Value:</h3>
                                                <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                    {#each selectedECOGValues as ecog, index}
                                                        <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                    {/each}
                                                </div>
                                            </div>
                                        {:else if $additionalPatientDetails[index].value === "icd10_codes"}
                                            <div style="margin-top: 5px;">
                                                {#each selectedICD10Codes as value, index (value)}
                                                    <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeICD10Code(index)}>x</span>
                                                        </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>ICD10 Code:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        bind:value={ICD10Code}
                                                        on:keyup={handleICD10CodeKeyUp}
                                                />
                                                <br/>
                                            </div>
                                        {/if}
                                    {:else}
                                        <div class="input-container">
                                            <h3>{$additionalPatientDetails[index].label}:</h3>
                                            {#if $additionalPatientDetails[index].value === "vital_status"}
                                                <p class="table-body">{vitalStatus}</p>
                                            {:else if $additionalPatientDetails[index].value === "race"}
                                                <p class="table-body">{race}</p>
                                            {:else if $additionalPatientDetails[index].value === "cancer_type"}
                                                {#if selectedCancerTypes.length > 0}
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedCancerTypes as cType, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{cType}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {:else if $additionalPatientDetails[index].value === "ecog_value"}
                                                {#if selectedECOGValues.length > 0}
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedECOGValues as ecog, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {:else if $additionalPatientDetails[index].value === "icd10_codes"}
                                                {#if selectedICD10Codes.length > 0}
                                                    <div style="display:flex;
                                                        flex-direction: column;
                                                        align-items: flex-start;
                                                        margin-top: 15px;
                                                        margin-left: 5px;
                                                        overflow: scroll;
                                                        resize: vertical;
                                                        transition: height 0.1s ease-in;
                                                        border: 1px solid #ccc;
                                                        height: 50px;
                                                        max-height: {maxICDAdditionalHeight}"
                                                         use:addResizableDiv>
                                                        {#each selectedICD10Codes as icd10, index}
                                                            <p style="margin-bottom: 0; margin-top:0;">{icd10}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {/if}
                                        </div>
                                    {/if}
                                {:else}
                                    <div style="padding-top: 55px; padding-right: 5px;">
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </td>
                    <td style="vertical-align: top; width: 50%">
                        <div class="patient-details-row">
                            {#each $additionalPatientDetails.slice(2) as _, index}
                                {#if $additionalPatientDetails[index + 2]}
                                    {#if (editPage)}
                                        {#if $additionalPatientDetails[index + 2].value === "vital_status"}
                                            <div class="input-container">
                                                <h3>Vital Status:</h3>
                                                <input type="text" class="input-field" bind:value={vitalStatus}>
                                            </div>
                                        {:else if $additionalPatientDetails[index + 2].value === "race"}
                                            <div class="input-container">
                                                <h3>Race:</h3>
                                                <input type="text" class="input-field" bind:value={race}>
                                            </div>
                                        {:else if $additionalPatientDetails[index + 2].value === "cancer_type"}
                                            <div style="margin-top: 5px;">
                                                {#each selectedCancerTypes as value, index (value)}
                                                    <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeCancerType(index)}>x</span>
                                                        </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>Cancer Type:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        bind:value={cancerType}
                                                        on:keyup={handleCancerTypeKeyUp}
                                                />
                                                <br/>
                                            </div>
                                        {:else if $additionalPatientDetails[index + 2].value === "ecog_value"}
                                            <div class="input-container">
                                                <h3>ECOG Value:</h3>
                                                <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                    {#each selectedECOGValues as ecog, index}
                                                        <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                    {/each}
                                                </div>
                                            </div>
                                        {:else if $additionalPatientDetails[index + 2].value === "icd10_codes"}
                                            <div style="margin-top: 5px;">
                                                {#each selectedICD10Codes as value, index (value)}
                                                    <div class="selected-values-container">
                                                        <span class="selected-value">
                                                            {value}
                                                            <span class="remove-selection"
                                                                  on:click={() => removeICD10Code(index)}>x</span>
                                                        </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>ICD10 Code:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        bind:value={ICD10Code}
                                                        on:keyup={handleICD10CodeKeyUp}
                                                />
                                                <br/>
                                            </div>
                                        {/if}
                                    {:else}
                                        <div class="input-container">
                                            <h3>{$additionalPatientDetails[index + 2].label}:</h3>
                                            {#if $additionalPatientDetails[index + 2].value === "vital_status"}
                                                <p class="table-body">{vitalStatus}</p>
                                            {:else if $additionalPatientDetails[index + 2].value === "race"}
                                                <p class="table-body">{race}</p>
                                            {:else if $additionalPatientDetails[index + 2].value === "cancer_type"}
                                                {#if selectedCancerTypes.length > 0}
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedCancerTypes as cType, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{cType}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {:else if $additionalPatientDetails[index + 2].value === "ecog_value"}
                                                {#if selectedECOGValues.length > 0}
                                                    <div style="display:flex; flex-direction: column; align-items: flex-start; max-height:100px; overflow-x: scroll">
                                                        {#each selectedECOGValues as ecog, index}
                                                            <p style="margin-bottom: 0; margin-top: {index === 0 ? '15px' : '0'}">{ecog}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {:else if $additionalPatientDetails[index + 2].value === "icd10_codes"}
                                                {#if selectedICD10Codes.length > 0}
                                                    <div style="display:flex;
                                                        flex-direction: column;
                                                        align-items: flex-start;
                                                        margin-top: 15px;
                                                        margin-left: 5px;
                                                        overflow: scroll;
                                                        resize: vertical;
                                                        transition: height 0.1s ease-in;
                                                        border: 1px solid #ccc;
                                                        height: 50px;
                                                        max-height: {maxICDAdditionalHeight};"
                                                         use:addResizableDiv>

                                                        {#each selectedICD10Codes as icd10, index}
                                                            <p style="margin-bottom: 0; margin-top:0;">{icd10}</p>
                                                        {/each}
                                                    </div>
                                                {:else}
                                                    <p class="table-body"> N/A</p>
                                                {/if}
                                            {/if}
                                        </div>
                                    {/if}
                                {:else}
                                    <div style="padding-top: 55px; padding-right: 5px;">
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div style="display: flex; justify-content: flex-end;">
                            <button class='save-button'
                                    style="visibility: {editPage ? 'visible' : 'hidden'}">
                                Save
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
{/if}

<style>

    .save-button {
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h3 {
        font-size: 16px;
        text-align: left;
    }

    p {
        font-size: 14px;
        padding: 4px;
        text-align: left;
    }

    .select-field {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        margin-top: 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .input-field {
        font-size: 14px;
        margin-top: 15px;
        margin-left: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .input-container {
        display: flex;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 100vw;
    }

    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-bottom: 5px;
        align-items: center;
    }

    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    .patient-details-row {
        margin-left: 10px;
    }

    .suggestions {
        z-index: 1;
        background-color: #f9f9f9;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
        width:200px;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    .type-ahead{
        width: 100px;
    }
</style>

