<script>
    import {HSplitPane} from 'svelte-split-pane';
    import {Icon} from "svelte-awesome";
    import {getBaseNavigationUrl} from '../../utils/urls';
    import {getPdModOverrideVar} from "../../store/session";
    import MainFooter from "./MainFooter.svelte";

    import {
        addToHistory, expandMainHeader,
        goBackward, goBackwardSteps,
        goForward,
        goForwardSteps,
        selectedOption,
        subscribe
    } from "../../store/navigation";
    import {onMount, onDestroy} from 'svelte';
    import {
        faHospitalUser,
        faChartSimple,
        faTriangleExclamation,
        faBell,
        faBuildingColumns,
        faUsers
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleLeft, faCircleRight} from "@fortawesome/free-regular-svg-icons";
    let showLabels = true;
    let iconTooltip = "";

    export let subHeader = null;
    export let mainContent = null;
    export let leftPaneSize = "5%";
    export let rightPaneSize = "95%";

    onMount(() => {
        handleUpdate();
        document.addEventListener('click', handleClickOutside);
    });

    const handleUpdate = (event) => {
        const iconContainer = document.querySelector('.icon-container');
        const iconContainerWidth = iconContainer.offsetWidth;
        showLabels = iconContainerWidth > 100;

    };

    function selectOption(option) {
        addToHistory(option);
        if (option !== "analytics") {
            selectedOption.set(option);
            window.location.href = `${getBaseNavigationUrl()}#/${option}`;
        }
    }

    let startTime;
    let timer;
    let openBackMenu = false;
    let openForwardMenu = false;
    let backMenuOptions = [];
    let forwardMenuOptions = [];
    let currentMenuIndex = -1;
    let clickCounter = 0;


    function handleClickOutside(event) {
        clickCounter += 1;
        if (clickCounter > 1){
            openBackMenu = false;
            openForwardMenu = false;
        }
    }


    function handleMouseDown(clickDirection) {
        // close both menus
        clickCounter=0;
        openBackMenu = false;
        openForwardMenu = false;
        // Record the start time when mouse is pressed down
        startTime = Date.now();
        // Start a timer when mouse is pressed down
        timer = setTimeout(() => {
            // If the timer expires, it indicates a click-and-hold action
            // Clear the timer to prevent the regular click action from executing
            clearTimeout(timer);
            timer = null;
            // Trigger the click-and-hold action
            if (clickDirection === 'back') {
                // close both menus
                openBackMenu = true;
                openForwardMenu = false;
            } else if (clickDirection === 'forward') {
                // close both menus
                openBackMenu = false;
                openForwardMenu = true;
            }
        }, 500);
    }

    function handleMouseUp(clickDirection) {
        // Clear the timer when mouse is released
        clearTimeout(timer);
        timer = null;
        // Get the duration of the click
        const holdTime = Date.now() - startTime;
        if (holdTime < 500) {
            // If the duration is less than 500 milliseconds, it's a regular click
            // Trigger the regular click action
            if (clickDirection === 'back') {
                goBackward();
            } else if (clickDirection === 'forward') {
                goForward();
            }
        }
    }

    const unsubscribe = subscribe(value => {
        backMenuOptions = value.history.slice(0, value.currentIndex);
        forwardMenuOptions = value.history.slice(value.currentIndex+1);
        currentMenuIndex = value.currentIndex;
    });

    onDestroy(() => {
        document.removeEventListener('click', handleClickOutside);
    });

</script>

<div class="header" style="height: {$expandMainHeader ? '150px' : '75px'}">
    <div class="header-icon">
        <div style="display: flex;">
            <div>
                <div on:mousedown={()=>{handleMouseDown('back')}} on:mouseup={()=>{handleMouseUp('back')}} aria-hidden="true">
                    <Icon data="{faCircleLeft}" scale="1.75" style="margin-left: 10px; color: {backMenuOptions.length>0 ? '#233C6B' : '#9d9d9f'}; cursor:pointer;"/>
                </div>
                <div style="margin-left: 10px; position: absolute; z-index: 9999; visibility: {openBackMenu ? 'visible' : 'hidden'}; border: 1px solid #ccc; background-color: white; padding: 10px; margin-right: 10px;">
                    {#each backMenuOptions.slice().reverse() as option, index}
                        <div class="hover-list-item" on:click={()=>{goBackwardSteps(index+1);}} aria-hidden="true">{option}</div>
                    {/each}
                </div>
            </div>
            <div>
                <div on:mousedown={()=>{handleMouseDown('forward')}} on:mouseup={()=>{handleMouseUp('forward')}} aria-hidden="true">
                    <Icon data="{faCircleRight}" scale="1.75" style="margin-left: 10px; color:{forwardMenuOptions.length>0 ? '#233C6B' : '#9d9d9f'}; cursor:pointer;"/>
                </div>
                <div style="margin-left: 10px; position: absolute; z-index: 9999; visibility: {openForwardMenu ? 'visible' : 'hidden'}; border: 1px solid #ccc; background-color: white; padding: 10px; margin-right: 10px;">
                    {#each forwardMenuOptions as option, index}
                        <div class="hover-list-item" on:click={()=>{goForwardSteps(index+1);}} aria-hidden="true">{option}</div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
    <div class="header-title" style="height: {$expandMainHeader ? '100%' : ''}">
        {#if subHeader}
            <svelte:component this={subHeader}/>
        {/if}
    </div>
</div>

<div class="wrapper">
    <HSplitPane leftPaneSize={leftPaneSize} rightPaneSize={rightPaneSize} minLeftPaneSize="100px"
                minRightPaneSize="89vw" updateCallback="{handleUpdate}">
        <left slot="left">
            <div class="icon-container">
                {#if (getPdModOverrideVar().includes('patient_match'))}
                    <div class="left-nav-content" on:click={() => selectOption('patient-search')} aria-hidden="true">
                        <div class="icon-button-top" on:mouseover={() => {iconTooltip='patient_search'}}
                             on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus>
                            <Icon data="{faHospitalUser}" scale="1.75"/>
                        </div>
                        <p class="tooltip"
                           style="visibility: {(iconTooltip==='patient_search' && !showLabels) ? 'visible' : 'hidden'}">
                            Patient Search </p>
                        {#if (showLabels)}
                            <span class="icon-title">Patient<br/>Search</span>
                        {/if}
                    </div>
                {/if}

                {#if (getPdModOverrideVar().includes('analytics'))}
                    <a href="/Analytics" target="_blank">
                        <div class="left-nav-content">
                            <div class="icon-button" on:mouseover={() => {iconTooltip='analytics'}}
                                 on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus>
                                <Icon data="{faChartSimple}" scale="1.75"/>
                            </div>
                            <p class="tooltip"
                               style="visibility: {(iconTooltip==='analytics' && !showLabels) ? 'visible' : 'hidden'}">
                                Analytics </p>
                            {#if (showLabels)}
                                <span class="icon-title">Analytics</span>
                            {/if}
                        </div>
                    </a>
                {/if}

                {#if (getPdModOverrideVar().includes('alerts'))}
                    <div class="left-nav-content" on:click={() => selectOption('alert-dashboard')} aria-hidden="true">
                        <div class="icon-button" on:mouseover={() => {iconTooltip='alerts'}}
                             on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus>
                            <Icon data="{faTriangleExclamation}" scale="1.75"/>
                        </div>
                        <p class="tooltip"
                           style="visibility: {(iconTooltip==='alerts'  && !showLabels)? 'visible' : 'hidden'}">
                            Alerts </p>
                        {#if (showLabels)}
                            <span class="icon-title">Alerts</span>
                        {/if}
                    </div>
                    <div class="left-nav-content" on:click={() => selectOption('notifications')} aria-hidden="true">
                        <div class="icon-button" on:mouseover={() => {iconTooltip='notifications'}}
                             on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus >
                            <Icon data="{faBell}" scale="1.75"/>
                        </div>
                        <p class="tooltip"
                           style="visibility: {(iconTooltip==='notifications' && !showLabels) ? 'visible' : 'hidden'}">
                            Notifications </p>
                        {#if (showLabels)}
                            <span class="icon-title">Notifications</span>
                        {/if}
                    </div>
                {/if}

                {#if (getPdModOverrideVar().includes('institutional_insights'))}
                    <div class="left-nav-content" on:click={() => selectOption('institutional-insights')} aria-hidden="true">
                        <div class="icon-button" on:mouseover={() => {iconTooltip='institutional_insights'}}
                             on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus>
                            <Icon data="{faBuildingColumns}" scale="1.75"/>
                        </div>
                        <p class="tooltip"
                           style="visibility: {(iconTooltip==='institutional_insights' && !showLabels) ? 'visible' : 'hidden'}">
                            Institutional Insights </p>
                        {#if (showLabels)}
                            <span class="icon-title">Institutional<br/>Insights</span>
                        {/if}
                    </div>
                {/if}

                {#if (getPdModOverrideVar().includes('patient_tracking'))}
                    <div class="left-nav-content" on:click={() => selectOption('patient-management')} aria-hidden="true">
                        <div class="icon-button" on:mouseover={() => {iconTooltip='patient_management'}}
                             on:mouseleave={() => {iconTooltip=''}} aria-hidden="true" on:focus>
                            <Icon data="{faUsers}" scale="1.75"/>
                        </div>
                        <p class="tooltip"
                           style="visibility: {(iconTooltip==='patient_management' && !showLabels) ? 'visible' : 'hidden'}">
                            Patient Management </p>
                        {#if (showLabels)}
                            <span class="icon-title">Patient<br/>Management</span>
                        {/if}
                    </div>
                {/if}
            </div>
        </left>
        <right slot="right">
            {#if mainContent}
                <svelte:component this={mainContent}/>
            {/if}
        </right>
    </HSplitPane>
</div>
<MainFooter/>

<style>
    div.wrapper {
        height: calc(80vh - 50px);
        margin: 10px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 2px;
    }

    div.header {
        margin: 0 10px 10px;
        background-color: #BBD5DD;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .header-icon {
        display: flex;
        align-items: center;
    }

    .header-title {
        color: #233C6B;
        width: 100%;
        z-index:2;
        text-align: center;
    }

    div.main-content{
        height: 100%;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    left {
        height: 100%;
        display: block;
        text-align: center;
        background-color: #233C6B;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    right {
        height: 100%;
        display: block;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .icon-button {
        color: #ffffff;
        margin-left: 20px;
        margin-top: 9vh;
    }

    .icon-button-top {
        color: #ffffff;
        margin-left: 20px;
        margin-top: 4vh;
    }

    .left-nav-content {
        cursor: pointer;
        display: flex;
        align-items: self-end;
    }

    .icon-container {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .icon-title {
        margin-left: 10px;
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        text-align: left;
    }

    .tooltip {
        position: absolute;
        z-index: 9999;
        background-color: rgba(48, 78, 130, 0.83);
        color: #fff;
        padding: 8px;
        border-radius: 4px;
        font-size: 16px;
        margin-left: 50px;
        margin-top: 50px;
    }

    .hover-list-item:hover {
        background-color: lightgray;
        cursor: pointer;
    }

</style>
