<script>

    import AccordionSection from './accordion/AccordionSection.svelte';
    import DynamicFacetQueryInput from './DynamicFacetQueryInput.svelte';
    import {currentQuery, queryCount} from "../store/currentAlertQuery";
    import {RADIO_ALL} from "../utils/constants";
    import FilterComponent from "./FilterComponent/FilterComponent.svelte";
    import AccordionGroup from "./accordion/AccordionGroup.svelte";
    import RadioButtonFilter from "./FilterComponent/RadioButtonFilter.svelte";

    export let categoryLabel = '';
    export let filters;

    $: CharacteristicsCount = queryCount($currentQuery, [categoryLabel]);

    $: FacetFilters = filters.filter((f) => {
        return f["filterDisplay"] === "facet field";
    });

    $: SectionFilters = filters.filter((f) => {
        return f["filterDisplay"] === "section field";
    });

    $: OmniTypeFilter = filters.filter((f) => {
        return f["filterDisplay"] === "omni type";
    });

</script>

<!--Hide a section completely if the current user does not have permissions for said filter-->
<div hidden={FacetFilters.length === 0 && SectionFilters.length === 0}>
    <AccordionSection>

        <p slot="header">
            {categoryLabel}
            {#if CharacteristicsCount > 0 }
                <span class="query-count">({ CharacteristicsCount })</span>
            {/if}
        </p>
        {#if OmniTypeFilter.length > 0}
            <AccordionGroup>
                <AccordionSection header={OmniTypeFilter[0]["displayName"]}>
                    <div class="accordion-content">
                        <RadioButtonFilter
                                solrField={OmniTypeFilter[0]["solrField"]}
                                displayName={OmniTypeFilter[0]["displayName"]}
                                filterMode={RADIO_ALL}
                                core={OmniTypeFilter[0]["core"]}
                                queryLabel={categoryLabel}
                                allValues={["therapies", "trials"]}
                        />
                    </div>
                </AccordionSection>
            </AccordionGroup>
        {/if}
        {#if SectionFilters.length > 0}
            <AccordionGroup>
                {#each SectionFilters as filter}
                    <FilterComponent
                            solrField={filter["solrField"]}
                            displayName={filter["displayName"]}
                            inputType={filter["filterInput"]}
                            core={filter["core"]}
                            queryLabel={categoryLabel}
                    />
                {/each}
            </AccordionGroup>
        {/if}
        {#if FacetFilters.length > 0}
            <div class="accordion-content">
                <DynamicFacetQueryInput filters={FacetFilters} key={categoryLabel}/>
            </div>
        {/if}
    </AccordionSection>
</div>


<style>
    .accordion-content {
        padding: 1em;
    }
    .query-count {
        color: #508ad9;
    }
</style>