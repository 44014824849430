<script>
    // components
    import AccordionSection from '../accordion/AccordionSection.svelte';
    import FilterOptions from "../FilterOptions.svelte";
    import {RADIO_ALL} from "../../utils/constants";
    import fetchFacetOptions from "../../utils/fetchFacetOptions";

    import DropDownFilter from "./DropDownFilter.svelte";
    import MultiSelectFilter from "./MultiSelectFilter.svelte";
    import AutoCompleteFilter from "./AutoCompleteFilter.svelte";
    import RadioButtonFilter from "./RadioButtonFilter.svelte";
    import NumericalRangeFilter from "./NumericalRangeFilter.svelte";
    import {getDiseaseList} from "../../network/diseases";

    export let inputType;
    export let solrField;
    export let displayName;
    export let core;
    let filterMode = RADIO_ALL;
    export let startValue = 0;
    export let endValue = 100;
    export let queryLabel = "";


    async function fetchValues() {
        // This first check for 'Ontology Diseases' in display name was implemented so
        // that we can query specific_diseases on cases using any diseases that
        // exist in solr not just on the cases themselves.
        // Todo: If we create more options similar to this in the future, may want to reevaluate how we handle
        if (solrField === 'specific_diseases') {
            return await getDiseaseOntologyOptions();
        } else  if (inputType !== "numerical range") {
            return fetchFacetOptions(solrField);
        } else {
            return [];
        }
    }

    async function getDiseaseOntologyOptions() {
        let possibleValues = [];
        let maxCount = 2;
        let count = 1;
        while (count < maxCount) {
            const response = await getDiseaseList(count);
            count = count + 1;
            if (response.ok) {
                const body = await response.json();
                if (maxCount === 2) {
                    maxCount = body.pagination.total_pages + 1;
                }
                body.results.forEach(result => {
                    if (!possibleValues.includes(result.name)) {
                        possibleValues = [...possibleValues, result.name];
                    }
                });
            }
        }
        return possibleValues;
    }

    let promise = fetchValues();
</script>

<AccordionSection header={displayName}>
    <div class="accordion-content">
        <FilterOptions bind:value={filterMode} />
        <div class="query-row">
            {#await promise then allValues}
                {#if allValues.length && inputType === "multiselect"}
                    <MultiSelectFilter
                            solrField={solrField}
                            displayName={displayName}
                            filterMode={filterMode}
                            core={core}
                            allValues={allValues}
                            queryLabel={queryLabel}
                    />
                {/if}
                {#if allValues.length && inputType === "dropdown"}
                    <DropDownFilter
                            solrField={solrField}
                            displayName={displayName}
                            filterMode={filterMode}
                            core={core}
                            allValues={allValues}
                            queryLabel={queryLabel}
                    />
                {/if}
                {#if allValues.length && inputType === "type ahead"}
                    <AutoCompleteFilter
                            solrField={solrField}
                            displayName={displayName}
                            filterMode={filterMode}
                            core={core}
                            allValues={allValues}
                            queryLabel={queryLabel}
                    />
                {/if}
                {#if inputType === "numerical range"}
                    <NumericalRangeFilter
                            solrField={solrField}
                            displayName={displayName}
                            filterMode={filterMode}
                            core={core}
                            allValues={allValues}
                            queryLabel={queryLabel}
                            startValue={startValue}
                            endValue={endValue}
                            is_age_field={solrField === "age_in_months"}
                    />
                {/if}
                {#if inputType === "radio button"}
                    <RadioButtonFilter
                            solrField={solrField}
                            displayName={displayName}
                            filterMode={filterMode}
                            core={core}
                            allValues={allValues}
                            queryLabel={queryLabel}
                    />
                {/if}
            {/await}
        </div>
    </div>
</AccordionSection>

<style>
    /* Same styles as ExportFilter */

    .accordion-content {
        padding: 1em;
    }

    .query-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 1em;
        width: 80%;
    }

</style>