<script>
    import {Icon} from "svelte-awesome";
    import {faPenToSquare, faXmark, faChevronUp, faChevronDown, faPlusCircle, faCirclePlus, faTrash} from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {pane, selectedPatient} from "../../store/patientMatch";
    import {createEventDispatcher, onMount} from 'svelte';
    import {suggestDrugs} from "../../network/patient_search";

    const dispatch = createEventDispatcher();

    let editPage = false;

    function toggleEditMode() {
        editPage = !editPage;
    }

    let uniqueDrugs = new Set();
    let systemic = [];
    let radiation = [];
    let surgery = [];
    let displaySystemic = false;
    let displayRadiation = false;
    let displaySurgery = false;
    let dedupedOncologicTreatments = [];
    let addSurgery = false;
    let addRadiation = false;
    let addSystemic = false;

    let SurgeryDrugsInput = '';
    let SurgeryDrug = '';
    let SurgeryDrugsSuggestions = [];

    let SurgeryStartDateInput = '';
    let SurgeryEndDateSystemic = '';

    let SystemicDrugsInput = '';
    let SystemicDrug = '';
    let SystemicDrugSuggestions = [];

    let SystemicStartDateInput = '';
    let SystemicEndDateSystemic = '';

    let RadiationDrugsInput = '';
    let RadiationDrug = '';
    let RadiationDrugSuggestions = [];

    let RadiationStartDateInput = '';
    let RadiationEndDateSystemic = '';

    function toggleVisibility(type) {
        if (type === 'systemic') {
            displaySystemic = !displaySystemic;
        } else if (type === 'radiation') {
            displayRadiation = !displayRadiation;
        } else if (type === 'surgery') {
            displaySurgery = !displaySurgery;
        }
    }

    onMount(() => {
        dedupedOncologicTreatments = $selectedPatient.oncologic_treatments.filter(item => {
            if (uniqueDrugs.has(item.drugs)) {
                return false;
            } else {
                uniqueDrugs.add(item.drugs);
                return true;
            }
        });
    });

    $:{
        // reset lists if dedeupedOT list changes
        systemic = [];
        radiation = [];
        surgery = [];

        for (let oc of dedupedOncologicTreatments) {
            if (oc.type_of_therapy === 'Systemic Therapy') {
                systemic = [...systemic, oc];
            } else if (oc.type_of_therapy === 'Radiation') {
                radiation = [...radiation, oc];
            } else if (oc.type_of_therapy === 'Surgery') {
                surgery = [...surgery, oc];
            }
        }

        let treatments = [systemic, radiation, surgery];
        treatments.forEach(treatmentList => {
            treatmentList.forEach(treatment => {
                treatment.start_date = formatDate(treatment.start_date);
                treatment.end_date = formatDate(treatment.end_date);
            });
        });
    }

    function formatDate(dateString) {
        if (dateString && dateString !== 'N/A') {
            const date = new Date(dateString);
            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        } else {
            return "N/A";
        }

    }

    const handleSystemicDrugsSelect = (suggestion) => {
        SystemicDrugsInput = suggestion;
        SystemicDrug = suggestion;
        SystemicDrugSuggestions = [];
    };

    const handleSystemicDrugsInput = async () => {
        await fetchSystemicDrugsSuggestions();
    };

    const fetchSystemicDrugsSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDrugs(SystemicDrug);

            if (Array.isArray(suggestion_resp['results'])) {
                SystemicDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                SystemicDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };

    const handleRadiationDrugsSelect = (suggestion) => {
        RadiationDrugsInput = suggestion;
        RadiationDrug = suggestion;
        RadiationDrugSuggestions = [];
    };

    const handleRadiationDrugsInput = async () => {
        await fetchRadiationDrugsSuggestions();
    };

    const fetchRadiationDrugsSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDrugs(RadiationDrug);

            if (Array.isArray(suggestion_resp['results'])) {
                RadiationDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                RadiationDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };

    const handleSurgeryDrugsSelect = (suggestion) => {
        SurgeryDrugsInput = suggestion;
        SurgeryDrug = suggestion;
        SurgeryDrugsSuggestions = [];
    };

    const handleSurgeryDrugsInput = async () => {
        await fetchSurgeryDrugsSuggestions();
    };

    const fetchSurgeryDrugsSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDrugs(SurgeryDrug);

            if (Array.isArray(suggestion_resp['results'])) {
                SurgeryDrugsSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                SurgeryDrugsSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };

    function deleteDrug(drugName) {
        dedupedOncologicTreatments = dedupedOncologicTreatments.filter(ot => ot.drugs !== drugName);
    }

    // this is temporary function for testing page function, since saving patient will result in match results updating
    // and we are still making decisions on how to handle that across all patient match pages
    function createDrug(type) {
        // Add the newDrug object to the appropriate list based on the type
        // For example, if type === 'systemic', add to systemic list
        if (type === 'systemic') {
            const newSysDrug = {
                drugs: SystemicDrugsInput,
                start_date: SystemicStartDateInput,
                end_date: SystemicEndDateSystemic,
                type_of_therapy: 'Systemic Therapy'
            };
            dedupedOncologicTreatments = [...dedupedOncologicTreatments, newSysDrug];
            // reset values
            SystemicDrugsInput = '';
            SystemicStartDateInput = '';
            SystemicEndDateSystemic = '';
            SystemicDrug = '';
            SystemicDrugsInput;
        } else if (type === 'radiation') {
            const newRadDrug = {
                drugs: RadiationDrugsInput,
                start_date: RadiationStartDateInput,
                end_date: RadiationEndDateSystemic,
                type_of_therapy: 'Radiation'
            };
            dedupedOncologicTreatments = [...dedupedOncologicTreatments, newRadDrug];
            // reset values
            RadiationDrugsInput = '';
            RadiationStartDateInput = '';
            RadiationEndDateSystemic = '';
            RadiationDrug='';
            RadiationDrugsInput = '';
        } else if (type === 'surgery') {
            const newSurDrug = {
                drugs: SurgeryDrugsInput,
                start_date: SurgeryStartDateInput,
                end_date: SurgeryEndDateSystemic,
                type_of_therapy: 'Surgery'
            };
            dedupedOncologicTreatments = [...dedupedOncologicTreatments, newSurDrug];
            // reset values
            SurgeryDrugsInput = '';
            SurgeryStartDateInput = '';
            SurgeryEndDateSystemic = '';
            SurgeryDrug = '';
            SurgeryDrugsInput = '';
        }
    }

</script>

<div style="display: flex; flex-direction: column; width: 100vw;">
    <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px; width: 95%;">
        <div></div>
        <div style="display: flex; align-items: center; justify-content: flex-start">
            <h1 style="margin: 0;">Prior Treatment History</h1>
            <div style="cursor: pointer;" on:click={toggleEditMode}>
                {#if editPage}
                    <Icon data="{faCircleXmark}" scale="1.75"
                          style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                {:else}
                    <Icon data="{faPenToSquare}" scale="1.75"
                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                {/if}
            </div>
        </div>
        <div on:click={() => pane.set("backToSummary")}>
            <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
        </div>
    </div>

    <div style="height: 55vh; margin-left:10px; overflow-x: scroll;">
        <h2 style="text-align: left">Current Treatment Context:</h2>
        <div class="ot-title" on:click={() => {toggleVisibility('systemic')}}>
            <span></span>
            Systemic Therapy Count ({systemic.length})
            {#if (displaySystemic)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displaySystemic)}
            <div>
                {#each systemic as ot}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        {#if (editPage)}
                            <strong>Drug Name:</strong>
                            <span class="drug-entry">
                                {ot.drugs}
                                <Icon data="{faXmark}"/>
                            </span>

                            <strong>Start Date:</strong> <input type="date" bind:value={ot.start_date}>
                            <strong>End Date:</strong> <input type="date" bind:value={ot.end_date}>
                            <span style="margin-left:10px; cursor: pointer" on:click={()=>{deleteDrug(ot.drugs)}}>
                                <Icon data="{faTrash}" style="color: #d98b95"/>
                            </span>

                        {:else}
                            <strong>Drug Name:</strong> {ot.drugs}
                            <strong>Start Date:</strong> {ot.start_date}
                            <strong>End Date:</strong> {ot.end_date}
                        {/if}
                    </p>
                {/each}
                {#if (addSystemic)}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        <strong>Drug Name:</strong>
                        <input
                                type="text"
                                class="input-field"
                                bind:value={SystemicDrug}
                                on:input={handleSystemicDrugsInput}
                                on:click={handleSystemicDrugsInput}
                        />
                        {#each SystemicDrugSuggestions as drug}
                            <p on:click={() => handleSystemicDrugsSelect(drug)}>{drug}</p>
                        {/each}
                        <strong>Start Date:</strong> <input type="date" bind:value={SystemicStartDateInput}>
                        <strong>End Date:</strong> <input type="date" bind:value={SystemicEndDateSystemic}>
                        <span style="margin-left:10px; cursor: pointer">
                            <span on:click={()=>{createDrug("systemic"); addSystemic=false;}}>
                                <Icon data="{faCirclePlus}" style="color: #9FCE67"/>
                            </span>
                            <span on:click={()=> {addSystemic=false; SystemicDrugsInput=''; SystemicStartDateInput=''; SystemicEndDateSystemic=''; SystemicDrug = ''; SystemicDrugsInput='';}}>
                                <Icon data="{faCircleXmark}" style="color: #d98b95"/>
                            </span>
                        </span>
                    </p>
                {/if}
                {#if (editPage)}
                    <div class="add-entry-container" on:click={()=>{addSystemic=true}}>
                        <Icon data="{faPlusCircle}" scale="1.25" style="margin-right: 10px; color: #9FCE67;"/>
                        <strong>Add New Entry</strong>
                    </div>
                {/if}
            </div>
        {/if}
        <div class="ot-title" on:click={() => {toggleVisibility('radiation')}}>
            <span></span>
            Radiation Count ({radiation.length})
            {#if (displayRadiation)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displayRadiation)}
            <div>
                {#each radiation as ot}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        {#if (editPage)}
                            <strong>Drug Name:</strong>
                            <span class="drug-entry">
                                {ot.drugs}
                                <Icon data="{faXmark}"/>
                            </span>

                            <strong>Start Date:</strong> <input type="date" bind:value={ot.start_date}>
                            <strong>End Date:</strong> <input type="date" bind:value={ot.end_date}>
                            <span style="margin-left:10px; cursor: pointer" on:click={()=>{deleteDrug(ot.drugs)}}>
                                <Icon data="{faTrash}" style="color: #d98b95"/>
                            </span>

                        {:else}
                            <strong>Drug Name:</strong> {ot.drugs}
                            <strong>Start Date:</strong> {ot.start_date}
                            <strong>End Date:</strong> {ot.end_date}
                            <!--<strong>Disease State:</strong> {ot.disease_state}-->
                            <!--<strong>Therapeutic Context:</strong> {ot.therapeutic_context}-->
                        {/if}

                    </p>
                {/each}
                {#if (addRadiation)}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        <strong>Drug Name:</strong>
                        <input
                                type="text"
                                class="input-field"
                                bind:value={RadiationDrug}
                                on:input={handleRadiationDrugsInput}
                                on:click={handleRadiationDrugsInput}
                        />
                        {#each RadiationDrugSuggestions as drug}
                            <p on:click={() => handleRadiationDrugsSelect(drug)}>{drug}</p>
                        {/each}
                        <strong>Start Date:</strong> <input type="date" bind:value={RadiationStartDateInput}>
                        <strong>End Date:</strong> <input type="date" bind:value={RadiationEndDateSystemic}>
                        <span style="margin-left:10px; cursor: pointer">
                            <span on:click={()=>{createDrug("radiation");addRadiation=false;}}>
                                <Icon data="{faCirclePlus}" style="color: #9FCE67"/>
                            </span>
                            <span on:click={()=> {addRadiation=false; RadiationDrugsInput=''; RadiationStartDateInput=''; RadiationEndDateSystemic=''; RadiationDrugsInput = ''; RadiationDrug = '';}}>
                                <Icon data="{faCircleXmark}" style="color: #d98b95"/>
                            </span>
                        </span>
                    </p>
                {/if}
                {#if (editPage)}
                    <div class="add-entry-container" on:click={()=>{addRadiation=true}}>
                        <Icon data="{faPlusCircle}" scale="1.25" style="margin-right: 10px; color: #9FCE67;"/>
                        <strong>Add New Entry</strong>
                    </div>
                {/if}
            </div>
        {/if}
        <div class="ot-title" on:click={() => {toggleVisibility('surgery')}}>
            <span></span>
            Surgery Count ({surgery.length})
            {#if (displaySurgery)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displaySurgery)}
            <div>
                {#each surgery as ot}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        {#if (editPage)}
                            <strong>Drug Name:</strong>
                            <span class="drug-entry">
                                {ot.drugs}
                                <Icon data="{faXmark}"/>
                            </span>

                            <strong>Start Date:</strong> <input type="date" bind:value={ot.start_date}>
                            <strong>End Date:</strong> <input type="date" bind:value={ot.end_date}>
                            <span style="margin-left:10px; cursor: pointer" on:click={()=>{deleteDrug(ot.drugs)}}>
                                <Icon data="{faTrash}" style="color: #d98b95"/>
                            </span>

                        {:else}
                            <strong>Drug Name:</strong> {ot.drugs}
                            <strong>Start Date:</strong> {ot.start_date}
                            <strong>End Date:</strong> {ot.end_date}
                            <!--<strong>Disease State:</strong> {ot.disease_state}-->
                            <!--<strong>Therapeutic Context:</strong> {ot.therapeutic_context}-->
                        {/if}
                    </p>
                {/each}
                {#if (addSurgery)}
                    <p style="font-size: 16px; text-align: left; margin: 25px;">
                        <strong>Drug Name:</strong>
                        <input
                                type="text"
                                class="input-field"
                                bind:value={SurgeryDrug}
                                on:input={handleSurgeryDrugsInput}
                                on:click={handleSurgeryDrugsInput}
                        />
                        {#each SurgeryDrugsSuggestions as drug}
                            <p on:click={() => handleSurgeryDrugsSelect(drug)}>{drug}</p>
                        {/each}
                        <strong>Start Date:</strong> <input type="date" bind:value={SurgeryStartDateInput}>
                        <strong>End Date:</strong> <input type="date" bind:value={SurgeryEndDateSystemic}>
                        <span style="margin-left:10px; cursor: pointer">
                            <span on:click={()=>{createDrug("surgery");addSurgery=false;}}>
                                <Icon data="{faCirclePlus}" style="color: #9FCE67"/>
                            </span>
                            <span on:click={()=> {addSurgery=false; SurgeryDrugsInput=''; SurgeryStartDateInput=''; SurgeryEndDateSystemic=''; SurgeryDrugsInput = ''; SurgeryDrug = '';}}>
                                <Icon data="{faCircleXmark}" style="color: #d98b95"/>
                            </span>
                        </span>
                    </p>
                {/if}
                {#if (editPage)}
                    <div class="add-entry-container" on:click={()=>{addSurgery=true}}>
                        <Icon data="{faPlusCircle}" scale="1.25" style="margin-right: 10px; color: #9FCE67;"/>
                        <strong>Add New Entry</strong>
                    </div>
                {/if}
            </div>
        {/if}
    </div>

    <div style=" display: flex; justify-content: flex-end;">
        <button class='save-button' style="visibility: {editPage ? 'visible' : 'hidden'}">Save</button>
    </div>

</div>

<style>

    .add-entry-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        text-align: left;
        margin: 25px;
    }

    input {
        border: none;
        border-bottom: 1px solid black;
        background-color: transparent;
        outline: none;
    }

    .save-button {
        margin-right: 25px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h3 {
        font-size: 16px;
        width: 150px;
        text-align: right;
    }

    .ot-title {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 45vw;
        background-color: #f2f2f2;
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        cursor: pointer;
    }

    .drug-entry {
        margin-left: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

</style>

