<script>
    import {Icon} from "svelte-awesome";
    import {
        faUser,
        faFileMedical,
        faFlaskVial,
        faDna,
        faFilter,
        faTriangleExclamation
    } from "@fortawesome/free-solid-svg-icons";
    import {HSplitPane} from "svelte-split-pane";
    import {onMount} from "svelte";
    import PatientInformation from "./PatientInformation.svelte";
    import {createEventDispatcher} from 'svelte';
    import PatientMatchDashboard from "./PatientMatchDashboard.svelte";
    import TreatmentInformation from "./TreatmentInformation.svelte";
    import TestInformation from "./TestInformation.svelte";
    import BiomarkerDetails from "./BiomarkerDetails.svelte";
    import {
        matchTableSetting,
        openMatchResults,
        pane,
        leftFilterPaneState,
        recalculate_match, historical_match_params, viewUnsavedChanges
    } from "../../store/patientMatch";
    import {faCircleXmark as faCircleXmarkRegular, faCircleXmark} from "@fortawesome/free-regular-svg-icons";

    const dispatch = createEventDispatcher();

    let showLabels = true;
    let iconTooltip = '';
    let test1 = false;
    let test2 = false;
    let sigMarkers = false;
    let vusMarkers = false;
    let negMarkers = false;
    let addForthPane = false;
    let geneInfo = false;
    let annotationInfo = false;
    let editPage = '';

    onMount(() => {
        handleUpdate();
    });


    const handleUpdate = (event) => {
        // const iconContainer = document.querySelector('.iconContainer');
        // const iconContainerWidth = iconContainer.offsetWidth;
        // showLabels = iconContainerWidth > 150;
    };

    function toggleCollapseItem(item) {
        if (item === 'test1') {
            test1 = !test1;
        } else if (item === 'test2') {
            test2 = !test2;
        } else if (item === 'sigMarkers') {
            sigMarkers = !sigMarkers;
        } else if (item === 'vusMarkers') {
            vusMarkers = !vusMarkers;
        } else if (item === 'negMarkers') {
            negMarkers = !negMarkers;
        } else if (item === "addForthPane") {
            addForthPane = !addForthPane;
        } else if (item === "geneInfo") {
            geneInfo = !geneInfo;
        } else if (item === "annotationInfo") {
            annotationInfo = !annotationInfo;
        }
    }

    function sendAdditionalPatientFields(event) {
        dispatch('add');
    }

    function removeAdditionalPatientFields(event) {
        dispatch('remove');
    }

    $: changesDetectedAssay = $historical_match_params?.unsaved_updated_fields?.assays ?? false;
    $: changesDetectedDOB = $historical_match_params?.unsaved_updated_fields?.date_of_birth ?? false;
    $: changesDetectedGender = $historical_match_params?.unsaved_updated_fields?.gender ?? false;
    $: changesDetectedAlt = $historical_match_params?.unsaved_updated_fields?.selected_alterations ?? false;
    $: changesDetectedDis = $historical_match_params?.unsaved_updated_fields?.specific_diseases ?? false;
    $: changesDetectedTC = $historical_match_params?.unsaved_updated_fields?.therapeutic_context ?? false;

</script>

<style>

    .container {
        display: flex;
    }

    .nav-header {
        z-index: 999;
        display: flex;
        justify-content: space-between;
        height: 75px;
    }


    .header-nav-button:hover {
        cursor: pointer;
    }

    .header-nav-button {
        position: relative;
        cursor: pointer;
        flex: 1;
        padding: 10px;
        background-color: #f2f2f2;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        font-size: 16px;
        font-weight: bold;
    }

    .match-button {
        cursor: pointer;
        flex: 1;
        padding: 10px;
        background-color: #6cc250;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 0;
        font-size: 14px;
        font-weight: bold;
        height: 35px;
    }

    .unsaved-changes-dot {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 12px;
        height: 12px;
        background-color: red;
        border-radius: 50%;
        z-index: 999;
    }

</style>
<div class="nav-header">
    <h3 style="align-content: center; margin-left: 10px;">Details: </h3>
    <p class="header-nav-button" on:click={() => pane.set("patient")}
       style="color: {$pane === 'patient' ? '#233C6B' : 'black'};"> Patient Information
        {#if changesDetectedDOB || changesDetectedGender || changesDetectedDis}
            <span class="unsaved-changes-dot"></span>
        {/if}
    </p>
    <p class="header-nav-button" on:click={() => pane.set("clinical")}
       style="color: {$pane === 'clinical' ? '#233C6B' : 'black'};">Prior Treatment History
        {#if changesDetectedTC}
            <span class="unsaved-changes-dot"></span>
        {/if}
    </p>
    <p class="header-nav-button" on:click={() => pane.set("test")}
       style="color: {$pane === 'test' ? '#233C6B' : 'black'};">Test Information
        {#if changesDetectedAssay}
            <span class="unsaved-changes-dot"></span>
        {/if}
    </p>
    <p class="header-nav-button" on:click={() => pane.set("markers")}
       style="color: {$pane === 'markers' ? '#233C6B' : 'black'};"> Biomarker Details
        {#if changesDetectedAlt}
            <span class="unsaved-changes-dot"></span>
        {/if}
    </p>
    <p class="header-nav-button"
       on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('instTrialsOnly');}}
       style="color: {$pane === 'main' ? '#233C6B' : 'black'};"> Actionability</p>
</div>
{#if ($pane === "main")}
    <div class="nav-header">
        <div class="icon-button"
             on:click={() => {leftFilterPaneState.set($leftFilterPaneState === "closed" ? "open" : "closed")}}>
            {#if ($leftFilterPaneState === 'closed')}
                <p style="margin-bottom: 0">Apply Filters</p>
                <Icon data="{faFilter}" scale="1.5" style="color: #233C6B; margin-top: 0"/>
            {:else}
                <p style="margin-bottom: 0">Close Filter Pane</p>
                <Icon data="{faCircleXmark}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
            {/if}
        </div>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('instTrialsOnly');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'instTrialsOnly' ? '#ffecb3' : '#f2f2f2'};">
            Clinical Trials<br>In House</p>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('outsideTrialsOnly');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'outsideTrialsOnly' ? '#ffecb3' : '#f2f2f2'};">
            Clinical Trials<br>Outside</p>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('allTherapies');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'allTherapies' ? '#ffecb3' : '#f2f2f2'};">
            FDA/NCCN<br>Therapies</p>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('evidenceTherapies');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'evidenceTherapies' ? '#ffecb3' : '#f2f2f2'};">
            Evidence Based<br>Therapies</p>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('diagnostic');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'diagnostic' ? '#ffecb3' : '#f2f2f2'};">
            Diagnostic</p>
        <p class="header-nav-button"
           on:click={() => {pane.set('main'); openMatchResults.set(true); matchTableSetting.set('prognostic');}}
           style="font-size: 14px; height: 35px; margin-top:0; background-color: {$matchTableSetting === 'prognostic' ? '#ffecb3' : '#f2f2f2'};">
            Prognostic</p>
        <p class="match-button" on:click={()=>{recalculate_match.set(true);}}>Match</p>
        <div style="margin-left: 0; margin-bottom: 10; justify-content: center">
            Last Match Performed: ({$historical_match_params.last_match_performed || "N/A"})<br>
            {#if ($historical_match_params.recalculate_match || false)}
                <span style="color: red; cursor:pointer;"> <Icon data="{faTriangleExclamation}"
                                                                 style="margin-left: 15px; color:#e79a45; cursor:pointer;"/> Changes Detected, re-run Match</span>
                {#if (Object.values($historical_match_params.unsaved_updated_fields).some(value => value === true))}
                    <br/>
                    <span style="text-decoration: underline; color:rgb(0, 80, 200); cursor:pointer; justify-content: left" on:click={() => {viewUnsavedChanges.set('open')}}> View Unsaved Changes</span>
                {/if}
            {/if}
        </div>

    </div>
{/if}
<hr style="margin: 0; border: none; border-top: 4px solid #AAAAAA;">

<div class="container" style="height: {$pane === 'main' ? 'calc(60vh - 10px)' : 'calc(70vh - 30px)'};">
    {#if ($pane === 'main' || $pane === 'backToSummary')}
        <svelte:component this={PatientMatchDashboard}/>
    {:else if ($pane === 'patient')}
        <svelte:component this={PatientInformation}/>
    {:else if ($pane === 'clinical')}
        <svelte:component this="{TreatmentInformation}"/>
    {:else if ($pane === 'test')}
        <svelte:component this="{TestInformation}"/>
    {:else if ($pane === 'markers')}
        <svelte:component this="{BiomarkerDetails}"/>
    {/if}
</div>