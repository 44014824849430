<script>
    import {
        instTrialfilters,
        allTrialfilters,
        instBioTrials,
        instNonBioTrials,
        markerMatchesMap,
        outsideTrials, pane
    } from "../../store/patientMatch";
    import {onMount} from "svelte";
    import {getDrugBridge} from "../../network/patient_match";

    let NctIds = '';
    let allNctIds = [];
    let selectedNctIds = [];
    let filteredNctIds = allNctIds;
    let nctSuggestionsDisplay = 'none';

    let ProtocolIds = '';
    let allProtocolIds = [];
    let selectedProtocolIds = [];
    let filteredProtocolIds = allProtocolIds;
    let protocolSuggestionsDisplay = 'none';

    let Markers = '';
    let allMarkers = [];
    let selectedMarkers = [];
    let filteredMarkers = allMarkers;
    let markerSuggestionsDisplay = 'none';

    let Diseases = '';
    let allDiseases = [];
    let selectedDiseases = [];
    let filteredDiseases = allDiseases;
    let diseaseSuggestionsDisplay = 'none';

    let Phase = '';
    let allPhases = [];
    let selectedPhases = [];
    let filteredPhases = allPhases;
    let phaseSuggestionsDisplay = 'none';


    let Drugs = '';
    let allDrugs = [];
    let selectedDrugs = [];
    let filteredDrugs = allDrugs;
    let drugSuggestionsDisplay = 'none';

    let explodedDrugDict = {};
    let explodedDrugDropdown = [];
    let selectedExplodedDrug = "";
    let filteredDCDrugs = [];
    let drugCategorySuggestionsDisplay = 'none';
    let DrugCategory = '';


    let selectedPriorTreatment = 'all';
    let priorTreatmentNames = {'none': 'No Prior Treatment Criteria', 'full': 'Full Match'};


    $: {
        // NCT
        const instBioNctIds = $instBioTrials.results.map(trial => trial.nct_id);
        const instNonBioNctIds = $instNonBioTrials.results.map(trial => trial.nct_id);
        const outsideTrialsNctIds = $outsideTrials.results.map(trial => trial.nct_id);

        const combinedNctIds = [...instBioNctIds, ...instNonBioNctIds, ...outsideTrialsNctIds];
        allNctIds = Array.from(new Set(combinedNctIds)); // Deduplicate using Set

        // Protocol ID
        const instBioProtocolIds = $instBioTrials.results.map(trial => trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids[0] : '');
        const instNonBioProtocolIds = $instNonBioTrials.results.map(trial => trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids[0] : '');

        const combinedProtocolIds = [...instBioProtocolIds, ...instNonBioProtocolIds];
        allProtocolIds = Array.from(new Set(combinedProtocolIds)).filter(id => id !== '');

        // Matched Biomarkers
        const instBioMarkers = $instBioTrials.results.flatMap(trial => {
            if (trial.detected_alterations && trial.detected_alterations.length > 0) {
                return trial.detected_alterations.flatMap(detectedAlteration => detectedAlteration.trigger_alterations);
            } else {
                return [];
            }
        });
        const instNonBioMarkers = $instNonBioTrials.results.flatMap(trial => {
            if (trial.detected_alterations && trial.detected_alterations.length > 0) {
                return trial.detected_alterations.flatMap(detectedAlteration => detectedAlteration.trigger_alterations);
            } else {
                return [];
            }
        });
        const outsideTrialsMarkers = $outsideTrials.results.flatMap(trial => {
            if (trial.detected_alterations && trial.detected_alterations.length > 0) {
                return trial.detected_alterations.flatMap(detectedAlteration => detectedAlteration.trigger_alterations);
            } else {
                return [];
            }
        });
        const combinedMarkers = [...instBioMarkers, ...instNonBioMarkers, ...outsideTrialsMarkers];
        allMarkers = Array.from(new Set(combinedMarkers)); // Deduplicate using Set


        // Matched Diseases
        const instBioDiseases = $instBioTrials.results.flatMap(trial => {
            if (trial.matched_diseases && trial.matched_diseases.length > 0) {
                return trial.matched_diseases.map(matchDiseases => matchDiseases.matched_disease);
            } else {
                return [];
            }
        });
        const instNonBioDiseases = $instNonBioTrials.results.flatMap(trial => {
            if (trial.matched_diseases && trial.matched_diseases.length > 0) {
                return trial.matched_diseases.map(matchDiseases => matchDiseases.matched_disease);
            } else {
                return [];
            }
        });
        const outsideTrialsDiseases = $outsideTrials.results.flatMap(trial => {
            if (trial.matched_diseases && trial.matched_diseases.length > 0) {
                return trial.matched_diseases.map(matchDiseases => matchDiseases.matched_disease);
            } else {
                return [];
            }
        });
        const combinedDiseases = [...instBioDiseases, ...instNonBioDiseases, ...outsideTrialsDiseases];
        allDiseases = Array.from(new Set(combinedDiseases)); // Deduplicate using Set


        // Matched Phases
        const instBioPhase = $instBioTrials.results.map(trial => trial.phase);
        const instNonBioPhase = $instNonBioTrials.results.map(trial => trial.phase);
        const outsideTrialsPhase = $outsideTrials.results.map(trial => trial.phase);

        const combinedPhases = [...instBioPhase, ...instNonBioPhase, ...outsideTrialsPhase].filter(phase => phase !== undefined);
        allPhases = Array.from(new Set(combinedPhases)); // Deduplicate using Set


        // Matched Drugs
        const instBioDrugs = $instBioTrials.results.flatMap(trial => {
            if (trial.match_results && trial.match_results.length > 0) {
                return trial.match_results.flatMap(mr => {
                    if (mr.treatment_contexts && mr.treatment_contexts.length > 0) {
                        return mr.treatment_contexts.flatMap(tc => {
                            return tc.drugs.map(drug => drug.name);
                        });
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        });
        const instNonBioDrugs = $instNonBioTrials.results.flatMap(trial => {
            if (trial.match_results && trial.match_results.length > 0) {
                return trial.match_results.flatMap(mr => {
                    if (mr.treatment_contexts && mr.treatment_contexts.length > 0) {
                        return mr.treatment_contexts.flatMap(tc => {
                            return tc.drugs.map(drug => drug.name);
                        });
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        });
        const outsideTrialsDrugs = $outsideTrials.results.flatMap(trial => {
            if (trial.match_results && trial.match_results.length > 0) {
                return trial.match_results.flatMap(mr => {
                    if (mr.treatment_contexts && mr.treatment_contexts.length > 0) {
                        return mr.treatment_contexts.flatMap(tc => {
                            return tc.drugs.map(drug => drug.name);
                        });
                    } else {
                        return [];
                    }
                });
            } else {
                return [];
            }
        });
        const combinedDrugs = [...instBioDrugs, ...instNonBioDrugs, ...outsideTrialsDrugs];
        allDrugs = Array.from(new Set(combinedDrugs)); // Deduplicate using Set

        setDrugsCategoryFilterList(allDrugs);
    }

    // NCT
    function handleNCTInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNctIds = allNctIds.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNCTSelect(option) {
        selectedNctIds = [option];
        NctIds = '';
        filteredNctIds = allNctIds;
    }

    function removeNCT(index) {
        selectedNctIds = selectedNctIds.slice(0, index).concat(selectedNctIds.slice(index + 1));
    }

    function handleNCTKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNCTSelect(event.target.value);
        }
    }

    function toggleNctSuggestions() {
        nctSuggestionsDisplay = nctSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (nctSuggestionsDisplay === 'block') {
            protocolSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
            drugSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }


    // PROTOCOL ID
    function handleProtocolInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredProtocolIds = allProtocolIds.filter(option => option.toLowerCase().includes(inputText));
    }

    function handleProtocolSelect(option) {
        selectedProtocolIds = [option];
        ProtocolIds = '';
        filteredProtocolIds = allProtocolIds;
    }

    function removeProtocol(index) {
        selectedProtocolIds = selectedProtocolIds.slice(0, index).concat(selectedProtocolIds.slice(index + 1));
    }

    function handleProtocolKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleProtocolSelect(event.target.value);
        }
    }

    function toggleProtocolSuggestions() {
        protocolSuggestionsDisplay = protocolSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (protocolSuggestionsDisplay === 'block') {
            nctSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
            drugSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }

    // Matched Biomarker
    function handleMarkerInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredMarkers = allMarkers.filter(option => option.toLowerCase().includes(inputText));
    }

    function handleMarkerSelect(option) {
        selectedMarkers = [option];
        Markers = '';
        filteredMarkers = allMarkers;
    }

    function removeMarker(index) {
        selectedMarkers = selectedMarkers.slice(0, index).concat(selectedMarkers.slice(index + 1));
    }

    function handleMarkerKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleMarkerSelect(event.target.value);
        }
    }

    function toggleMarkerSuggestions() {
        markerSuggestionsDisplay = markerSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (markerSuggestionsDisplay === 'block') {
            nctSuggestionsDisplay = 'none';
            protocolSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
            drugSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }


    // Matched Disease
    function handleDiseaseInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDiseases = allDiseases.filter(option => option.toLowerCase().includes(inputText));
    }

    function handleDiseaseSelect(option) {
        selectedDiseases = [option];
        Diseases = '';
        filteredDiseases = allDiseases;
    }

    function removeDisease(index) {
        selectedDiseases = selectedDiseases.slice(0, index).concat(selectedDiseases.slice(index + 1));
    }

    function handleDiseaseKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleDiseaseSelect(event.target.value);
        }
    }

    function toggleDiseaseSuggestions() {
        diseaseSuggestionsDisplay = diseaseSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (diseaseSuggestionsDisplay === 'block') {
            nctSuggestionsDisplay = 'none';
            protocolSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
            drugSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }


    // Phase
    function handlePhaseInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredPhases = allPhases.filter(option => option.toLowerCase().includes(inputText));
    }

    function handlePhaseSelect(option) {
        selectedPhases = [option];
        Phase = '';
        filteredPhases = allPhases;
    }

    function removePhase(index) {
        selectedPhases = selectedPhases.slice(0, index).concat(selectedPhases.slice(index + 1));
    }

    function handlePhaseKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handlePhaseSelect(event.target.value);
        }
    }

    function togglePhaseSuggestions() {
        phaseSuggestionsDisplay = phaseSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (phaseSuggestionsDisplay === 'block') {
            nctSuggestionsDisplay = 'none';
            protocolSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            drugSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }

    // Drugs
    function handleDrugInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDrugs = allDrugs.filter(option => option.toLowerCase().includes(inputText));
    }

    function handleDrugSelect(option) {
        selectedDrugs = [option];
        Drugs = '';
        filteredDrugs = allDrugs;
    }

    function removeDrug(index) {
        selectedDrugs = selectedDrugs.slice(0, index).concat(selectedDrugs.slice(index + 1));
    }

    function handleDrugKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleDrugSelect(event.target.value);
        }
    }

    function toggleDrugSuggestions() {
        drugSuggestionsDisplay = drugSuggestionsDisplay === 'none' ? 'block' : 'none';
        if (drugSuggestionsDisplay === 'block') {
            nctSuggestionsDisplay = 'none';
            protocolSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
            drugCategorySuggestionsDisplay = 'none';
        }
    }

    // Drug Category
    async function setDrugsCategoryFilterList(drugs) {
        drugs.sort();
        let allDCDrugs = [];
        let depdupeDrugs = Array.from(new Set(drugs));
        if (depdupeDrugs.length > 4000) {
            allDCDrugs = choppedList(depdupeDrugs, 4000);
        } else {
            allDCDrugs.push(depdupeDrugs);
        }

        if (allDCDrugs.length > 0) {
            for (let drugList of allDCDrugs) {
                const result = await getDrugBridge(drugList);

                result.results.forEach(db => db.exploded_drugs.forEach(explodedDrug => {
                    if (!explodedDrugDict[toTitle(explodedDrug)]) {
                        explodedDrugDict[toTitle(explodedDrug)] = [];
                    }
                    explodedDrugDict[toTitle(explodedDrug)].push(db.drug);
                }));
            }
        }
        explodedDrugDropdown = Object.keys(explodedDrugDict);
    }

    function choppedList(array, size) {
        let result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    function toTitle(str) {
        const isLowerCase = str === str.toLowerCase();

        if (isLowerCase) {
            return str
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
        return str;
    }

    function handleDrugCategoryInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDCDrugs = explodedDrugDropdown.filter(option => option.toLowerCase().includes(inputText));
    }

    function handleDrugCategorySelect(option) {
        selectedExplodedDrug = [option];
        DrugCategory = '';
        filteredDCDrugs = explodedDrugDropdown;
    }

    function removeDrugCategory(index) {
        selectedExplodedDrug = selectedExplodedDrug.slice(0, index).concat(selectedExplodedDrug.slice(index + 1));
    }

    function handleDrugCategoryKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleDrugCategorySelect(event.target.value);
        }
    }

    function toggleDrugCategorySuggestions() {
        drugCategorySuggestionsDisplay = drugCategorySuggestionsDisplay === 'none' ? 'block' : 'none';
        if (drugCategorySuggestionsDisplay === 'block') {
            drugSuggestionsDisplay = 'none';
            nctSuggestionsDisplay = 'none';
            protocolSuggestionsDisplay = 'none';
            markerSuggestionsDisplay = 'none';
            diseaseSuggestionsDisplay = 'none';
            phaseSuggestionsDisplay = 'none';
        }
    }


    function resetButtonClick() {
        selectedNctIds = [];
        selectedProtocolIds = [];
        selectedMarkers = [];
        selectedDiseases = [];
        selectedPhases = [];
        selectedDrugs = [];
        selectedExplodedDrug = [];
        selectedPriorTreatment = 'all';
    }


</script>
<style>
    .selected-values-container {
        display: flex;
        flex-wrap: wrap; /* Allow values to wrap to the next line */
        max-width: 100vw; /* Ensure it doesn't exceed its parent's width */
    }

    /* Style each selected diagnosis */
    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-left: 25px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    /* Style the "x" button for removing selections */
    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    /* Add hover effect for suggestions */
    .suggestions {
        z-index: 1;
        background-color: #f9f9f9;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
        width: 80%;
        margin-left: 25px;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    /* Limit the width of the type-ahead container */
    .type-ahead {
        width: 100%; /* Set a maximum width for the type-ahead container */
    }

    .input-title {
        margin-bottom: 0;
    }

    .input-field {
        margin-top: 0;
        width: 80%;
        cursor: pointer;
    }

    .apply-button {
        margin-right: 10px;
        background-color: #9d9d9f;
        color: #fff;
        width: 85px;
        height: 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .reset-button {
        background-color: #d98b95;
        color: #fff;
        margin-right: 10px;
        width: 85px;
        height: 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .vertical-line {
        border-left: 1px solid black;
        height: 40px;
        margin: 0 10px;
    }

    .input-container {
        overflow-x: scroll;
    }
</style>
<div class="input-container" style="height: {$pane === 'main' ? 'calc(67vh - 77px)' : '67vh'}">
    <h3>Apply Filters</h3>
    <p class="input-title">NCTID</p>
    <div class="type-ahead" on:click={()=>toggleNctSuggestions()}>
        <div class="selected-values-container">
            {#each selectedNctIds as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeNCT(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={NctIds}
                on:click={handleNCTInput}
                on:input={handleNCTInput}
                on:keydown={handleNCTKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{nctSuggestionsDisplay}">
            {#each filteredNctIds as option (option)}
                <div class="suggestion-item" on:click={() => handleNCTSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Protocol ID</p>
    <div class="type-ahead" on:click={()=>toggleProtocolSuggestions()}>
        <div class="selected-values-container">
            {#each selectedProtocolIds as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeProtocol(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={ProtocolIds}
                on:click={handleProtocolInput}
                on:input={handleProtocolInput}
                on:keydown={handleProtocolKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{protocolSuggestionsDisplay}">
            {#each filteredProtocolIds as option (option)}
                <div class="suggestion-item" on:click={() => handleProtocolSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Matched Biomarkers</p>
    <div class="type-ahead" on:click={()=>toggleMarkerSuggestions()}>
        <div class="selected-values-container">
            {#each selectedMarkers as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeMarker(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={Markers}
                on:click={handleMarkerInput}
                on:input={handleMarkerInput}
                on:keydown={handleMarkerKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{markerSuggestionsDisplay}">
            {#each filteredMarkers as option (option)}
                <div class="suggestion-item" on:click={() => handleMarkerSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Matched Diseases</p>
    <div class="type-ahead" on:click={()=>toggleDiseaseSuggestions()}>
        <div class="selected-values-container">
            {#each selectedDiseases as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeDisease(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={Diseases}
                on:click={handleDiseaseInput}
                on:input={handleDiseaseInput}
                on:keydown={handleDiseaseKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{diseaseSuggestionsDisplay}">
            {#each filteredDiseases as option (option)}
                <div class="suggestion-item" on:click={() => handleDiseaseSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Phase</p>
    <div class="type-ahead" on:click={()=>togglePhaseSuggestions()}>
        <div class="selected-values-container">
            {#each selectedPhases as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removePhase(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={Phase}
                on:click={handlePhaseInput}
                on:input={handlePhaseInput}
                on:keydown={handlePhaseKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{phaseSuggestionsDisplay}">
            {#each filteredPhases as option (option)}
                <div class="suggestion-item" on:click={() => handlePhaseSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Drugs</p>
    <div class="type-ahead" on:click={()=>toggleDrugSuggestions()}>
        <div class="selected-values-container">
            {#each selectedDrugs as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeDrug(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={Drugs}
                on:click={handleDrugInput}
                on:input={handleDrugInput}
                on:keydown={handleDrugKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{drugSuggestionsDisplay}">
            {#each filteredDrugs as option (option)}
                <div class="suggestion-item" on:click={() => handleDrugSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Drug Category</p>
    <div class="type-ahead" on:click={()=>toggleDrugCategorySuggestions()}>
        <div class="selected-values-container">
            {#each selectedExplodedDrug as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeDrugCategory(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={DrugCategory}
                on:click={handleDrugCategoryInput}
                on:input={handleDrugCategoryInput}
                on:keydown={handleDrugCategoryKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions" style="display:{drugCategorySuggestionsDisplay}">
            {#each filteredDCDrugs as option (option)}
                <div class="suggestion-item" on:click={() => handleDrugCategorySelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <p class="input-title">Prior Treatments</p>
    <select id="piDropdown" style="width: 80%" bind:value={selectedPriorTreatment}>
        <option value="all">All</option>
        <option value="none">No Prior Treatment Criteria</option>
        <option value="full">Full Match</option>
    </select>

    <div style="margin-top: 2.5vh; margin-bottom: 2.5vh; margin-left: 10px; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <button class='apply-button'
                on:click={() => {
                        const submittedNCTIDs = selectedNctIds;
                        const submittedProtocolIds = selectedProtocolIds;
                        const submittedMarkers = selectedMarkers;
                        const submittedDiseases = selectedDiseases;
                        const submittedPhases = selectedPhases;
                        const submittedDrugs = selectedDrugs;
                        const submittedPriorTreatment = selectedPriorTreatment;
                        const submittedDC = [{ [selectedExplodedDrug]: explodedDrugDict[selectedExplodedDrug] }];

                        resetButtonClick();
                        if (submittedPriorTreatment === 'all'){
                            allTrialfilters.update(currentFilters => {
                            return {
                                "NCTID": [...currentFilters.NCTID, ...submittedNCTIDs],
                                "ProtocolID": [...currentFilters.ProtocolID, ...submittedProtocolIds],
                                "Biomarkers": [...currentFilters.Biomarkers, ...submittedMarkers],
                                "Diseases": [...currentFilters.Diseases, ...submittedDiseases],
                                "Phase": [...currentFilters.Phase, ...submittedPhases],
                                "Drugs": [...currentFilters.Drugs, ...submittedDrugs],
                                "DrugCategory": [],
                                "PriorTreatment": []
                            };
                        });
                        }
                        else{allTrialfilters.update(currentFilters => {
                            return {
                                "NCTID": [...currentFilters.NCTID, ...submittedNCTIDs],
                                "ProtocolID": [...currentFilters.ProtocolID, ...submittedProtocolIds],
                                "Biomarkers": [...currentFilters.Biomarkers, ...submittedMarkers],
                                "Diseases": [...currentFilters.Diseases, ...submittedDiseases],
                                "Phase": [...currentFilters.Phase, ...submittedPhases],
                                "Drugs": [...currentFilters.Drugs, ...submittedDrugs],
                                "DrugCategory": [...currentFilters.DrugCategory, ...submittedDC],
                                "PriorTreatment": [priorTreatmentNames[submittedPriorTreatment]]
                            };
                        });}
                    }}>
            Apply
        </button>
        <button class='reset-button' on:click={resetButtonClick}>Reset</button>
    </div>
</div>

<!--Savinvg for when filtering on inst trials seperately-->
<!--<div style="margin-top: 5vh; margin-left: 10px;">-->
<!--    <p style="text-align: left; padding-bottom: 0">Apply Filters to:</p>-->
<!--    <div style="display:flex; padding-top: 0">-->
<!--        <button class='apply-button'-->
<!--                on:click={() => {-->
<!--                        const submittedNCTIDs = selectedNctIds;-->
<!--                        const submittedProtocolIds = selectedProtocolIds;-->
<!--                        const submittedMarkers = selectedMarkers;-->
<!--                        const submittedDiseases = selectedDiseases;-->
<!--                        const submittedPhases = selectedPhases;-->
<!--                        const submittedDrugs = selectedDrugs;-->
<!--                        resetButtonClick();-->
<!--                        instTrialfilters.update(currentFilters => {-->
<!--                            return {-->
<!--                                "NCTID": [...currentFilters.NCTID, ...submittedNCTIDs],-->
<!--                                "ProtocolID": [...currentFilters.ProtocolID, ...submittedProtocolIds],-->
<!--                                "Biomarkers": [...currentFilters.Biomarkers, ...submittedMarkers],-->
<!--                                "Diseases": [...currentFilters.Diseases, ...submittedDiseases],-->
<!--                                "Phase": [...currentFilters.Phase, ...submittedPhases],-->
<!--                                "Drugs": [...currentFilters.Drugs, ...submittedDrugs]-->
<!--                            };-->
<!--                        });-->
<!--                    }}>-->
<!--            Inst. Trials-->
<!--        </button>-->
<!--        <button class='apply-button'-->
<!--                on:click={() => {-->

<!--                        const submittedNCTIDs = selectedNctIds;-->
<!--                        const submittedProtocolIds = selectedProtocolIds;-->
<!--                        const submittedMarkers = selectedMarkers;-->
<!--                        const submittedDiseases = selectedDiseases;-->
<!--                        const submittedPhases = selectedPhases;-->
<!--                        const submittedDrugs = selectedDrugs;-->
<!--                        resetButtonClick();-->
<!--                        allTrialfilters.update(currentFilters => {-->
<!--                            return {-->
<!--                                "NCTID": [...currentFilters.NCTID, ...submittedNCTIDs],-->
<!--                                "ProtocolID": [...currentFilters.ProtocolID, ...submittedProtocolIds],-->
<!--                                "Biomarkers": [...currentFilters.Biomarkers, ...submittedMarkers],-->
<!--                                "Diseases": [...currentFilters.Diseases, ...submittedDiseases],-->
<!--                                "Phase": [...currentFilters.Phase, ...submittedPhases],-->
<!--                                "Drugs": [...currentFilters.Drugs, ...submittedDrugs]-->
<!--                            };-->
<!--                        });-->
<!--                    }}>-->
<!--            All Trials-->
<!--        </button>-->
<!--        <div class="vertical-line"></div>-->
<!--        <button class='reset-button' on:click={resetButtonClick}>Reset</button>-->
<!--    </div>-->
<!--</div>-->