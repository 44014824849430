<script>
    import {Icon} from "svelte-awesome";
    import {
        faPlus,
        faMinus,
        faFilter,
        faBars,
        faChevronUp,
        faChevronDown,
        faCircleInfo,
        faTriangleExclamation
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {HSplitPane} from "svelte-split-pane";
    import {
        matchTableSetting,
        outsideTrials,
        instBioTrials,
        instNonBioTrials,
        senOTTTherapies,
        senFTTTherapies,
        resTherapies,
        allTrialfilters,
        instTrialfilters,
        therapyFilters,
        selectedCase,
        leftFilterPaneState,
        senEvidenceCRCSTherapies,
        senEvidenceCSTherapies,
        senEvidenceCTTherapies,
        senEvidencePCTherapies,
        senEvidenceMCGTherapies,
        resEvidenceCRCSTherapies,
        resEvidenceCSTherapies,
        resEvidenceCTTherapies,
        resEvidencePCTherapies,
        resEvidenceMCGTherapies, viewUnsavedChanges, historical_match_params, pane
    } from "../../store/patientMatch";
    import {addToHistory, selectedOption} from "../../store/navigation";
    import {getDefaultInstitution} from "../../store/session";
    import {onMount} from "svelte";
    import PatientMatchFilterNav from "./PatientMatchFilterNav.svelte";
    import {getBaseNavigationUrl} from "../../utils/urls";
    import {bulkDrugDetail, trialDetails} from "../../network/patient_match";
    import Loader from "../shared/Loader.svelte";

    const additionalDetails = ["OnCore Details", "CT.gov Details", "Treatment Details", "Locations", "Explore Matched Eligibility Details", "View Other Curated Eligibility", "Trial Document"];

    const rightPaneLabels = {
        "OnCore Details": ["Status Update Date", "Additional Protocol Numbers", "Principal Investigator", "Organizational Unit", "Sponsor Type", "Department"],
        "CT.gov Details": ["Title", "NCT ID", "Phase", "Recruiting Status", "Lead Sponsor", "Contact Info", "Age Range (yrs)", "Gender", "Summary"],
        "Treatment Details": ["DrugDetail"],
        "Locations": ["Location"],
        "Explore Matched Eligibility Details": ["Matched Eligibility"],
        "View Other Curated Eligibility": ["Curated Eligibility"],
        "Trial Document": ["document"],
        "Untested Biomarkers": ["markerDetails"]
    };

    let rightPaneTitle = '';
    let additionalDetailsLoading = false;
    let additionalDetailTrialSelected = '';
    let rightAdditionalPaneState = 'closed';
    let casesMatchedDiseases = [];

    let default_inst = getDefaultInstitution();


    let allFilters = [];
    let instFilters = [];
    let filteredInstBioTrials = [];
    let filteredInstNonBioTrials = [];
    let filteredOutsideTrials = [];
    let filteredSenOTTTherapies = [];
    let filteredSenFTTTherapies = [];
    let filteredResTherapies = [];
    let trialsWithDetailGathered = [];
    let trialsAdditionalDetail = [];

    let expandPIKey = [];
    let expandEligibilityKey = [];
    let expandDiseaseCriteriaKey = [];
    let expandAlterationCriteriaKey = [];
    let expandPICriteriaKey = [];
    let expandDiseaseOperatorKey = [];
    let expandAlterationOperatorKey = [];
    let expandPIOperatorKey = [];
    let expandDiseaseConceptKey = [];
    let expandAlterationConceptKey = [];
    let expandPIConceptKey = [];
    let visibleAlterationIndex = 0;
    let visibleDiseaseIndex = 0;
    let visiblePIIndex = 0;

    let selectedUntestedBiomarkerDetails = [];

    let senEvidenceCRCSTherapiesTableState = 'open';
    let senEvidenceCSTherapiesTableState = 'open';
    let senEvidenceCTTherapiesTableState = 'open';
    let senEvidencePCTherapiesTableState = 'open';
    let senEvidenceMCGTherapiesTableState = 'open';

    let resEvidenceCRCSTherapiesTableState = 'open';
    let resEvidenceCSTherapiesTableState = 'open';
    let resEvidenceCTTherapiesTableState = 'open';
    let resEvidencePCTherapiesTableState = 'open';
    let resEvidenceMCGTherapiesTableState = 'open';

    function selectOption(option) {
        addToHistory(option);
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }

    $: {
        // Reset filter lists
        allFilters = [];
        instFilters = [];

        // Update allFilters list
        Object.keys($allTrialfilters).forEach(key => {
            $allTrialfilters[key].forEach(value => {
                allFilters.push({label: key, value: value, source: 'all'});
            });
        });

        // Update instFilters list
        Object.keys($instTrialfilters).forEach(key => {
            $instTrialfilters[key].forEach(value => {
                // Check if value exists in allFilters
                const existsInAllFilters = allFilters.find(filter => filter.label === key && filter.value === value);
                if (existsInAllFilters) {
                    existsInAllFilters.source = 'all';
                } else {
                    instFilters.push({label: key, value: value, source: 'inst'});
                }
            });
        });

        // Reset filtered trial lists
        filteredOutsideTrials = [];
        filteredInstNonBioTrials = [];
        filteredInstBioTrials = [];

        // Check if any filters are applied
        const filtersApplied = allFilters.length > 0 || instFilters.length > 0;

        // If filters are applied, filter the trials; otherwise, assign original trial results
        if (filtersApplied) {
            // Filter outside trials
            filteredOutsideTrials = filterTrials($outsideTrials.results, allFilters, []);

            // Filter institutional non-bio trials
            filteredInstNonBioTrials = filterTrials($instNonBioTrials.results, allFilters, instFilters);

            // Filter institutional bio trials
            filteredInstBioTrials = filterTrials($instBioTrials.results, allFilters, instFilters);
        } else {
            // Assign original trial results to filtered lists
            filteredOutsideTrials = $outsideTrials.results;
            filteredInstNonBioTrials = $instNonBioTrials.results;
            filteredInstBioTrials = $instBioTrials.results;
        }

        // Update matched Diseases list
        filteredOutsideTrials.forEach(trial => {
            if (trial.matched_diseases.length > 0) {
                trial.matched_diseases.forEach(disease => {
                    if (!casesMatchedDiseases.includes(disease.matched_disease)) {
                        casesMatchedDiseases = [...casesMatchedDiseases, disease.matched_disease];
                    }
                });
            }
        });
        filteredInstBioTrials.forEach(trial => {
            if (trial.matched_diseases.length > 0) {
                trial.matched_diseases.forEach(disease => {
                    if (!casesMatchedDiseases.includes(disease.matched_disease)) {
                        casesMatchedDiseases = [...casesMatchedDiseases, disease.matched_disease];
                    }
                });
            }
        });
        filteredInstNonBioTrials.forEach(trial => {
            if (trial.matched_diseases.length > 0) {
                trial.matched_diseases.forEach(disease => {
                    if (!casesMatchedDiseases.includes(disease.matched_disease)) {
                        casesMatchedDiseases = [...casesMatchedDiseases, disease.matched_disease];
                    }
                });
            }
        });
        // Apply Filtering for Therapies
        if ($matchTableSetting === "allTherapies") {
            filteredSenOTTTherapies = $senOTTTherapies;
            filteredSenFTTTherapies = $senFTTTherapies;
            filteredResTherapies = $resTherapies;
        } else if ($matchTableSetting === 'fdaTherapies') {
            filteredSenOTTTherapies = $senOTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            });
            filteredSenFTTTherapies = $senFTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            });
            filteredResTherapies = $resTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            });
        } else if ($matchTableSetting === 'nccnTherapies') {
            filteredSenOTTTherapies = $senOTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            });
            filteredSenFTTTherapies = $senFTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            });
            filteredResTherapies = $resTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            });
        } else if ($matchTableSetting === 'resTherapies') {
            filteredSenOTTTherapies = [];
            filteredSenFTTTherapies = [];
            filteredResTherapies = $resTherapies.filter(therapy => {
                return therapy.trigger_alteration_display.includes($therapyFilters.Biomarkers[0]);
            });
        } else if ($matchTableSetting === 'senOTTTherapies') {
            filteredSenOTTTherapies = $senOTTTherapies.filter(therapy => {
                return therapy.trigger_alteration_display.includes($therapyFilters.Biomarkers[0]);
            });
            filteredResTherapies = [];
        } else if ($matchTableSetting === 'senFTTTherapies') {
            filteredSenFTTTherapies = $senFTTTherapies.filter(therapy => {
                return therapy.trigger_alteration_display.includes($therapyFilters.Biomarkers[0]);
            });
            filteredResTherapies = [];
        } else if ($matchTableSetting === 'evidenceTherapies') {
        } else {
            filteredSenOTTTherapies = [];
            filteredSenFTTTherapies = [];
            filteredResTherapies = [];
        }
    }

    // Function to filter trials based on filters
    function filterTrials(trials, allFilters, instFilters) {
        return trials.filter(trial => {
            // Check if the trial complies with all filters in allFilters
            for (const filter of allFilters) {
                if (!trialMatchesFilter(trial, filter)) {
                    return false; // Trial doesn't comply with this filter
                }
            }

            // Check if the trial complies with all filters in instFilters
            for (const filter of instFilters) {
                if (!trialMatchesFilter(trial, filter)) {
                    return false; // Trial doesn't comply with this filter
                }
            }

            return true; // Trial complies with all filters
        });
    }

    function trialMatchesFilter(trial, filter) {
        switch (filter.label) {
        case 'NCTID':
            return trial.nct_id === filter.value;
        case 'ProtocolID':
            return trial.inst_protocol_ids && trial.inst_protocol_ids.includes(filter.value);
        case 'Biomarkers':
            return trial.detected_alterations && trial.detected_alterations.some(da => da.trigger_alterations.includes(filter.value));
        case 'Diseases':
            return trial.matched_diseases && trial.matched_diseases.some(disease => disease.matched_disease === filter.value);
        case 'Phase':
            return trial.phase === filter.value;
        case 'Drugs':
            return trial.match_results && trial.match_results.some(therapy => {
                return therapy.treatment_contexts && therapy.treatment_contexts.some(treatment => {
                    const drugsInTherapyDisplay = treatment.therapy_display.split(' + ');
                    return drugsInTherapyDisplay.includes(filter.value);
                });
            });
        case 'DrugCategory':
            const drugs = Object.values(filter.value);
            return drugs[0].some(drug => {
                return trial.match_results && trial.match_results.some(therapy => {
                    return therapy.treatment_contexts && therapy.treatment_contexts.some(treatment => {
                        const drugsInTherapyDisplay = treatment.therapy_display.split(' + ');
                        return drugsInTherapyDisplay.includes(drug);
                    });
                });
            });
        case 'PriorTreatment':
            if (filter.value === "Full Match") {
                return trial.match_results && trial.match_results.some(therapy => {
                    return therapy.treatment_contexts && therapy.treatment_contexts.some(tc => {
                        return tc.pi_matches && tc.pi_matches.length > 0;
                    });
                });
            } else {
                return trial.match_results && trial.match_results.every(therapy => {
                    // Check if treatment_contexts is either missing or empty
                    return !therapy.treatment_contexts || therapy.treatment_contexts.length === 0 ||
                        therapy.treatment_contexts.every(tc => {
                            return !tc.pi_matches || tc.pi_matches.length === 0;
                        });
                });
            }

        default:
            return false;
        }
    }

    let openDropdownId = null;

    function toggleDropdown(trial, table) {
        const dropdownId = `${trial.nct_id}_${table}`;
        openDropdownId = openDropdownId === dropdownId ? null : dropdownId;
    }

    function handleChange(trial, option) {
        expandPIKey=[];
        expandPICriteriaKey=[];
        expandPIOperatorKey=[];
        expandPIConceptKey=[];
        visiblePIIndex=0;
        openDropdownId = '';
        // If trial details have not been gathered, gather them
        rightAdditionalPaneState = 'open';
        viewUnsavedChanges.set('closed');
        if (!trialsWithDetailGathered.includes(trial.nct_id)) {

            let nctID = trial.nct_id;

            // set Loading variable for right pane display
            additionalDetailsLoading = true;

            let trialDetailPromise = trialDetails(nctID);

            let drugs = [];

            if (trial && trial.match_results && trial.match_results.length > 0) {
                trial.match_results.forEach(therapy => {
                    if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0) {
                        therapy.treatment_contexts.forEach(treatment => {
                            // Use the splitTherapyDisplay function to get the parts of therapy_display
                            const drugDisplay = (treatment.therapy_display ? treatment.therapy_display.split('+').map(part => part.trim()) : []);
                            // Log or further process the parts as needed
                            drugDisplay.forEach(drug => {
                                if (!drugs.includes(drug)) {
                                    drugs = [...drugs, drug];
                                }
                            });
                        });
                    }
                });
            }

            let drugDetailPromise = bulkDrugDetail(drugs);

            Promise.all([trialDetailPromise, drugDetailPromise]).then(([trialDetail, drugDetail]) => {
                const newDetailEntry = {
                    [trial.nct_id]: {
                        "trialDetail": trialDetail,
                        "drugDetail": drugDetail
                    }
                };

                trialsWithDetailGathered = [...trialsWithDetailGathered, trial.nct_id];
                trialsAdditionalDetail = {
                    ...trialsAdditionalDetail,
                    ...newDetailEntry
                };
                additionalDetailTrialSelected = trial.nct_id;
                additionalDetailsLoading = false;

            });
        } else {
            additionalDetailTrialSelected = trial.nct_id;
            additionalDetailsLoading = false;
        }


        if (rightPaneTitle !== option) {
            rightPaneTitle = option;
        }
        option = '';
    }

    // Handle eligibility row expansion

    function expandEligibility(eligibility_id, nct) {
        if (expandEligibilityKey[`${nct}${eligibility_id}`] !== undefined) {
            expandEligibilityKey[`${nct}${eligibility_id}`] = !expandEligibilityKey[`${nct}${eligibility_id}`];
        } else {
            expandEligibilityKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandDiseaseCriteria(eligibility_id, nct) {
        if (expandDiseaseCriteriaKey[`${nct}${eligibility_id}`] !== undefined) {
            expandDiseaseCriteriaKey[`${nct}${eligibility_id}`] = !expandDiseaseCriteriaKey[`${nct}${eligibility_id}`];
        } else {
            expandDiseaseCriteriaKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandAlterationCriteria(eligibility_id, nct) {
        if (expandAlterationCriteriaKey[`${nct}${eligibility_id}`] !== undefined) {
            expandAlterationCriteriaKey[`${nct}${eligibility_id}`] = !expandAlterationCriteriaKey[`${nct}${eligibility_id}`];
        } else {
            expandAlterationCriteriaKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandPICriteria(eligibility_id, nct) {
        if (expandPICriteriaKey[`${nct}${eligibility_id}`] !== undefined) {
            expandPICriteriaKey[`${nct}${eligibility_id}`] = !expandPICriteriaKey[`${nct}${eligibility_id}`];
        } else {
            expandPICriteriaKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandPI(pi_name, eligibility_id) {
        if (expandPIKey[`${pi_name}${eligibility_id}`] !== undefined) {
            expandPIKey[`${pi_name}${eligibility_id}`] = !expandPIKey[`${pi_name}${eligibility_id}`];
        } else {
            expandPIKey[`${pi_name}${eligibility_id}`] = true;
        }
    }

    function expandDiseaseOperator(eligibility_id, nct) {
        if (expandDiseaseOperatorKey[`${nct}${eligibility_id}`] !== undefined) {
            expandDiseaseOperatorKey[`${nct}${eligibility_id}`] = !expandDiseaseOperatorKey[`${nct}${eligibility_id}`];
        } else {
            expandDiseaseOperatorKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandAlterationOperator(eligibility_id, nct) {
        if (expandAlterationOperatorKey[`${nct}${eligibility_id}`] !== undefined) {
            expandAlterationOperatorKey[`${nct}${eligibility_id}`] = !expandAlterationOperatorKey[`${nct}${eligibility_id}`];
        } else {
            expandAlterationOperatorKey[`${nct}${eligibility_id}`] = true;
        }
    }

    function expandPIOperator(eligibility_id, nct, pi_id) {
        if (expandPIOperatorKey[`${nct}${eligibility_id}${pi_id}`] !== undefined) {
            expandPIOperatorKey[`${nct}${eligibility_id}${pi_id}`] = !expandPIOperatorKey[`${nct}${eligibility_id}${pi_id}`];
        } else {
            expandPIOperatorKey[`${nct}${eligibility_id}${pi_id}`] = true;
        }
    }

    function expandDiseaseConcept(eligibility_id, nct, operator, groupId, i) {
        if (expandDiseaseConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] !== undefined) {
            expandDiseaseConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] = !expandDiseaseConceptKey[`${nct}${eligibility_id}${operator}${groupId}`];
        } else {
            expandDiseaseConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] = true;
        }
        if (i + 1 > visibleDiseaseIndex) {
            visibleDiseaseIndex = i + 1;
        }
        if (!expandDiseaseConceptKey[`${nct}${eligibility_id}${operator}${groupId}`]) {
            visibleDiseaseIndex = i;
        }
    }

    function expandAlterationConcept(eligibility_id, nct, operator, groupId, i) {
        if (expandAlterationConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] !== undefined) {
            expandAlterationConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] = !expandAlterationConceptKey[`${nct}${eligibility_id}${operator}${groupId}`];
        } else {
            expandAlterationConceptKey[`${nct}${eligibility_id}${operator}${groupId}`] = true;
        }
        if (i + 1 > visibleAlterationIndex) {
            visibleAlterationIndex = i + 1;
        }
        if (!expandAlterationConceptKey[`${nct}${eligibility_id}${operator}${groupId}`]) {
            visibleAlterationIndex = i;
        }
    }

    function expandPIConcept(eligibility_id, nct, operator, pi, i) {
        if (expandPIConceptKey[`${nct}${eligibility_id}${operator}${pi}`] !== undefined) {
            expandPIConceptKey[`${nct}${eligibility_id}${operator}${pi}`] = !expandPIConceptKey[`${nct}${eligibility_id}${operator}${pi}`];
        } else {
            expandPIConceptKey[`${nct}${eligibility_id}${operator}${pi}`] = true;
        }
        if (i + 1 > visiblePIIndex) {
            visiblePIIndex = i + 1;
        }
        if (!expandPIConceptKey[`${nct}${eligibility_id}${operator}${pi}`]) {
            visiblePIIndex = i;
        }
    }

    function getEligibilities(matched, nct, eligibilities) {
        let eligibilityIds = [];
        if ($instBioTrials.results.find(trial => trial.nct_id === nct)) {
            $instBioTrials.results.find(trial => trial.nct_id === nct).match_results.forEach(mr => {
                if (!eligibilityIds.includes(mr.eligibility_id.split("_")[0])) {
                    eligibilityIds = [...eligibilityIds, mr.eligibility_id.split("_")[0]];
                }
            });
            $instBioTrials.results.find(trial => trial.nct_id === nct).matched_diseases.forEach(md => {
                md.eligibility_ids.forEach(eligibility_id => {
                    if (!eligibilityIds.includes(eligibility_id.split("_")[0])) {
                        eligibilityIds = [...eligibilityIds, eligibility_id.split("_")[0]];
                    }
                });
            });
        } else if ($instNonBioTrials.results.find(trial => trial.nct_id === nct)) {
            $instNonBioTrials.results.find(trial => trial.nct_id === nct).match_results.forEach(mr => {
                if (!eligibilityIds.includes(mr.eligibility_id.split("_")[0])) {
                    eligibilityIds = [...eligibilityIds, mr.eligibility_id.split("_")[0]];
                }
            });
            $instNonBioTrials.results.find(trial => trial.nct_id === nct).matched_diseases.forEach(md => {
                md.eligibility_ids.forEach(eligibility_id => {
                    if (!eligibilityIds.includes(eligibility_id.split("_")[0])) {
                        eligibilityIds = [...eligibilityIds, eligibility_id.split("_")[0]];
                    }
                });
            });
        } else if ($outsideTrials.results.find(trial => trial.nct_id === nct)) {
            $outsideTrials.results.find(trial => trial.nct_id === nct).match_results.forEach(mr => {
                if (!eligibilityIds.includes(mr.eligibility_id.split("_")[0])) {
                    eligibilityIds = [...eligibilityIds, mr.eligibility_id.split("_")[0]];
                }
            });
            $outsideTrials.results.find(trial => trial.nct_id === nct).matched_diseases.forEach(md => {
                md.eligibility_ids.forEach(eligibility_id => {
                    if (!eligibilityIds.includes(eligibility_id.split("_")[0])) {
                        eligibilityIds = [...eligibilityIds, eligibility_id.split("_")[0]];
                    }
                });
            });
        }
        if (eligibilityIds.length > 0) {
            if (matched) {
                return eligibilities.filter(eligibility => eligibilityIds.includes(eligibility.id));
            } else {
                return eligibilities.filter(eligibility => !eligibilityIds.includes(eligibility.id));

            }
        } else {
            return [];
        }
    }

    function getUntestedBiomarkers(matchResult) {
        const untestedBiomarkers = {
            "eligibilityName": matchResult.eligibility_name,
            "SNV": [],
            "CNV": [],
            "PRX": [],
            "BIOMARKER": [],
            "CTX": [],
            "KARYOTYPE": [],
            "length": 0
        };

        if (matchResult.match_result === 'PartialMatch') {
            matchResult.untested_concepts.forEach(untestedConcept => {
                if (!Array.isArray(untestedBiomarkers[untestedConcept.test_mode])) {
                    untestedBiomarkers[untestedConcept.test_mode] = [];
                }
                untestedBiomarkers[untestedConcept.test_mode] = [...untestedBiomarkers[untestedConcept.test_mode], untestedConcept.name];
                untestedBiomarkers['length'] = (untestedBiomarkers['length'] || 0) + 1;
            });
        }


        return untestedBiomarkers;
    }

    function getDisplayedTestMode(test_mode) {
        switch (test_mode) {
        case 'SNV':
            return 'Mutations';
        case 'CNV':
            return 'Copy Number';
        case 'PRX':
            return 'Expression';
        case 'BIOMARKER':
            return 'Other biomarkers';
        case 'CTX':
            return 'Rearrangements';
        case 'KARYOTYPE':
            return 'Karyotype';
        default:
            return test_mode;
        }
    }

    let traversedPILogicSets = {};
    let traversedPILogicSetLengths = {};
    let traversedAlterationLogicSet = [];
    let traversedAlterationLogicSetLength = 0;
    let traversedDiseaseLogicSet = [];
    let traversedDiseaseLogicSetLength = 0;

    function traversePILogicSet(id, logicSets, index) {
        if (index === 0) {
            traversedPILogicSets[id] = [];
            traversedPILogicSetLengths[id] = 0;
        }

        let isDone = false;

        logicSets.forEach((logicSet, i) => {
            const newLogicSetItem = {
                index: index,
                operator: logicSet.operator,
                concepts: logicSet.concepts,
                groupId: logicSet.group_id !== null ? logicSet.group_id : 0
            };
            traversedPILogicSets[id] = [...traversedPILogicSets[id], newLogicSetItem];

            if (logicSet.logic_sets.length > 0) {
                traversePILogicSet(id, logicSet.logic_sets, index + 1);
            } else {
                if (i === logicSets.length - 1) {
                    isDone = true;
                }
            }
        });

        if (isDone) {
            traversedPILogicSetLengths[id] = (index + 1);
        }
    }

    function traverseAlterationLogicSet(logicSets, index) {
        if (index === 0) {
            traversedAlterationLogicSet = [];
            traversedAlterationLogicSetLength = 0;
            visibleAlterationIndex = 0;
        }

        let isDone = false;

        logicSets.forEach((logicSet, i) => {
            const newLogicSetItem = {
                index: index,
                operator: logicSet.operator,
                concepts: logicSet.concepts,
                groupId: logicSet.group_id !== null ? logicSet.group_id : 0
            };
            traversedAlterationLogicSet = [...traversedAlterationLogicSet, newLogicSetItem];

            if (logicSet.logic_sets.length > 0) {
                traverseAlterationLogicSet(logicSet.logic_sets, index + 1);
            } else {
                if (i === logicSets.length - 1) {
                    isDone = true;
                }
            }
        });

        if (isDone) {
            traversedAlterationLogicSetLength = (index + 1);
        }
    }

    function traverseDiseaseLogicSet(logicSets, index) {
        if (index === 0) {
            traversedDiseaseLogicSet = [];
            traversedDiseaseLogicSetLength = 0;
            visibleDiseaseIndex = 0;
        }

        let isDone = false;

        logicSets.forEach((logicSet, i) => {
            const newLogicSetItem = {
                index: index,
                operator: logicSet.operator,
                concepts: logicSet.concepts,
                groupId: logicSet.group_id !== null ? logicSet.group_id : 0
            };
            traversedDiseaseLogicSet = [...traversedDiseaseLogicSet, newLogicSetItem];

            if (logicSet.logic_sets.length > 0) {
                traverseDiseaseLogicSet(logicSet.logic_sets, index + 1);
            } else {
                if (i === logicSets.length - 1) {
                    isDone = true;
                }
            }
        });

        if (isDone) {
            traversedDiseaseLogicSetLength = (index + 1);
        }
    }

    function checkIfAlteration(concept, triggerAlterations) {
        const mutations = triggerAlterations.map(trigger => {
            const words = trigger.split(' ');
            return words[0] + ' Mutation';
        });
        return triggerAlterations.includes(concept) || mutations.includes(concept);
    }

    function checkIfDiseases(concept, matchedDiseases) {
        // Check for exact or specific disease match
        return casesMatchedDiseases.includes(concept);
    }

    // Prior Treatment Functions
    function gatherPriorTreatment(treatmentContext, eligibility) {
        let detected = false;
        let negative = false;
        let qualified = false;
        let drugsList = [];
        let negativeDrugsList = [];
        let info_qualifier = '';
        let info_time = '';
        let info_string = '';

        eligibility.forEach(elig => {
            if (elig.treatment_contexts != null && elig.treatment_contexts.length > 0) {
                elig.prior_interventions.forEach(intervent => {

                    if (intervent.qualifier != null && (intervent.qualifier.length > info_qualifier.length)) {
                        info_qualifier = intervent.qualifier;
                    }
                    if (intervent.time != null && intervent.time != '') {
                        info_time = "time: " + intervent.time_operator + intervent.time + " weeks";
                    }
                });
            }
        });

        treatmentContext.pi_matches.forEach(pi => {
            //Gather detected drugs
            if (pi.detected_drugs) {
                pi.detected_drugs.forEach(drug => {
                    if (drug.trigger_drug && !drugsList.includes(drug.trigger_drug)) {
                        detected = true;
                        drugsList.push(drug.trigger_drug);
                    }
                });
            }
            //Gather Negative Drugs
            if (pi.negative_drugs) {
                pi.negative_drugs.forEach(drug => {
                    if (!negativeDrugsList.includes(drug)) {
                        negative = true;
                        negativeDrugsList.push(drug);
                    }
                });
            }

            //Gather Qualified
            if (pi.qualified) {
                qualified = true;
                info_string = info_qualifier + " " + info_time;
            }
        });

        return {detected, negative, qualified, drugsList, negativeDrugsList, info_string};
    }

    function getPILogicSet(id, eligibility) {
        let PILogicSet = [];
        eligibility.treatment_contexts.forEach(tc => {
            tc.prior_interventions.forEach(pi => {
                if (pi.id === id) {
                    PILogicSet = pi.logic_sets;
                }
            });
        });
        return PILogicSet;
    }

    function getPINameById(id, eligibility) {
        const pi = eligibility.prior_interventions.find(pi => String(pi.id) === String(id));
        return pi ? pi.name : 'Unknown'; // Return 'Unknown' if no match is found
    }

    function getQualifiedStringById(id, eligibility) {
        const pi = eligibility.prior_interventions.find(pi => String(pi.id) === String(id));
        let qualifiedString = '';
        if (pi.qualifier != null && (pi.qualifier !== '')) {
            qualifiedString += pi.qualifier;
        }
        if (pi.time != null && pi.time != '') {
            qualifiedString += "time: " + pi.time_operator + pi.time + " weeks";
        }
        return qualifiedString;
    }

    $: openRightPane = $viewUnsavedChanges === 'open' ? 'open' : rightAdditionalPaneState;


</script>

<style>

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-toggle {
        cursor: pointer;
        padding: 5px 10px;
        background-color: transparent;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 150px;
        z-index: 2;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .dropdown-menu-item {
        cursor: pointer;
        padding: 5px 10px;
    }

    .dropdown-menu-item:hover {
        background-color: #f0f0f0;
    }

    li {
        font-size: 14px;
        text-align: left;
        margin: 0;
    }

    .caption {
        position: sticky;
        z-index: 1;
        top: 0;
        font-weight: bold;
        font-size: 20px;
        background-color: white;
        text-align: left;
    }

    th {
        position: sticky;
        z-index: 1;
        top: 25px;
        background-color: white;
    }

    .top-tier-caption {
        position: sticky;
        z-index: 3;
        top: 0;
        font-weight: bold;
        font-size: 20px;
        background-color: white;
        height: 35px;
        text-align: left;
    }

    .second-tier-caption {
        position: sticky;
        z-index: 2;
        top: 35px;
        font-weight: bold;
        font-size: 20px;
        background-color: white;
        text-align: left;
    }

    .third-tier-th {
        position: sticky;
        z-index: 1;
        top: 60px;
        background-color: white;
    }

    .match-results-table {
        height: calc(60vh - 50px);
        margin-top: 5px;
        overflow-x: scroll;
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    .table-body {
        height: 4em;
    }

    .table-body td {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 10px;
    }

    .table-body td:first-child {
        border-left: 1px solid black;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    .table-body td:last-child {
        border-right: 1px solid black;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .match-table {
        font-size: 14px;
        width: 90%;
        margin-left: 5%;
        margin-top: 25px;
        cursor: pointer;
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    .icon-button {
        cursor: pointer;
        width: 100px;
        margin-left: 10px;
    }

    .location-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-left: 10px;
    }

    .location-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        width: 80vw;
        padding: 20px;
    }

    .location-card h3 {
        margin-top: 0;
    }

    .location-card p {
        margin: 5px 0;
    }

    .concept-item {
        font-size: 12px;
        margin-bottom: 10px;
        margin-left: 35px;
    }

    .logicset-item {
        font-size: 12px;
        font-weight: bold;
        margin-left: 30px;
        margin-bottom: 10px;
    }

    .logicset-operator {
        font-size: 12px;
        font-weight: bold;
        margin-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .criteria-item {
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .additional-fields {
        height: calc(60vh - 15px);
        overflow-x: scroll;
    }

    .additional-fields h3 {
        font-size: 18px;
    }

    .additional-fields p {
        font-size: 14px;
    }

    .additional-fields span {
        font-size: 14px;
    }

    .untested-bio-table {
        margin-left: 10px;
    }

    .untested-bio-table th, .untested-bio-table td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        width: 50vw;
    }

    .unsaved-biomarker {
        cursor: pointer;
    }

    .tooltip-container {
        position: relative;
        display: inline-block;
    }

    .tooltip-text {
        visibility: hidden;
        width: 300px; /* Adjust width as needed */
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 125%;
        left: 50%;
        margin-left: -40px;
        transition: opacity 0.3s;
    }

    .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-container:hover .tooltip-icon {
        color: #e79a45;
    }

</style>

<HSplitPane leftPaneSize={openRightPane==='closed' ? '100%' : '70%'}
            rightPaneSize={openRightPane==='closed' ? '0%' : '30%'}
            minRightPaneSize={openRightPane==='closed' ? '0%' : '20%'}>
    <left slot="left">
        {#if ($leftFilterPaneState==='open')}
            <HSplitPane leftPaneSize = "30%" rightPaneSize= "70%" minLeftPaneSize= "20%">
            <left slot="left">
                {#if ($leftFilterPaneState === 'open')}
                    <svelte:component this={PatientMatchFilterNav}/>
                {/if}
            </left>
            <right slot="right">
                <div class="match-results-table">

                    <!--trials-->
                    {#if $matchTableSetting === 'allTrials'}
                        <h3 style="font-size: 20px; margin-left: 10px; text-align: left;">
                            Matched Clinical Trials
                            ({filteredInstNonBioTrials.length + filteredInstBioTrials.length + filteredOutsideTrials.length}
                            )
                        </h3>
                        <h3 style="font-size: 20px; margin-left: 20px; text-align: left;">
                            Matched {getDefaultInstitution()} Trials
                            ({filteredInstNonBioTrials.length + filteredInstBioTrials.length})
                        </h3>
                    {:else if $matchTableSetting === 'instTrialsOnly'}
                        <h3 style="font-size: 20px; margin-left: 10px; text-align: left;">
                            Matched {getDefaultInstitution()} Trials
                            ({filteredInstNonBioTrials.length + filteredInstBioTrials.length})
                        </h3>
                    {/if}

                    {#if $matchTableSetting === 'allTrials' || $matchTableSetting === 'instTrialsOnly'}
                        <!--inst bio trials-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th class="caption" colspan="9">Biomarker Based Matched
                                        ({filteredInstBioTrials.length})
                                    </th>
                                {:else}
                                    <th class="caption" colspan="8">Biomarker Based Matched
                                        ({filteredInstBioTrials.length})
                                    </th>
                                {/if}
                            </tr>
                            <tr>

                                <th>
                                    Protocol ID
                                </th>
                                <th>
                                    NCT ID
                                </th>
                                <th>
                                    Accural Status
                                </th>
                                <th>
                                    Phase
                                </th>
                                <th>
                                    Matched Disease
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th>
                                        Prior Treatment Requirements
                                    </th>
                                {/if}
                                <th>
                                    Treatment Arm
                                </th>
                                <th>
                                    Additional Details
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredInstBioTrials.length > 0)}
                                {#each filteredInstBioTrials as trial}
                                    <tr class="table-body">
                                        <td>
                                            {trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids.join(", ") : ""}
                                        </td>
                                        <td>
                                            {trial.nct_id}
                                        </td>
                                        <td>
                                            {trial.inst_statuses && trial.inst_statuses.length > 0 ? trial.inst_statuses.join(", ") : ""}
                                        </td>
                                        <td>
                                            {trial.phase}
                                        </td>
                                        <td>
                                            {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                                {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                    <p>{disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                                {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                    <p>{marker}</p>
                                                    {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                            {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarker
                                                                </p>
                                                            {:else}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarkers
                                                                </p>
                                                            {/if}
                                                        {/if}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                            <td>

                                                {#each trial.match_results as therapy}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        {#key treatment}
                                                            {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                                <div>
                                                                    {#if result.detected}
                                                                        <p>Received: {result.drugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.negative}
                                                                        <p>Not
                                                                            Received: {result.negativeDrugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.qualified && result.info_string}
                                                                        <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                            <span class="tooltip-text">{result.info_string}</span>
                                                                        </div>
                                                                    {/if}
                                                                </div>
                                                            {/await}
                                                        {/key}
                                                    {/each}
                                                {/each}
                                            </td>
                                        {/if}
                                        <td>
                                            {#if trial.match_results && trial.match_results.length > 0}
                                                {#each trial.match_results as therapy}
                                                    {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                        {#each therapy.treatment_contexts as treatment}
                                                            <p>{treatment.therapy_display}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <div class="dropdown-toggle"
                                                     on:click={() => {toggleDropdown(trial, "instBio")}}>
                                                    <Icon data="{faBars}"/>
                                                </div>
                                                {#if (openDropdownId === `${trial.nct_id}_instBio`)}
                                                    <ul class="dropdown-menu">
                                                        {#each additionalDetails as detail}
                                                            <p class="dropdown-menu-item"
                                                               on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                        {/each}
                                                    </ul>
                                                {/if}
                                            </div>
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <tr class="table-body">
                                        <td colspan="9">No Institutional Biomarker Matches found</td>
                                    </tr>
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="8">No Institutional Biomarker Matches found</td>
                                    </tr>
                                {/if}
                            {/if}

                        </table>

                        <!--inst non-bio trials-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th class="caption" colspan="9">Non-Biomarker Based Matched
                                        ({filteredInstNonBioTrials.length})
                                    </th>
                                {:else}
                                    <th class="caption" colspan="8">Non-Biomarker Based Matched
                                        ({filteredInstNonBioTrials.length})
                                    </th>
                                {/if}
                            </tr>
                            <tr>
                                <th>
                                    Protocol ID
                                </th>
                                <th>
                                    NCT ID
                                </th>
                                <th>
                                    Accural Status
                                </th>
                                <th>
                                    Phase
                                </th>
                                <th>
                                    Matched Disease
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th>
                                        Prior Treatment Requirements
                                    </th>
                                {/if}
                                <th>
                                    Treatment Arm
                                </th>
                                <th>
                                    Additional Details
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredInstNonBioTrials.length > 0)}
                                {#each filteredInstNonBioTrials as trial}
                                    <tr class="table-body">
                                        <td>
                                            {trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids.join(", ") : ""}
                                        </td>
                                        <td>
                                            {trial.nct_id}
                                        </td>
                                        <td>
                                            {trial.inst_statuses && trial.inst_statuses.length > 0 ? trial.inst_statuses.join(", ") : ""}
                                        </td>
                                        <td>
                                            {trial.phase}
                                        </td>
                                        <td>
                                            {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                                {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                    <p>{disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                                {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                    <p>{marker}</p>
                                                    {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                            {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarker
                                                                </p>
                                                            {:else}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarkers
                                                                </p>
                                                            {/if}
                                                        {/if}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                            <td>
                                                {#each trial.match_results as therapy}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        {#key treatment}
                                                            {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                                <div>
                                                                    {#if result.detected}
                                                                        <p>Received: {result.drugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.negative}
                                                                        <p>Not
                                                                            Received: {result.negativeDrugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.qualified && result.info_string}
                                                                        <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                            <span class="tooltip-text">{result.info_string}</span>
                                                                        </div>
                                                                    {/if}
                                                                </div>
                                                            {/await}
                                                        {/key}
                                                    {/each}
                                                {/each}
                                            </td>
                                        {/if}
                                        <td>
                                            {#if trial.match_results && trial.match_results.length > 0}
                                                {#each trial.match_results as therapy}
                                                    {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                        {#each therapy.treatment_contexts as treatment}
                                                            <p>{treatment.therapy_display}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <div class="dropdown-toggle"
                                                     on:click={() => {toggleDropdown(trial, "instNonBio")}}>
                                                    <Icon data="{faBars}"/>
                                                </div>
                                                {#if (openDropdownId === `${trial.nct_id}_instNonBio`)}
                                                    <ul class="dropdown-menu">
                                                        {#each additionalDetails as detail}
                                                            <p class="dropdown-menu-item"
                                                               on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                        {/each}
                                                    </ul>
                                                {/if}
                                            </div>
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                        <td colspan="9">No Institutional Non-Biomarker Matches found</td>
                                    {:else}
                                        <td colspan="8">No Institutional Non-Biomarker Matches found</td>

                                    {/if}
                                </tr>
                            {/if}
                        </table>
                    {/if}

                    <!--outside trials-->
                    {#if $matchTableSetting === 'allTrials' || $matchTableSetting === 'outsideTrialsOnly'}
                        <table class="match-table">
                            <thead>
                            <tr>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th class="caption" colspan="9">
                                        Matched Outside Trials ({filteredOutsideTrials.length})
                                    </th>
                                {:else}
                                    <th class="caption" colspan="8">
                                        Matched Outside Trials ({filteredOutsideTrials.length})
                                    </th>
                                {/if}
                            </tr>
                            <tr>
                                <th>
                                    NCT ID
                                </th>
                                <th>
                                    Recruiting Status
                                </th>
                                <th>
                                    Phase
                                </th>
                                <th>
                                    Matched Disease
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <th>
                                        Prior Treatment Requirements
                                    </th>
                                {/if}
                                <th>
                                    Treatment Arm
                                </th>
                                <th>
                                    Title
                                </th>
                                <th>
                                    Additional Details
                                </th>
                            </tr>

                            </thead>
                            {#if (filteredOutsideTrials.length > 0)}
                                {#each filteredOutsideTrials as trial}
                                    <tr class="table-body">
                                        <td>
                                            {trial.nct_id}
                                        </td>
                                        <td>
                                            {trial.recruiting_status ? trial.recruiting_status : "-"}
                                        </td>
                                        <td>
                                            {trial.phase}
                                        </td>
                                        <td>
                                            {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                                {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                    <p>{disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                                {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                    <p>{marker}</p>
                                                    {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                            {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarker
                                                                </p>
                                                            {:else}
                                                                <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                                   on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                    {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                    Untested Biomarkers
                                                                </p>
                                                            {/if}
                                                        {/if}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                            <td>

                                                {#each trial.match_results as therapy}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        {#key treatment}
                                                            {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                                <div>
                                                                    {#if result.detected}
                                                                        <p>Received: {result.drugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.negative}
                                                                        <p>Not
                                                                            Received: {result.negativeDrugsList.join(", ")}</p>
                                                                    {/if}
                                                                    {#if result.qualified && result.info_string}
                                                                        <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                            <span class="tooltip-text">{result.info_string}</span>
                                                                        </div>
                                                                    {/if}
                                                                </div>
                                                            {/await}
                                                        {/key}
                                                    {/each}
                                                {/each}
                                            </td>
                                        {/if}
                                        <td>
                                            {#if trial.match_results && trial.match_results.length > 0}
                                                {#each trial.match_results as therapy}
                                                    {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                        {#each therapy.treatment_contexts as treatment}
                                                            <p>{treatment.therapy_display}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        </td>
                                        <td style="text-align: left">
                                            {trial.title}
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <div class="dropdown-toggle"
                                                     on:click={() => {toggleDropdown(trial, "outside")}}>
                                                    <Icon data="{faBars}"/>
                                                </div>
                                                {#if (openDropdownId === `${trial.nct_id}_outside`)}
                                                    <ul class="dropdown-menu">
                                                        {#each additionalDetails as detail}
                                                            <p class="dropdown-menu-item"
                                                               on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                        {/each}
                                                    </ul>
                                                {/if}
                                            </div>
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <tr class="table-body">
                                        <td colspan="9">No Outside Matches found</td>
                                    </tr>
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="8">No Outside Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                    {/if}

                    <!--therapies-->
                    {#if ($matchTableSetting === "allTherapies" || $matchTableSetting === "fdaTherapies" || $matchTableSetting === "nccnTherapies")}
                        <h3 style="font-size: 20px; margin-left: 10px; text-align: left;"> Matched Therapies
                            ({filteredSenFTTTherapies.length + filteredSenOTTTherapies.length + filteredResTherapies.length}
                            )</h3>
                        <!--sen therapies for tumor type-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Sensitive Therapies for Patient’s Tumor Type
                                    ({filteredSenFTTTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredSenFTTTherapies.length > 0)}
                                {#each filteredSenFTTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>

                        <!--sen therapies other tumor type-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Sensitive Therapies for Other Tumor Types
                                    ({filteredSenOTTTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredSenOTTTherapies.length > 0)}
                                {#each filteredSenOTTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>

                        <!--res therapies-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Resistant Therapies ({filteredResTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredResTherapies.length > 0)}
                                {#each filteredResTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>
                    {:else if ($matchTableSetting === "senFTTTherapies")}
                        <!--sen therapies for tumor type-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Sensitive Therapies for Patient’s Tumor Type
                                    ({filteredSenFTTTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredSenFTTTherapies.length > 0)}
                                {#each filteredSenFTTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>
                    {:else if ($matchTableSetting === "senOTTTherapies")}
                        <!--sen therapies other tumor type-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Sensitive Therapies for Other Tumor Types
                                    ({filteredSenOTTTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredSenOTTTherapies.length > 0)}
                                {#each filteredSenOTTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>
                    {:else if ($matchTableSetting === "resTherapies")}
                        <!--res therapies-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="caption" colspan="6">Resistant Therapies ({filteredResTherapies.length})
                                </th>
                            </tr>
                            <tr>

                                <th>
                                    Drugs
                                </th>
                                <th>
                                    Diseases
                                </th>
                                <th>
                                    Matched Biomarkers
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>
                                    AMP Tier
                                </th>
                                <th>
                                    Settings
                                </th>
                            </tr>
                            </thead>
                            {#if (filteredResTherapies.length > 0)}
                                {#each filteredResTherapies as therapy}
                                    <tr class="table-body">
                                        <td>
                                            {therapy.therapy_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                        </td>
                                        <td>
                                            {therapy.trigger_alteration_display}
                                        </td>
                                        <td>
                                            {therapy.consolidated.indication}
                                        </td>
                                        <td>
                                            {therapy.consolidated.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {therapy.consolidated.setting_display}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        </table>
                    {:else if ($matchTableSetting === 'evidenceTherapies')}
                        <!--Sensitive Evidence Based Therapies -->
                        <h3 class="top-tier-caption"
                            style="font-size: 20px; margin-left: 10px; text-align: left; width: {openRightPane === 'open' ? '60vw' : '85vw'};">
                            Evidence Based Therapies - Sensitive
                            ({$senEvidenceMCGTherapies.length + $senEvidenceCTTherapies.length + $senEvidenceCSTherapies.length + $senEvidenceCRCSTherapies.length + $senEvidencePCTherapies.length}
                            )
                        </h3>
                        <!--MCG-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {senEvidenceMCGTherapiesTableState = senEvidenceMCGTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    My Cancer Genome Curated Therapies ({$senEvidenceMCGTherapies.length})
                                    {#if (senEvidenceMCGTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (senEvidenceMCGTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (senEvidenceMCGTherapiesTableState === 'open')}
                                {#if ($senEvidenceMCGTherapies.length > 0)}
                                    {#each $senEvidenceMCGTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Clinical Trial Results-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {senEvidenceCTTherapiesTableState = senEvidenceCTTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Clinical Trial Results ({$senEvidenceCTTherapies.length})
                                    {#if (senEvidenceCTTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (senEvidenceCTTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (senEvidenceCTTherapiesTableState === 'open')}
                                {#if ($senEvidenceCTTherapies.length > 0)}
                                    {#each $senEvidenceCTTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Clinical Study-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {senEvidenceCSTherapiesTableState = senEvidenceCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Clinical Study ({$senEvidenceCSTherapies.length})
                                    {#if (senEvidenceCSTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (senEvidenceCSTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (senEvidenceCSTherapiesTableState === 'open')}
                                {#if ($senEvidenceCSTherapies.length > 0)}
                                    {#each $senEvidenceCSTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Case Report/ Case Series-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {senEvidenceCRCSTherapiesTableState = senEvidenceCRCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Case Reports/Case Series ({$senEvidenceCRCSTherapies.length})
                                    {#if (senEvidenceCRCSTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (senEvidenceCRCSTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (senEvidenceCRCSTherapiesTableState === 'open')}

                                {#if ($senEvidenceCRCSTherapies.length > 0)}
                                    {#each $senEvidenceCRCSTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Preclinical-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {senEvidencePCTherapiesTableState = senEvidencePCTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Preclinical ({$senEvidencePCTherapies.length})
                                    {#if (senEvidencePCTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (senEvidencePCTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (senEvidencePCTherapiesTableState === 'open')}
                                {#if ($senEvidencePCTherapies.length > 0)}
                                    {#each $senEvidencePCTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>

                        <!--Resistant Evidence Based Therapies -->
                        <h3 class="top-tier-caption"
                            style="font-size: 20px; margin-left: 10px; text-align: left; width: {openRightPane === 'open' ? '60vw' : '85vw'};">
                            Evidence Based Therapies - Resistant
                            ({$resEvidenceMCGTherapies.length + $resEvidenceCTTherapies.length + $resEvidenceCSTherapies.length + $resEvidenceCRCSTherapies.length + $resEvidencePCTherapies.length}
                            )
                        </h3>
                        <!--MCG-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {resEvidenceMCGTherapiesTableState = resEvidenceMCGTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    My Cancer Genome Curated Therapies ({$resEvidenceMCGTherapies.length})
                                    {#if (resEvidenceMCGTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (resEvidenceMCGTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (resEvidenceMCGTherapiesTableState === 'open')}
                                {#if ($resEvidenceMCGTherapies.length > 0)}
                                    {#each $resEvidenceMCGTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Clinical Trial Results-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {resEvidenceCTTherapiesTableState = resEvidenceCTTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Clinical Trial Results ({$resEvidenceCTTherapies.length})
                                    {#if (resEvidenceCTTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (resEvidenceCTTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (resEvidenceCTTherapiesTableState === 'open')}
                                {#if ($resEvidenceCTTherapies.length > 0)}
                                    {#each $resEvidenceCTTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Clinical Study-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {resEvidenceCSTherapiesTableState = resEvidenceCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Clinical Study ({$resEvidenceCSTherapies.length})
                                    {#if (resEvidenceCSTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (resEvidenceCSTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (resEvidenceCSTherapiesTableState === 'open')}
                                {#if ($resEvidenceCSTherapies.length > 0)}
                                    {#each $resEvidenceCSTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Case Report/ Case Series-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {resEvidenceCRCSTherapiesTableState = resEvidenceCRCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Case Reports/Case Series ({$resEvidenceCRCSTherapies.length})
                                    {#if (resEvidenceCRCSTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (resEvidenceCRCSTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (resEvidenceCRCSTherapiesTableState === 'open')}

                                {#if ($resEvidenceCRCSTherapies.length > 0)}
                                    {#each $resEvidenceCRCSTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                        <!--Preclinical-->
                        <table class="match-table">
                            <thead>
                            <tr>
                                <th class="second-tier-caption" colspan="7"
                                    on:click={() => {resEvidencePCTherapiesTableState = resEvidencePCTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                    Preclinical ({$resEvidencePCTherapies.length})
                                    {#if (resEvidencePCTherapiesTableState === 'open')}
                                        <Icon data="{faChevronDown}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {:else}
                                        <Icon data="{faChevronUp}"
                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                    {/if}
                                </th>
                            </tr>
                            {#if (resEvidencePCTherapiesTableState === 'open')}
                                <tr>
                                    <th class="third-tier-th">
                                        Drugs
                                    </th>
                                    <th class="third-tier-th">
                                        Response
                                    </th>
                                    <th class="third-tier-th">
                                        Diseases
                                    </th>
                                    <th class="third-tier-th">
                                        Related Alterations
                                    </th>
                                    <th class="third-tier-th">
                                        Note
                                    </th>
                                    <th class="third-tier-th">
                                        AMP Tier
                                    </th>
                                    <th class="third-tier-th">
                                        Source
                                    </th>
                                </tr>
                            {/if}
                            </thead>
                            {#if (resEvidencePCTherapiesTableState === 'open')}
                                {#if ($resEvidencePCTherapies.length > 0)}
                                    {#each $resEvidencePCTherapies as therapy}
                                        <tr class="table-body">
                                            <td>{therapy.therapy_display}</td>
                                            <td>{therapy.response}</td>
                                            <td>
                                                {#if (!therapy.for_other_tumor_type)}
                                                    {#each therapy.matched_diseases as md}
                                                        <p>{md.matched_disease}</p>
                                                    {/each}
                                                {/if}
                                            </td>
                                            <td>
                                                {#each therapy.detected_alterations as da}
                                                    <p>{da.display}</p>
                                                {/each}
                                                {#each therapy.match_results as mr}
                                                    {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                        {#each mr.negative_alterations as na}
                                                            <p><strong>ND: </strong>{na.name}</p>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            </td>
                                            <td>
                                                {therapy.indication}
                                            </td>
                                            <td>
                                                {therapy.amp_tier_evidence}
                                            </td>
                                            <td>
                                                {#each therapy.evidence_references as er}
                                                    {#if er.reference_source_name === "PubMed"}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline"
                                                                   href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                                   target="_blank">
                                                                    PMID {er.reference_source_id}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}

                                                    {#if er.reference_source_name === "Other" && er.reference_url}
                                                        <div>
                                                            <b>
                                                                <a style="display: inline" href="{er.reference_url}"
                                                                   target="_blank">
                                                                    {er.reference_source_name}
                                                                </a>
                                                            </b>
                                                        </div>
                                                    {/if}
                                                {/each}
                                            </td>
                                        </tr>
                                    {/each}
                                {:else}
                                    <tr class="table-body">
                                        <td colspan="7">No Therapies Matches found</td>
                                    </tr>
                                {/if}
                            {/if}
                        </table>
                    {/if}
                </div>
            </right>
        </HSplitPane>
        {:else}
            <div class="match-results-table">

                <!--trials-->
                {#if $matchTableSetting === 'allTrials'}
                    <h3 style="font-size: 20px; margin-left: 10px; text-align: left;">
                        Matched Clinical Trials
                        ({filteredInstNonBioTrials.length + filteredInstBioTrials.length + filteredOutsideTrials.length}
                        )
                    </h3>
                    <h3 style="font-size: 20px; margin-left: 20px; text-align: left;">
                        Matched {getDefaultInstitution()} Trials
                        ({filteredInstNonBioTrials.length + filteredInstBioTrials.length})
                    </h3>
                {:else if $matchTableSetting === 'instTrialsOnly'}
                    <h3 style="font-size: 20px; margin-left: 10px; text-align: left;">
                        Matched {getDefaultInstitution()} Trials
                        ({filteredInstNonBioTrials.length + filteredInstBioTrials.length})
                    </h3>
                {/if}

                {#if $matchTableSetting === 'allTrials' || $matchTableSetting === 'instTrialsOnly'}
                    <!--inst bio trials-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th class="caption" colspan="9">Biomarker Based Matched
                                    ({filteredInstBioTrials.length})
                                </th>
                            {:else}
                                <th class="caption" colspan="8">Biomarker Based Matched
                                    ({filteredInstBioTrials.length})
                                </th>
                            {/if}
                        </tr>
                        <tr>

                            <th>
                                Protocol ID
                            </th>
                            <th>
                                NCT ID
                            </th>
                            <th>
                                Accural Status
                            </th>
                            <th>
                                Phase
                            </th>
                            <th>
                                Matched Disease
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th>
                                    Prior Treatment Requirements
                                </th>
                            {/if}
                            <th>
                                Treatment Arm
                            </th>
                            <th>
                                Additional Details
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredInstBioTrials.length > 0)}
                            {#each filteredInstBioTrials as trial}
                                <tr class="table-body">
                                    <td>
                                        {trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids.join(", ") : ""}
                                    </td>
                                    <td>
                                        {trial.nct_id}
                                    </td>
                                    <td>
                                        {trial.inst_statuses && trial.inst_statuses.length > 0 ? trial.inst_statuses.join(", ") : ""}
                                    </td>
                                    <td>
                                        {trial.phase}
                                    </td>
                                    <td>
                                        {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                            {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                <p>{disease}</p>
                                            {/each}
                                        {/if}
                                    </td>
                                    <td>
                                        {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                            {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                <p>{marker}</p>
                                                {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                    {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarker
                                                            </p>
                                                        {:else}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarkers
                                                            </p>
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                        <td>

                                            {#each trial.match_results as therapy}
                                                {#each therapy.treatment_contexts as treatment}
                                                    {#key treatment}
                                                        {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                            <div>
                                                                {#if result.detected}
                                                                    <p>Received: {result.drugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.negative}
                                                                    <p>Not
                                                                        Received: {result.negativeDrugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.qualified && result.info_string}
                                                                    <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                        <span class="tooltip-text">{result.info_string}</span>
                                                                    </div>
                                                                {/if}
                                                            </div>
                                                        {/await}
                                                    {/key}
                                                {/each}
                                            {/each}
                                        </td>
                                    {/if}
                                    <td>
                                        {#if trial.match_results && trial.match_results.length > 0}
                                            {#each trial.match_results as therapy}
                                                {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        <p>{treatment.therapy_display}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <div class="dropdown-toggle"
                                                 on:click={() => {toggleDropdown(trial, "instBio")}}>
                                                <Icon data="{faBars}"/>
                                            </div>
                                            {#if (openDropdownId === `${trial.nct_id}_instBio`)}
                                                <ul class="dropdown-menu">
                                                    {#each additionalDetails as detail}
                                                        <p class="dropdown-menu-item"
                                                           on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                    {/each}
                                                </ul>
                                            {/if}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <tr class="table-body">
                                    <td colspan="9">No Institutional Biomarker Matches found</td>
                                </tr>
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Institutional Biomarker Matches found</td>
                                </tr>
                            {/if}
                        {/if}

                    </table>

                    <!--inst non-bio trials-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th class="caption" colspan="9">Non-Biomarker Based Matched
                                    ({filteredInstNonBioTrials.length})
                                </th>
                            {:else}
                                <th class="caption" colspan="8">Non-Biomarker Based Matched
                                    ({filteredInstNonBioTrials.length})
                                </th>
                            {/if}
                        </tr>
                        <tr>
                            <th>
                                Protocol ID
                            </th>
                            <th>
                                NCT ID
                            </th>
                            <th>
                                Accural Status
                            </th>
                            <th>
                                Phase
                            </th>
                            <th>
                                Matched Disease
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th>
                                    Prior Treatment Requirements
                                </th>
                            {/if}
                            <th>
                                Treatment Arm
                            </th>
                            <th>
                                Additional Details
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredInstNonBioTrials.length > 0)}
                            {#each filteredInstNonBioTrials as trial}
                                <tr class="table-body">
                                    <td>
                                        {trial.inst_protocol_ids && trial.inst_protocol_ids.length > 0 ? trial.inst_protocol_ids.join(", ") : ""}
                                    </td>
                                    <td>
                                        {trial.nct_id}
                                    </td>
                                    <td>
                                        {trial.inst_statuses && trial.inst_statuses.length > 0 ? trial.inst_statuses.join(", ") : ""}
                                    </td>
                                    <td>
                                        {trial.phase}
                                    </td>
                                    <td>
                                        {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                            {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                <p>{disease}</p>
                                            {/each}
                                        {/if}
                                    </td>
                                    <td>
                                        {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                            {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                <p>{marker}</p>
                                                {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                    {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarker
                                                            </p>
                                                        {:else}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarkers
                                                            </p>
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                        <td>
                                            {#each trial.match_results as therapy}
                                                {#each therapy.treatment_contexts as treatment}
                                                    {#key treatment}
                                                        {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                            <div>
                                                                {#if result.detected}
                                                                    <p>Received: {result.drugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.negative}
                                                                    <p>Not
                                                                        Received: {result.negativeDrugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.qualified && result.info_string}
                                                                    <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                        <span class="tooltip-text">{result.info_string}</span>
                                                                    </div>
                                                                {/if}
                                                            </div>
                                                        {/await}
                                                    {/key}
                                                {/each}
                                            {/each}
                                        </td>
                                    {/if}
                                    <td>
                                        {#if trial.match_results && trial.match_results.length > 0}
                                            {#each trial.match_results as therapy}
                                                {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        <p>{treatment.therapy_display}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <div class="dropdown-toggle"
                                                 on:click={() => {toggleDropdown(trial, "instNonBio")}}>
                                                <Icon data="{faBars}"/>
                                            </div>
                                            {#if (openDropdownId === `${trial.nct_id}_instNonBio`)}
                                                <ul class="dropdown-menu">
                                                    {#each additionalDetails as detail}
                                                        <p class="dropdown-menu-item"
                                                           on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                    {/each}
                                                </ul>
                                            {/if}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                    <td colspan="9">No Institutional Non-Biomarker Matches found</td>
                                {:else}
                                    <td colspan="8">No Institutional Non-Biomarker Matches found</td>

                                {/if}
                            </tr>
                        {/if}
                    </table>
                {/if}

                <!--outside trials-->
                {#if $matchTableSetting === 'allTrials' || $matchTableSetting === 'outsideTrialsOnly'}
                    <table class="match-table">
                        <thead>
                        <tr>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th class="caption" colspan="9">
                                    Matched Outside Trials ({filteredOutsideTrials.length})
                                </th>
                            {:else}
                                <th class="caption" colspan="8">
                                    Matched Outside Trials ({filteredOutsideTrials.length})
                                </th>
                            {/if}
                        </tr>
                        <tr>
                            <th>
                                NCT ID
                            </th>
                            <th>
                                Recruiting Status
                            </th>
                            <th>
                                Phase
                            </th>
                            <th>
                                Matched Disease
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <th>
                                    Prior Treatment Requirements
                                </th>
                            {/if}
                            <th>
                                Treatment Arm
                            </th>
                            <th>
                                Title
                            </th>
                            <th>
                                Additional Details
                            </th>
                        </tr>

                        </thead>
                        {#if (filteredOutsideTrials.length > 0)}
                            {#each filteredOutsideTrials as trial}
                                <tr class="table-body">
                                    <td>
                                        {trial.nct_id}
                                    </td>
                                    <td>
                                        {trial.recruiting_status ? trial.recruiting_status : "-"}
                                    </td>
                                    <td>
                                        {trial.phase}
                                    </td>
                                    <td>
                                        {#if trial.matched_diseases && trial.matched_diseases.length > 0}
                                            {#each trial.matched_diseases.map(disease => disease.matched_disease) as disease}
                                                <p>{disease}</p>
                                            {/each}
                                        {/if}
                                    </td>
                                    <td>
                                        {#if trial.detected_alterations && trial.detected_alterations.length > 0}
                                            {#each trial.detected_alterations.map(marker => marker.display) as marker, index}
                                                <p>{marker}</p>
                                                {#if trial.match_results.length > 0 && trial.match_results[index]}
                                                    {#if getUntestedBiomarkers(trial.match_results[index])["length"] > 0}
                                                        {#if getUntestedBiomarkers(trial.match_results[index])["length"] === 1}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarker
                                                            </p>
                                                        {:else}
                                                            <p style="text-decoration: underline; cursor: pointer; color:#3A63B0;"
                                                               on:click={() => {rightAdditionalPaneState = "open"; viewUnsavedChanges.set('closed'); rightPaneTitle='Untested Biomarkers'; selectedUntestedBiomarkerDetails=getUntestedBiomarkers(trial.match_results[index])}}>
                                                                {getUntestedBiomarkers(trial.match_results[index])["length"]}
                                                                Untested Biomarkers
                                                            </p>
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                        <td>

                                            {#each trial.match_results as therapy}
                                                {#each therapy.treatment_contexts as treatment}
                                                    {#key treatment}
                                                        {#await Promise.resolve(gatherPriorTreatment(treatment, trial.eligibility)) then result}
                                                            <div>
                                                                {#if result.detected}
                                                                    <p>Received: {result.drugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.negative}
                                                                    <p>Not
                                                                        Received: {result.negativeDrugsList.join(", ")}</p>
                                                                {/if}
                                                                {#if result.qualified && result.info_string}
                                                                    <div class="tooltip-container">
                                                                        <span style="color:#3A63B0"
                                                                              on:click={() => {handleChange(trial, 'Explore Matched Eligibility Details')}}>qualified <Icon
                                                                                class="tooltip-icon"
                                                                                data="{faCircleInfo}" scale="0.75"
                                                                                style="color:black;"/></span>
                                                                        <span class="tooltip-text">{result.info_string}</span>
                                                                    </div>
                                                                {/if}
                                                            </div>
                                                        {/await}
                                                    {/key}
                                                {/each}
                                            {/each}
                                        </td>
                                    {/if}
                                    <td>
                                        {#if trial.match_results && trial.match_results.length > 0}
                                            {#each trial.match_results as therapy}
                                                {#if (therapy.treatment_contexts && therapy.treatment_contexts.length > 0)}
                                                    {#each therapy.treatment_contexts as treatment}
                                                        <p>{treatment.therapy_display}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        {/if}
                                    </td>
                                    <td style="text-align: left">
                                        {trial.title}
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <div class="dropdown-toggle"
                                                 on:click={() => {toggleDropdown(trial, "outside")}}>
                                                <Icon data="{faBars}"/>
                                            </div>
                                            {#if (openDropdownId === `${trial.nct_id}_outside`)}
                                                <ul class="dropdown-menu">
                                                    {#each additionalDetails as detail}
                                                        <p class="dropdown-menu-item"
                                                           on:click={() => handleChange(trial, detail)}>{detail}</p>
                                                    {/each}
                                                </ul>
                                            {/if}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            {#if ($selectedCase && $selectedCase.oncologic_treatments && $selectedCase.oncologic_treatments.length > 0)}
                                <tr class="table-body">
                                    <td colspan="9">No Outside Matches found</td>
                                </tr>
                            {:else}
                                <tr class="table-body">
                                    <td colspan="8">No Outside Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                {/if}

                <!--therapies-->
                {#if ($matchTableSetting === "allTherapies" || $matchTableSetting === "fdaTherapies" || $matchTableSetting === "nccnTherapies")}
                    <h3 style="font-size: 20px; margin-left: 10px; text-align: left;"> Matched Therapies
                        ({filteredSenFTTTherapies.length + filteredSenOTTTherapies.length + filteredResTherapies.length}
                        )</h3>
                    <!--sen therapies for tumor type-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Sensitive Therapies for Patient’s Tumor Type
                                ({filteredSenFTTTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredSenFTTTherapies.length > 0)}
                            {#each filteredSenFTTTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>

                    <!--sen therapies other tumor type-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Sensitive Therapies for Other Tumor Types
                                ({filteredSenOTTTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredSenOTTTherapies.length > 0)}
                            {#each filteredSenOTTTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>

                    <!--res therapies-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Resistant Therapies ({filteredResTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredResTherapies.length > 0)}
                            {#each filteredResTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>
                {:else if ($matchTableSetting === "senFTTTherapies")}
                    <!--sen therapies for tumor type-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Sensitive Therapies for Patient’s Tumor Type
                                ({filteredSenFTTTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredSenFTTTherapies.length > 0)}
                            {#each filteredSenFTTTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>
                {:else if ($matchTableSetting === "senOTTTherapies")}
                    <!--sen therapies other tumor type-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Sensitive Therapies for Other Tumor Types
                                ({filteredSenOTTTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredSenOTTTherapies.length > 0)}
                            {#each filteredSenOTTTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>
                {:else if ($matchTableSetting === "resTherapies")}
                    <!--res therapies-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="caption" colspan="6">Resistant Therapies ({filteredResTherapies.length})
                            </th>
                        </tr>
                        <tr>

                            <th>
                                Drugs
                            </th>
                            <th>
                                Diseases
                            </th>
                            <th>
                                Matched Biomarkers
                            </th>
                            <th>
                                Note
                            </th>
                            <th>
                                AMP Tier
                            </th>
                            <th>
                                Settings
                            </th>
                        </tr>
                        </thead>
                        {#if (filteredResTherapies.length > 0)}
                            {#each filteredResTherapies as therapy}
                                <tr class="table-body">
                                    <td>
                                        {therapy.therapy_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.matched_diseases.map(disease => disease.matched_disease).join(', ')}
                                    </td>
                                    <td>
                                        {therapy.trigger_alteration_display}
                                    </td>
                                    <td>
                                        {therapy.consolidated.indication}
                                    </td>
                                    <td>
                                        {therapy.consolidated.amp_tier_evidence}
                                    </td>
                                    <td>
                                        {therapy.consolidated.setting_display}
                                    </td>
                                </tr>
                            {/each}
                        {:else}
                            <tr class="table-body">
                                <td colspan="8">No Therapies Matches found</td>
                            </tr>
                        {/if}
                    </table>
                {:else if ($matchTableSetting === 'evidenceTherapies')}
                    <!--Sensitive Evidence Based Therapies -->
                    <h3 class="top-tier-caption"
                        style="font-size: 20px; margin-left: 10px; text-align: left; width: {openRightPane === 'open' ? '60vw' : '85vw'};">
                        Evidence Based Therapies - Sensitive
                        ({$senEvidenceMCGTherapies.length + $senEvidenceCTTherapies.length + $senEvidenceCSTherapies.length + $senEvidenceCRCSTherapies.length + $senEvidencePCTherapies.length}
                        )
                    </h3>
                    <!--MCG-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {senEvidenceMCGTherapiesTableState = senEvidenceMCGTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                My Cancer Genome Curated Therapies ({$senEvidenceMCGTherapies.length})
                                {#if (senEvidenceMCGTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (senEvidenceMCGTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (senEvidenceMCGTherapiesTableState === 'open')}
                            {#if ($senEvidenceMCGTherapies.length > 0)}
                                {#each $senEvidenceMCGTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Clinical Trial Results-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {senEvidenceCTTherapiesTableState = senEvidenceCTTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Clinical Trial Results ({$senEvidenceCTTherapies.length})
                                {#if (senEvidenceCTTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (senEvidenceCTTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (senEvidenceCTTherapiesTableState === 'open')}
                            {#if ($senEvidenceCTTherapies.length > 0)}
                                {#each $senEvidenceCTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Clinical Study-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {senEvidenceCSTherapiesTableState = senEvidenceCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Clinical Study ({$senEvidenceCSTherapies.length})
                                {#if (senEvidenceCSTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (senEvidenceCSTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (senEvidenceCSTherapiesTableState === 'open')}
                            {#if ($senEvidenceCSTherapies.length > 0)}
                                {#each $senEvidenceCSTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Case Report/ Case Series-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {senEvidenceCRCSTherapiesTableState = senEvidenceCRCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Case Reports/Case Series ({$senEvidenceCRCSTherapies.length})
                                {#if (senEvidenceCRCSTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (senEvidenceCRCSTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (senEvidenceCRCSTherapiesTableState === 'open')}

                            {#if ($senEvidenceCRCSTherapies.length > 0)}
                                {#each $senEvidenceCRCSTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Preclinical-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {senEvidencePCTherapiesTableState = senEvidencePCTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Preclinical ({$senEvidencePCTherapies.length})
                                {#if (senEvidencePCTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (senEvidencePCTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (senEvidencePCTherapiesTableState === 'open')}
                            {#if ($senEvidencePCTherapies.length > 0)}
                                {#each $senEvidencePCTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>

                    <!--Resistant Evidence Based Therapies -->
                    <h3 class="top-tier-caption"
                        style="font-size: 20px; margin-left: 10px; text-align: left; width: {openRightPane === 'open' ? '60vw' : '85vw'};">
                        Evidence Based Therapies - Resistant
                        ({$resEvidenceMCGTherapies.length + $resEvidenceCTTherapies.length + $resEvidenceCSTherapies.length + $resEvidenceCRCSTherapies.length + $resEvidencePCTherapies.length}
                        )
                    </h3>
                    <!--MCG-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {resEvidenceMCGTherapiesTableState = resEvidenceMCGTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                My Cancer Genome Curated Therapies ({$resEvidenceMCGTherapies.length})
                                {#if (resEvidenceMCGTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (resEvidenceMCGTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (resEvidenceMCGTherapiesTableState === 'open')}
                            {#if ($resEvidenceMCGTherapies.length > 0)}
                                {#each $resEvidenceMCGTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Clinical Trial Results-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {resEvidenceCTTherapiesTableState = resEvidenceCTTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Clinical Trial Results ({$resEvidenceCTTherapies.length})
                                {#if (resEvidenceCTTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (resEvidenceCTTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (resEvidenceCTTherapiesTableState === 'open')}
                            {#if ($resEvidenceCTTherapies.length > 0)}
                                {#each $resEvidenceCTTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Clinical Study-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {resEvidenceCSTherapiesTableState = resEvidenceCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Clinical Study ({$resEvidenceCSTherapies.length})
                                {#if (resEvidenceCSTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (resEvidenceCSTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (resEvidenceCSTherapiesTableState === 'open')}
                            {#if ($resEvidenceCSTherapies.length > 0)}
                                {#each $resEvidenceCSTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Case Report/ Case Series-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {resEvidenceCRCSTherapiesTableState = resEvidenceCRCSTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Case Reports/Case Series ({$resEvidenceCRCSTherapies.length})
                                {#if (resEvidenceCRCSTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (resEvidenceCRCSTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (resEvidenceCRCSTherapiesTableState === 'open')}

                            {#if ($resEvidenceCRCSTherapies.length > 0)}
                                {#each $resEvidenceCRCSTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                    <!--Preclinical-->
                    <table class="match-table">
                        <thead>
                        <tr>
                            <th class="second-tier-caption" colspan="7"
                                on:click={() => {resEvidencePCTherapiesTableState = resEvidencePCTherapiesTableState === 'open' ? 'closed' : 'open'}}>
                                Preclinical ({$resEvidencePCTherapies.length})
                                {#if (resEvidencePCTherapiesTableState === 'open')}
                                    <Icon data="{faChevronDown}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {:else}
                                    <Icon data="{faChevronUp}"
                                          style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                {/if}
                            </th>
                        </tr>
                        {#if (resEvidencePCTherapiesTableState === 'open')}
                            <tr>
                                <th class="third-tier-th">
                                    Drugs
                                </th>
                                <th class="third-tier-th">
                                    Response
                                </th>
                                <th class="third-tier-th">
                                    Diseases
                                </th>
                                <th class="third-tier-th">
                                    Related Alterations
                                </th>
                                <th class="third-tier-th">
                                    Note
                                </th>
                                <th class="third-tier-th">
                                    AMP Tier
                                </th>
                                <th class="third-tier-th">
                                    Source
                                </th>
                            </tr>
                        {/if}
                        </thead>
                        {#if (resEvidencePCTherapiesTableState === 'open')}
                            {#if ($resEvidencePCTherapies.length > 0)}
                                {#each $resEvidencePCTherapies as therapy}
                                    <tr class="table-body">
                                        <td>{therapy.therapy_display}</td>
                                        <td>{therapy.response}</td>
                                        <td>
                                            {#if (!therapy.for_other_tumor_type)}
                                                {#each therapy.matched_diseases as md}
                                                    <p>{md.matched_disease}</p>
                                                {/each}
                                            {/if}
                                        </td>
                                        <td>
                                            {#each therapy.detected_alterations as da}
                                                <p>{da.display}</p>
                                            {/each}
                                            {#each therapy.match_results as mr}
                                                {#if (mr.negative_alterations && mr.negative_alterations.length > 0)}
                                                    {#each mr.negative_alterations as na}
                                                        <p><strong>ND: </strong>{na.name}</p>
                                                    {/each}
                                                {/if}
                                            {/each}
                                        </td>
                                        <td>
                                            {therapy.indication}
                                        </td>
                                        <td>
                                            {therapy.amp_tier_evidence}
                                        </td>
                                        <td>
                                            {#each therapy.evidence_references as er}
                                                {#if er.reference_source_name === "PubMed"}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline"
                                                               href="https://www.ncbi.nlm.nih.gov/pubmed/{er.reference_source_id}"
                                                               target="_blank">
                                                                PMID {er.reference_source_id}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}

                                                {#if er.reference_source_name === "Other" && er.reference_url}
                                                    <div>
                                                        <b>
                                                            <a style="display: inline" href="{er.reference_url}"
                                                               target="_blank">
                                                                {er.reference_source_name}
                                                            </a>
                                                        </b>
                                                    </div>
                                                {/if}
                                            {/each}
                                        </td>
                                    </tr>
                                {/each}
                            {:else}
                                <tr class="table-body">
                                    <td colspan="7">No Therapies Matches found</td>
                                </tr>
                            {/if}
                        {/if}
                    </table>
                {/if}
            </div>
        {/if}
    </left>

    <right slot="right">
        {#if (openRightPane === 'open')}
            {#if ($viewUnsavedChanges === 'open')}
                <div class="additional-fields">
                    <div style="display:flex; justify-content:center; align-items: center;">
                        <h3 style="margin-right: 10px;">View Unsaved Changes</h3>
                        <div on:click={() => {viewUnsavedChanges.set($viewUnsavedChanges === "closed" ? "open" : "closed");}}>
                            <Icon data="{faCircleXmark}"/>
                        </div>
                    </div>
                    <div class="unsaved-biomarker"
                         on:click={() => {pane.set('markers'); viewUnsavedChanges.set('closed')}}>
                        <p style="text-align: left; margin-left:10px;">
                            <strong>Biomarkers:</strong>
                        </p>
                        {#each Object.entries($historical_match_params.updated_fields).filter(([key, param]) => param.saved === false).filter(([key, param]) => param.field === "selected_alterations") as [key, updatedSelectedAlt]}
                            <p style="text-align: left; margin-left:25px;">
                                <strong>Marker Display: </strong>{key}<br/>
                                <strong>Value: </strong>{updatedSelectedAlt.value ? 'Significant Alteration' : 'Variant of Uncertain Significance'}
                                <br/>
                                <strong>Time Changed: </strong>{updatedSelectedAlt.timeStamp}<br/>
                            </p>
                        {/each}
                    </div>
                </div>
            {:else}
                {#if additionalDetailsLoading}
                    <Loader/>
                {:else}
                    <div class="additional-fields">
                        <div style="display:flex; justify-content:center; align-items: center;">
                            <h3 style="margin-right: 10px;">{rightPaneTitle}</h3>
                            <div on:click={() => {rightAdditionalPaneState = rightAdditionalPaneState === "closed" ? "open" : "closed";}}>
                                <Icon data="{faCircleXmark}"/>
                            </div>
                        </div>
                        {#each rightPaneLabels[rightPaneTitle] as label}
                            {#if (label === "Status Update Date")}
                                <p style="text-align: left; margin-left:10px;">
                                    <strong>{label}:</strong>
                                    {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_status_dates && trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_status_dates.length > 0 ?
                                        trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_status_dates :
                                        "-"}

                                </p>
                            {:else if (label === "Additional Protocol Numbers")}
                                <p style="text-align: left; margin-left:10px;">
                                    <strong>{label}:</strong>
                                    {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_additional_protocol_numbers && trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_additional_protocol_numbers.length > 0 ?
                                        trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.inst_additional_protocol_numbers :
                                        "-"}
                                </p>
                            {:else if (label === "Principal Investigator")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.lead_sponsor || "-"}
                                </p>
                            {:else if (label === "Organizational Unit")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}:</strong> -</p>
                            {:else if (label === "Sponsor Type")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.sponsor_type || "-"}
                                </p>
                            {:else if (label === "Department")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}:</strong> -</p>
                            {:else if (label === "Title")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.title || "-"}
                                </p>
                            {:else if (label === "NCT ID")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {additionalDetailTrialSelected}</p>
                            {:else if (label === "Phase")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.phase || "-"}
                                </p>
                            {:else if (label === "Recruiting Status")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.recruiting_status || "-"}
                                </p>
                            {:else if (label === "Lead Sponsor")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.lead_sponsor || "-"}
                                </p>
                            {:else if (label === "Contact Info")}
                                {#if (trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact)}
                                    <p style="text-align: left; margin-left:10px;"><strong>Contact Information:</strong>
                                    </p>
                                    <p style="text-align: left; margin-left:25px;">
                                        <strong>Name:</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact.last_name}
                                    </p>
                                    <p style="text-align: left; margin-left:25px;">
                                        <strong>Email:</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact.email}
                                    </p>
                                    <p style="text-align: left; margin-left:25px;">
                                        <strong>Phone:</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact.phone}{trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact.phone_ext ? ` ext: ${trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.overall_contact.phone_ext}` : ''}
                                    </p>
                                {:else}
                                    <p style="text-align: left; margin-left:10px;"><strong>{label}:</strong> -</p>

                                {/if}
                            {:else if (label === "Age Range (yrs)")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.min_age_months / 12}
                                    to {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.max_age_months / 12}
                                </p>
                            {:else if (label === "Gender")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.gender}
                                </p>
                            {:else if (label === "Summary")}
                                <p style="text-align: left; margin-left:10px;"><strong>{label}
                                    :</strong> {@html trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.summary}
                                </p>
                            {:else if (label === "DrugDetail")}
                                <div class="location-cards">
                                    {#each trialsAdditionalDetail[additionalDetailTrialSelected].drugDetail.results as drugDetail}
                                        <div class="location-card">
                                            <p style="text-align: left; margin-left:10px;">
                                                <strong>Name:</strong> {drugDetail.name}</p>
                                            <p style="text-align: left; margin-left:10px;">
                                                <strong>Definition:</strong> {drugDetail.definition}</p>
                                        </div>
                                    {/each}
                                </div>
                            {:else if (label === "Location")}
                                <table class="untested-bio-table">
                                    {#each trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.locations as locationDetail}
                                        <tr>
                                            <td>
                                                <p style="text-align: left; margin-left:10px;">
                                                    <strong>Name:</strong> {locationDetail.facility_name}</p>
                                                <p style="text-align: left; margin-left:10px;">
                                                    <strong>Location:</strong> {locationDetail.city}
                                                    , {locationDetail.state}
                                                    , {locationDetail.zip}</p>
                                                <p style="text-align: left; margin-left:10px;">
                                                    <strong>Status:</strong> {locationDetail.location_status}</p>
                                                <p style="text-align: left; margin-left:10px;">
                                                    <strong>Distance:</strong> -
                                                </p>
                                            </td>
                                        </tr>
                                    {/each}
                                </table>
                            {:else if (label === "Matched Eligibility")}
                                {#each getEligibilities(true, additionalDetailTrialSelected, trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.eligibility) as eligibility}
                                    <div style="text-align: left; margin-left: 10px; margin-bottom:10px;">
                                        <div style="margin-bottom: 10px;">
                                    <span class="icon"
                                          on:click={() => expandEligibility(eligibility.id, additionalDetailTrialSelected)}>
                                        {#if (expandEligibilityKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                        {:else}
                                            <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                        {/if}
                                    </span>
                                            <span><strong>{eligibility.name}</strong></span>
                                        </div>
                                        {#if (expandEligibilityKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                            <!-- Disease Criteria -->
                                            {#if eligibility.disease_criteria}
                                                <div class="criteria-item">
                                            <span class="icon"
                                                  on:click={() => expandDiseaseCriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                {#if (expandDiseaseCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                    <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {:else}
                                                    <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {/if}
                                            </span>
                                                    <span>Disease Criteria</span>
                                                </div>
                                                {#if (expandDiseaseCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                    {#if eligibility.disease_criteria.logic_sets.length > 0}
                                                        <div class="logicset-operator">
                                                        <span class="icon"
                                                              on:click={() => {expandDiseaseOperator(eligibility.id, additionalDetailTrialSelected);
                                                              traverseDiseaseLogicSet(eligibility.disease_criteria.logic_sets, 0)}}>
                                                            {#if (expandDiseaseOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                                <Icon data="{faMinus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {:else}
                                                                <Icon data="{faPlus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {/if}
                                                        </span>
                                                            <span>{eligibility.disease_criteria.operator} of:</span>
                                                        </div>
                                                        {#if (expandDiseaseOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                            {#each Array.from({length: traversedDiseaseLogicSetLength}) as _, i}
                                                                <div style="margin-left: {i * 15 + 15}px">
                                                                    {#each traversedDiseaseLogicSet as logicSet}
                                                                        <div class="logicset-item">
                                                                            {#if (logicSet.index === i && visibleDiseaseIndex >= i)}
                                                                        <span class="icon"
                                                                              on:click={() => {expandDiseaseConcept(eligibility.id, additionalDetailTrialSelected, logicSet.operator, logicSet.groupId, i)}}>
                                                                            {#if (expandDiseaseConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                <Icon data="{faMinus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                                <span>{logicSet.operator} of: </span>
                                                                                {#if logicSet.concepts}
                                                                                    {#if (expandDiseaseConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                        <div class="concept-item">
                                                                                            {#each logicSet.concepts as concept, index}
                                                                                                {#if $selectedCase && $selectedCase.specific_diseases && checkIfDiseases(concept, $selectedCase.specific_diseases)}
                                                                                                    <span style="background-color: yellow;">{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                {:else}
                                                                                                    <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                {/if}
                                                                                            {/each}
                                                                                        </div>
                                                                                    {/if}
                                                                                {/if}
                                                                            {/if}
                                                                        </div>
                                                                    {/each}
                                                                </div>
                                                            {/each}
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/if}
                                            <!-- Alteration Criteria -->
                                            {#if eligibility.alteration_criteria}
                                                <div class="criteria-item">
                                            <span class="icon"
                                                  on:click={() => expandAlterationCriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                {#if (expandAlterationCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                    <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {:else}
                                                    <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {/if}
                                            </span>
                                                    <span>Alteration Criteria</span>
                                                </div>
                                                {#if (expandAlterationCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                    {#if eligibility.alteration_criteria.logic_sets.length > 0}
                                                        <div class="logicset-operator">
                                                        <span class="icon"
                                                              on:click={() => {expandAlterationOperator(eligibility.id, additionalDetailTrialSelected);
                                                                  traverseAlterationLogicSet(eligibility.alteration_criteria.logic_sets, 0)}}>
                                                            {#if (expandAlterationOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                                <Icon data="{faMinus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {:else}
                                                                <Icon data="{faPlus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {/if}
                                                        </span>
                                                            <span>{eligibility.alteration_criteria.operator} of:</span>
                                                        </div>
                                                        {#if (expandAlterationOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                            {#each Array.from({length: traversedAlterationLogicSetLength}) as _, i}
                                                                <div style="margin-left: {i * 15 + 15}px">
                                                                    {#each traversedAlterationLogicSet as logicSet}
                                                                        <div class="logicset-item">
                                                                            {#if (logicSet.index === i && visibleAlterationIndex >= i)}
                                                                        <span class="icon"
                                                                              on:click={() => {expandAlterationConcept(eligibility.id, additionalDetailTrialSelected, logicSet.operator, logicSet.groupId, i)}}>
                                                                            {#if (expandAlterationConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                <Icon data="{faMinus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                                <span>{logicSet.operator} of: </span>
                                                                                {#if logicSet.concepts}
                                                                                    {#if (expandAlterationConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                        <div class="concept-item">
                                                                                            {#each logicSet.concepts as concept, index}
                                                                                                {#if $selectedCase && $selectedCase.case_glance && checkIfAlteration(concept, $selectedCase.case_glance.trigger_alterations)}
                                                                                                    <span style="background-color: yellow;">{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                {:else}
                                                                                                    <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                {/if}
                                                                                            {/each}
                                                                                        </div>
                                                                                    {/if}
                                                                                {/if}
                                                                            {/if}
                                                                        </div>
                                                                    {/each}
                                                                </div>
                                                            {/each}
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/if}
                                            <!--Prior Intervention Criteria -->
                                            {#if (eligibility.treatment_contexts != null && eligibility.treatment_contexts.length > 0)}
                                                <div class="criteria-item">
                                                    <span class="icon" on:click={() => expandPICriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                        {#if (expandPICriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                        {:else}
                                                            <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                        {/if}
                                                    </span>
                                                    <span>Prior Intervention Criteria</span>
                                                </div>
                                                {#if (expandPICriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                    {#each eligibility.treatment_contexts[0].prior_interventions as pi}
                                                        <div class="criteria-item" style="margin-left: {15}px">
                                                            <span class="icon" on:click={() => expandPI(getPINameById(pi.id, eligibility), eligibility.id)}>
                                                                {#if (expandPIKey[`${getPINameById(pi.id, eligibility)}${eligibility.id}`] || false)}
                                                                    <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                {:else}
                                                                    <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                {/if}
                                                            </span>
                                                            <span>{getPINameById(pi.id, eligibility)}</span>
                                                        </div>
                                                        {#if (expandPIKey[`${getPINameById(pi.id, eligibility)}${eligibility.id}`] || false)}
                                                            {#if (getPILogicSet(pi.id, eligibility).length > 0)}
                                                                {#if (getQualifiedStringById(pi.id, eligibility) !== '')}
                                                                    <div style="margin-left: 15px;">
                                                                        <span style="background-color: yellow;">Qualifier: {getQualifiedStringById(pi.id, eligibility)}</span>
                                                                    </div>
                                                                {/if}
                                                                <div class="logicset-operator">
                                                                    <div class="criteria-item" style="margin-left: {30}px">
                                                                        <span class="icon">
                                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                        </span>
                                                                        <span>Drug Criteria:</span>
                                                                    </div>
                                                                    <div class="criteria-item" style="margin-left: {45}px">
                                                                        <span class="icon" on:click={() => {expandPIOperator(eligibility.id, additionalDetailTrialSelected, pi.id); traversePILogicSet(pi.id, getPILogicSet(pi.id, eligibility), 0)}}>
                                                                            {#if (expandPIOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}${pi.id}`] || false)}
                                                                                <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                        <span>{pi.operator} of:</span>
                                                                    </div>
                                                                    {#if (expandPIOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}${pi.id}`] || false)}
                                                                        {#each Array.from({length: traversedPILogicSetLengths[pi.id]}) as _, i}
                                                                            <div style="margin-left: {i * 15 + 45}px">
                                                                                {#each traversedPILogicSets[pi.id] as logicSet}
                                                                                    <div class="logicset-item">
                                                                                        <span class="icon">
                                                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                                        </span>
                                                                                        <span>{logicSet.operator} of: </span>
                                                                                        {#if logicSet.concepts}
                                                                                            <div class="concept-item">
                                                                                                {#each logicSet.concepts as concept, index}
                                                                                                    {#if ((getQualifiedStringById(pi.id, eligibility) !== ''))}
                                                                                                        <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                    {:else}
                                                                                                        {#if (logicSet.operator !== "None")}
                                                                                                            <span style="background-color: yellow;">{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                        {:else}
                                                                                                            <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                        {/if}
                                                                                                    {/if}
                                                                                                {/each}
                                                                                            </div>
                                                                                        {/if}
                                                                                    </div>
                                                                                {/each}
                                                                            </div>
                                                                        {/each}
                                                                        <span style="margin-left: 15px;"><strong>Disease States: </strong>{pi.disease_states.join(', ') || ''}</span><br/><br/>
                                                                        <span style="margin-left: 15px;"><strong>Therapeutic Contexts: </strong>{pi.therapeutic_contexts.join(', ') || ''}</span>
                                                                    {/if}
                                                                </div>
                                                            {/if}
                                                        {/if}
                                                    {/each}
                                                {/if}
                                            {/if}
                                        {/if}
                                    </div>
                                {/each}
                            {:else if (label === "Curated Eligibility")}
                                {#each getEligibilities(false, additionalDetailTrialSelected, trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.eligibility) as eligibility}
                                    <div style="text-align: left; margin-left: 10px; margin-bottom:10px;">
                                        <div style="margin-bottom: 10px;">
                                    <span class="icon"
                                          on:click={() => expandEligibility(eligibility.id, additionalDetailTrialSelected)}>
                                        {#if (expandEligibilityKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                        {:else}
                                            <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                        {/if}
                                    </span>
                                            <span><strong>{eligibility.name}</strong></span>
                                        </div>
                                        {#if (expandEligibilityKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                            <!-- Disease Criteria -->
                                            {#if eligibility.disease_criteria}
                                                <div class="criteria-item">
                                                <span class="icon"
                                                      on:click={() => expandDiseaseCriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                    {#if (expandDiseaseCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                        <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                    {:else}
                                                        <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                    {/if}
                                                </span>
                                                    <span>Disease Criteria</span>
                                                </div>
                                                {#if (expandDiseaseCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                    {#if eligibility.disease_criteria.logic_sets.length > 0}
                                                        <div class="logicset-operator">
                                                        <span class="icon"
                                                              on:click={() => {expandDiseaseOperator(eligibility.id, additionalDetailTrialSelected); traverseDiseaseLogicSet(eligibility.disease_criteria.logic_sets, 0);}}>
                                                            {#if (expandDiseaseOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                                <Icon data="{faMinus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {:else}
                                                                <Icon data="{faPlus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {/if}
                                                        </span>
                                                            <span>{eligibility.disease_criteria.operator} of:</span>
                                                        </div>
                                                        {#if (expandDiseaseOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                            {#each Array.from({length: traversedDiseaseLogicSetLength}) as _, i}
                                                                <div style="margin-left: {i * 15 + 15}px">
                                                                    {#each traversedDiseaseLogicSet as logicSet}
                                                                        <div class="logicset-item">
                                                                            {#if (logicSet.index === i && visibleDiseaseIndex >= i)}
                                                                        <span class="icon"
                                                                              on:click={() => {expandDiseaseConcept(eligibility.id, additionalDetailTrialSelected, logicSet.operator, logicSet.groupId, i)}}>
                                                                            {#if (expandDiseaseConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                <Icon data="{faMinus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                                <span>{logicSet.operator} of: </span>
                                                                                {#if logicSet.concepts}
                                                                                    {#if (expandDiseaseConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                        <div class="concept-item">
                                                                                            {logicSet.concepts}
                                                                                        </div>
                                                                                    {/if}
                                                                                {/if}
                                                                            {/if}
                                                                        </div>
                                                                    {/each}
                                                                </div>
                                                            {/each}
                                                        {/if}
                                                    {/if}
                                                {/if}
                                            {/if}
                                            <!-- Alteration Criteria -->
                                            {#if eligibility.alteration_criteria}
                                                <div class="criteria-item">
                                            <span class="icon"
                                                  on:click={() => expandAlterationCriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                {#if (expandAlterationCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                    <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {:else}
                                                    <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                {/if}
                                            </span>
                                                    <span>Alteration Criteria</span>
                                                    {#if (expandAlterationCriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                        {#if eligibility.alteration_criteria.logic_sets.length > 0}
                                                            <div class="logicset-operator">
                                                        <span class="icon"
                                                              on:click={() => {expandAlterationOperator(eligibility.id, additionalDetailTrialSelected);
                                                                  traverseAlterationLogicSet(eligibility.alteration_criteria.logic_sets, 0)}}>
                                                            {#if (expandAlterationOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                                <Icon data="{faMinus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {:else}
                                                                <Icon data="{faPlus}"
                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                            {/if}
                                                        </span>
                                                                <span>{eligibility.alteration_criteria.operator}
                                                                    of:</span>
                                                            </div>
                                                            {#if (expandAlterationOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                                {#each Array.from({length: traversedAlterationLogicSetLength}) as _, i}
                                                                    <div style="margin-left: {i * 15 + 15}px">
                                                                        {#each traversedAlterationLogicSet as logicSet}
                                                                            <div class="logicset-item">
                                                                                {#if (logicSet.index === i && visibleAlterationIndex >= i)}
                                                                        <span class="icon"
                                                                              on:click={() => {expandAlterationConcept(eligibility.id, additionalDetailTrialSelected, logicSet.operator, logicSet.groupId, i)}}>
                                                                            {#if (expandAlterationConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                <Icon data="{faMinus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}"
                                                                                      style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                                    <span>{logicSet.operator}
                                                                                        of: </span>
                                                                                    {#if logicSet.concepts}
                                                                                        {#if (expandAlterationConceptKey[`${additionalDetailTrialSelected}${eligibility.id}${logicSet.operator}${logicSet.groupId}`] || false)}
                                                                                            <div class="concept-item">
                                                                                                {logicSet.concepts}
                                                                                            </div>
                                                                                        {/if}
                                                                                    {/if}
                                                                                {/if}
                                                                            </div>
                                                                        {/each}
                                                                    </div>
                                                                {/each}
                                                            {/if}
                                                        {/if}
                                                    {/if}
                                                </div>
                                            {/if}
                                            <!-- Prior Intervention Criteria-->
                                            {#if (eligibility.treatment_contexts != null && eligibility.treatment_contexts.length > 0)}
                                                <div class="criteria-item">
                                                    <span class="icon" on:click={() => expandPICriteria(eligibility.id, additionalDetailTrialSelected)}>
                                                        {#if (expandPICriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`] || false)}
                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                        {:else}
                                                            <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                        {/if}
                                                    </span>
                                                    <span>Prior Intervention Criteria</span>
                                                </div>
                                                {#if (expandPICriteriaKey[`${additionalDetailTrialSelected}${eligibility.id}`]) || false}
                                                    {#each eligibility.treatment_contexts[0].prior_interventions as pi}
                                                        <div class="criteria-item" style="margin-left: {15}px">
                                                            <span class="icon" on:click={() => expandPI(getPINameById(pi.id, eligibility), eligibility.id)}>
                                                                {#if (expandPIKey[`${getPINameById(pi.id, eligibility)}${eligibility.id}`] || false)}
                                                                    <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                {:else}
                                                                    <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                {/if}
                                                            </span>
                                                            <span>{getPINameById(pi.id, eligibility)}</span>
                                                        </div>
                                                        {#if (expandPIKey[`${getPINameById(pi.id, eligibility)}${eligibility.id}`] || false)}
                                                            {#if (getPILogicSet(pi.id, eligibility).length > 0)}
                                                                {#if (getQualifiedStringById(pi.id, eligibility) !== '')}
                                                                    <div style="margin-left: 15px;">
                                                                        <span style="background-color: yellow;">Qualifier: {getQualifiedStringById(pi.id, eligibility)}</span>
                                                                    </div>
                                                                {/if}
                                                                <div class="logicset-operator">
                                                                    <div class="criteria-item" style="margin-left: {30}px">
                                                                        <span class="icon">
                                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                        </span>
                                                                        <span>Drug Criteria:</span>
                                                                    </div>
                                                                    <div class="criteria-item" style="margin-left: {45}px">
                                                                        <span class="icon" on:click={() => {expandPIOperator(eligibility.id, additionalDetailTrialSelected, pi.id); traversePILogicSet(pi.id, getPILogicSet(pi.id, eligibility), 0)}}>
                                                                            {#if (expandPIOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}${pi.id}`] || false)}
                                                                                <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                            {:else}
                                                                                <Icon data="{faPlus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                            {/if}
                                                                        </span>
                                                                        <span>{pi.operator} of:</span>
                                                                    </div>
                                                                    {#if (expandPIOperatorKey[`${additionalDetailTrialSelected}${eligibility.id}${pi.id}`] || false)}
                                                                        {#each Array.from({length: traversedPILogicSetLengths[pi.id]}) as _, i}
                                                                            <div style="margin-left: {i * 15 + 45}px">
                                                                                {#each traversedPILogicSets[pi.id] as logicSet}
                                                                                    <div class="logicset-item">
                                                                                        <span class="icon">
                                                                                            <Icon data="{faMinus}" style="color:#3A63B0; cursor:pointer;"/>
                                                                                        </span>
                                                                                        <span>{logicSet.operator} of: </span>
                                                                                        {#if logicSet.concepts}
                                                                                            <div class="concept-item">
                                                                                                {#each logicSet.concepts as concept, index}
                                                                                                    {#if ((getQualifiedStringById(pi.id, eligibility) !== ''))}
                                                                                                        <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                    {:else}
                                                                                                        {#if (logicSet.operator !== "None")}
                                                                                                            <span style="background-color: yellow;">{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                        {:else}
                                                                                                            <span>{concept}{index !== logicSet.concepts.length - 1 ? ', ' : ''}</span>
                                                                                                        {/if}
                                                                                                    {/if}
                                                                                                {/each}
                                                                                            </div>
                                                                                        {/if}
                                                                                    </div>
                                                                                {/each}
                                                                            </div>
                                                                        {/each}
                                                                        <span style="margin-left: 15px;"><strong>Disease States: </strong>{pi.disease_states.join(', ') || ''}</span><br/><br/>
                                                                        <span style="margin-left: 15px;"><strong>Therapeutic Contexts: </strong>{pi.therapeutic_contexts.join(', ') || ''}</span>
                                                                    {/if}
                                                                </div>
                                                            {/if}
                                                        {/if}
                                                    {/each}
                                                {/if}
                                            {/if}
                                        {/if}
                                    </div>
                                {/each}
                            {:else if (label === "document")}
                                <p style="text-align: left; margin-left:10px;">{@html trialsAdditionalDetail[additionalDetailTrialSelected].trialDetail.body}</p>
                            {:else if (label === "markerDetails")}
                                <table class="untested-bio-table">
                                    <thead>
                                    <tr>
                                        <th>Eligibility Group</th>
                                        <th>Untested Biomarkers</th>
                                    </tr>
                                    </thead>
                                    <tr>
                                        <td>{selectedUntestedBiomarkerDetails.eligibilityName}</td>
                                        <td>
                                            {#if selectedUntestedBiomarkerDetails.SNV.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("SNV")}</strong>: {selectedUntestedBiomarkerDetails.SNV}
                                                </p>
                                            {/if}
                                            {#if selectedUntestedBiomarkerDetails.CNV.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("CNV")}</strong>: {selectedUntestedBiomarkerDetails.CNV}
                                                </p>
                                            {/if}
                                            {#if selectedUntestedBiomarkerDetails.PRX.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("PRX")}</strong>: {selectedUntestedBiomarkerDetails.PRX}
                                                </p>
                                            {/if}
                                            {#if selectedUntestedBiomarkerDetails.BIOMARKER.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("BIOMARKER")}</strong>: {selectedUntestedBiomarkerDetails.BIOMARKER}
                                                </p>
                                            {/if}
                                            {#if selectedUntestedBiomarkerDetails.CTX.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("CTX")}</strong>: {selectedUntestedBiomarkerDetails.CTX}
                                                </p>
                                            {/if}
                                            {#if selectedUntestedBiomarkerDetails.KARYOTYPE.length > 0}
                                                <p>
                                                    <strong>{getDisplayedTestMode("KARYOTYPE")}</strong>: {selectedUntestedBiomarkerDetails.KARYOTYPE}
                                                </p>
                                            {/if}
                                        </td>
                                    </tr>
                                </table>
                            {/if}
                        {/each}
                    </div>
                {/if}
            {/if}
        {/if}
    </right>
</HSplitPane>