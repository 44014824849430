<script>
    import {additionalPatientDetails} from "../../store/patientMatch";
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    let tempAdditionalDetails = [];
    let checkboxes = {
        'Vital Status': false,
        'Race': false,
        'Cancer Type': false,
        'ECOG Value': false,
        'ICD10 Codes': false
    };

    function handleCheckboxChange(label, value) {
        if (checkboxes[label]) {
            tempAdditionalDetails.push({label, value});
        } else {
            tempAdditionalDetails = tempAdditionalDetails.filter(item => item.label !== label);
        }
    }

    function handleSubmit() {
        additionalPatientDetails.set(tempAdditionalDetails);
        dispatch('submitAction');
    }

    function handleCancel() {
        tempAdditionalDetails = [];
        Object.keys(checkboxes).forEach(key => checkboxes[key] = false);
        additionalPatientDetails.set([]);
        dispatch('removeAction');
    }
</script>

<div>
    <h3> View Additional Details</h3>
    <div class="checkbox-wrapper">
        <input type="checkbox" bind:checked={checkboxes['Cancer Type']}
               on:change={() => handleCheckboxChange('Cancer Type', 'cancer_type')}/>
        <p>Cancer Type</p>
    </div>
    <div class="checkbox-wrapper">
        <input type="checkbox" bind:checked={checkboxes['ECOG Value']}
               on:change={() => handleCheckboxChange('ECOG Value', 'ecog_value')}/>
        <p>ECOG Value</p>
    </div>
    <div class="checkbox-wrapper">
        <input type="checkbox" bind:checked={checkboxes['ICD10 Codes']}
               on:change={() => handleCheckboxChange('ICD10 Codes', 'icd10_codes')}/>
        <p>ICD10 Codes</p>
    </div>
    <div class="checkbox-wrapper">
        <input type="checkbox" bind:checked={checkboxes['Race']}
               on:change={() => handleCheckboxChange('Race', 'race')}/>
        <p>Race</p>
    </div>
    <div class="checkbox-wrapper">
        <input type="checkbox" bind:checked={checkboxes['Vital Status']}
               on:change={() => handleCheckboxChange('Vital Status', 'vital_status')}/>
        <p>Vital Status</p>
    </div>
    <button class="search-button" on:click={handleSubmit}>Submit</button>
    <button class="clear-button" on:click={handleCancel}>Reset</button>
</div>

<style>
    .checkbox-wrapper {
        display: flex;
        align-content: space-between;
    }

    .search-button {
        margin-top: 10px;
        background-color: #9d9d9f;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .clear-button {
        margin-top: 10px;
        background-color: #d98b95;
        color: #fff;
        margin-right: 0.5em;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
</style>
